import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import "./ChangePWDPage.css"
import ChangePWDComponent from '../../../components/Authentification/ResetPassword/ChangePWDComponent';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

const ChangePWDPage = () => {
    const { resetToken } = useParams();

    return (
        <div>
             <Helmet>
                <meta name="keywords" content="change password, authentication, user login, secure login, Meryashome, virtual staging, virtual renovation, 3D rendering interior, 3D rendering exterior, 2d textured plan, item removal, image enhancement, photo modification, day to dusk" />
                <meta name="description" content="Login to access your account. Secure and user-friendly authentication for a seamless experience." />
            </Helmet>
            {/* <Header/> */}
            <ChangePWDComponent resetToken={resetToken}/>
            <Footer/>
        </div>
    );
};

export default ChangePWDPage;