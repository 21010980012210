import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SnackbarProvider, useSnackbar } from "notistack";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { environment } from "../../../envirement";
const ChangePWDComponent = ({ resetToken }) => {
  const Language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.loginDetails);
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    Password: '',
    Confirme_Password: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const validationSchema = Yup.object({
    Password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/, 'Invalid password. eg: Abc123@a')
      .required('Password is required'),
    Confirme_Password: Yup.string()
      .oneOf([Yup.ref('Password'), null], 'Passwords must match')
      .required('Confirmation password is required'),
  });

  const formik = useFormik({
    initialValues: {
      Password: '',
      Confirme_Password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${environment.apiUrl}/usersAPI/resetPassword/${resetToken}`,
          {
            Mot_de_passe: values.Password,
            language: Language,
          }
        );

        const { message } = response.data;
        enqueueSnackbar(message, { variant: "success" });
        navigate("/login");
      } catch (error) {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Something went wrong.";
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
  });

  const handlePasswordChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
    formik.handleChange(event); // Trigger formik's handleChange
  };

  const handleToggleVisibility = (prop) => () => {
    setValues({
      ...values,
      [prop]: !values[prop],
    });
  };

  return (
    <div className="login-container">
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <h3 className="title">{t('reset-title')}</h3>
      {user && <h1>{user.message}</h1>}
      <form onSubmit={formik.handleSubmit}>
        <label>{t(`logIn.Signup.label4`)}</label>
        <div className="password-input-container">
          <Input
            className="login-input"
            type={values.showPassword ? "text" : "password"}
            placeholder="Password"
            name="Password"
            value={values.Password}
            onChange={handlePasswordChange('Password')}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleVisibility('showPassword')}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {formik.touched.Password && formik.errors.Password ? (
          <div className="error-message">{formik.errors.Password}</div>
        ) : null}

        <label>{t(`logIn.Signup.label8`)}</label>
        <div className="password-input-container">
          <Input
            className="login-input"
            type={values.showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            name="Confirme_Password"
            value={values.Confirme_Password}
            onChange={handlePasswordChange('Confirme_Password')}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleVisibility('showConfirmPassword')}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {formik.touched.Confirme_Password && formik.errors.Confirme_Password ? (
          <div className="error-message">{formik.errors.Confirme_Password}</div>
        ) : null}

        <button className="submit-btn-CPW" type="submit">
          {t(`reset-btn`)}
        </button>
      </form>
    </div>
  );
};

export default ChangePWDComponent;