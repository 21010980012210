import React from 'react'
import './ImagesGridLoader.css'

const ImagesGridLoader = () => {
  return (
    <div>
        <div className="loaderContainer">
  <div className="">
    <div className="movie--isloading">
      <div className="loading-image"></div>
    
    </div>
  </div>
    <div className="">
    <div className="movie--isloading">
      <div className="loading-image"></div>
    
    </div>
  </div>
    <div className="">
    <div className="movie--isloading">
      <div className="loading-image"></div>
    
    </div>
  </div>
    <div className="">
    <div className="movie--isloading">
      <div className="loading-image"></div>
    
    </div>
  </div>
    <div className="">
    <div className="movie--isloading">
      <div className="loading-image"></div>
    
    </div>
  </div>
    <div className="">
    <div className="movie--isloading">
      <div className="loading-image"></div>
    
    </div>
  </div>

</div>
    </div>
  )
}

export default ImagesGridLoader