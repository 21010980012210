import React, { useEffect, useRef, useState } from "react";
import "./ServiceOrderPage.css";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import Cards from "../../../data/cards.json";
import FileUpload from "../../../components/FileUpload/FileUpload";
import LineComponent from "../../../components/LineComponent";
import VirtualStagingImg from "../../../assets/img/5 15.png";
import PricingPacks from "../../../components/PlaceOrder/PricingPacks/PricingPacks";
import { useDispatch, useSelector } from "react-redux";
import { addNewOrder } from "../../../redux/actions/OrdersActions";
import { updateCredits } from "../../../redux/actions/UsersActions";
import upload from "../../../assets/img/icons/upload1.svg";
import deleteIcon from "../../../assets/img/icons/Close.svg";
import Credit from "../../../components/Credit/Credit";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import fileImg from "../../../assets/img/fileimage_13.png";
import { FallingLines } from "react-loader-spinner";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../../components/payment/CheckoutForm";
import CheckoutForm from "../../../components/payment/CheckoutForm";
import axios from "axios";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { GrClose } from "react-icons/gr";
import { environment } from "../../../envirement";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const stripePromise = loadStripe('pk_test_51Ok8drLJrt4v80UFisMEseYeiCgfDXx1Xc49YFekmInb3nCnTQzdPolP6PUSsWgd1aQuDzrbulf7rgfYtV63zbr700QUtJ9eYM');

const ServiceOrderPage = ({currency}) => {
  const [uploadedImgs, setUploadedImgs] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState("");
  const [notes, setNotes] = useState("");
  const [address, setAddress] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [credits, setCredits] = useState(0); // Move credits state to ServiceOrderPage
  const [orderPrice, setOrderPrice] = useState(0);
  const [orderDataFromLocalStorage, setOrderDataFromLocalStorage] =
    useState(null);
    const [loading, setLoading] = useState(false); // Nouvel état pour contrôler l'affichage du loader

  const styleSelectorRef = useRef(null); // Ajoutez cette ligne pour créer une référence
const {user}=useSelector(state=>state.loginDetails.user)
const language=localStorage.getItem('lng')
// const [notifyAdmin, setNotifyAdmin] = useState({user,language})

  useEffect(() => {
    const orderData = localStorage.getItem("orderData");
    if (orderData) {
      setOrderDataFromLocalStorage(JSON.parse(orderData));
    }
  }, []);
  const getCredits = (credits) => {
    setCredits(credits);
    // console.log(credits)
  };
  const getPrice = (price) => {
    // console.log(price)
    setOrderPrice(price);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleStyleChange = (e) => {
    setSelectedStyle(e.target.value);
  };
  const getFiles = (files) => {
    setUploadedImgs(files);
  };

  const getInspirations = (files) => {
    setInspiration(files);
  };
  const dispatch = useDispatch();

  const iconURL = (imgName) => {
    return require(`../../../assets/img/icons/${imgName}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { serviceId } = useParams();
  const title = t(`place-order-page.card${serviceId}.title`);
  // console.log("------------------title------------------");
  // console.log(title);
  const generateShortcut = (t) => {
    // console.log(t);
    switch (t.toLowerCase()) {
      case "virtual staging":
      case "mise en scène virtuelle":
        return "VS";
      case "virtual renovation":
      case "rénovation virtuelle":
        return "VR";
      case "3D rendering (interior)":
      case "3d rendering (intérieur)":
        return "3DMI";
      case "3D rendering (exterior)":
      case "3d rendering (extérieur)":
        return "3DME";
      case "image enhancement":
      case "amélioration de l'image":
        return "IE";
      case "item removal":
      case "suppression d'objet":
        return "IR";
      case "day to dusk":
      case "crépuscule":
        return "DD";
      case "2d textured plan":
      case "plan d'architecture en couleur":
        return "2D";
      case "photo modification":
      case "modification de photo":
        return "PM";
      default:
        return "";
    }
  };

  const isVirtualStaging =
    title === "mise en scène virtuelle" || title === "virtual staging";
  const foundService = Cards.find((s) => s.id === parseInt(serviceId));

  //pour la liste static des style ajouter au niveau de la translation
  const generateStyles = (count) => {
    const stylesList = [];
    for (let i = 0; i < count; i++) {
      stylesList.push(`style${i}`);
    }
    return stylesList;
  };

  const numberOfStyles = 8; // Vous pouvez ajuster le nombre de styles selon les styles
  const stylesList = generateStyles(numberOfStyles);
  //pour la liste static des style ajouter au niveau de la translation

  const [inspiration, setInspiration] = useState(null);

  const handleButtonClick = () => {
    const dynamicInput = document.createElement("input");
    dynamicInput.type = "file";
    dynamicInput.multiple = true;
    dynamicInput.onchange = handleFileInputChange;
    dynamicInput.click();
  };

  const handleFileInputChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setInspiration((prevFiles) =>
      prevFiles ? [...prevFiles, ...newFiles] : newFiles
    );
  };

  const handleRemoveFile = (index, e) => {
    // Check if the click event originated from the delete icon
    if (e.target.tagName.toLowerCase() === "img") {
      const newFiles = [...inspiration];
      newFiles.splice(index, 1);
      setInspiration(newFiles.length > 0 ? newFiles : null);
    }
    // Stop the propagation of the click event to prevent triggering handleButtonClick
    e.stopPropagation();
  };

  useEffect(() => {
    getInspirations(inspiration);
  }, [inspiration]);
  // console.log("------title------");
  // console.log(title);
  const redirectionURL = window.location.href
  // console.log(redirectionURL)
  const confirmUpload = async (PaymentStatus) => {
    // console.log(PaymentStatus)
    setLoading(true)
    try {
      const orderData = {
        Ref: generateShortcut(title),
        Status: "In progress",
        Price: orderPrice,
        Images: imgsFromFileManger,
        Inspi: inspiration,
        Style: selectedStyle,
        Note: notes,
        Address: address,
        PaymentStatus:PaymentStatus
      };
      // console.log(orderData);
      const fromLocalStorage = localStorage.getItem("cred")
        ? JSON.parse(localStorage.getItem("cred"))
        : null;
      if (fromLocalStorage.user.id) {
        //-----------------------------set localstorage----------------------------- :
        let credString = localStorage.getItem("cred");
        let cred = JSON.parse(credString);
        cred.user.Credit = credits;
        localStorage.setItem("cred", JSON.stringify(cred));

        //-----------------------------set localstorage----------------------------- :
        // if(orderData.Price>0 ){
        //   console.log(orderData.Price)
        //   Order_Payment()
        // }
      //  if(isComplete==true ||orderData.Price==0  ){
        
        dispatch(updateCredits(cred.user.Credit, fromLocalStorage.user.id));
        // dispatch(addNewOrder(orderData, orderInfos, uploadedImgs,redirectionURL));
        const createdOrder = await dispatch(addNewOrder(orderData, orderInfos, uploadedImgs, navigate));
        setLoading(false)

        return createdOrder;

      //  }
         
        
       
        
      
        // dispatch(addNewOrder(orderData, orderInfos, uploadedImgs,navigate));
        // if(isVirtualStaging && credits!==0){
        //   navigate("/profile")
        //   console.log("c bn")
        // }
        // setLoading(false)

      }
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);
  const [orderInfos, setOrderInfos] = useState({
    price: 0,
    credit: 0,
    checked: false,
    name: "",
  });

  const getOrderInfos = (infos) => {
    setOrderInfos(infos);
    // console.log(infos)
  };

  const [imgsFromFileManger, setImgsFromFileManger] = useState({});
  const handleValidateFiles = (validatedFiles) => {
    setImgsFromFileManger(validatedFiles);
  };

  useEffect(() => {
    if (orderInfos.name) {
      // Vérifiez si l'élément est bien référencé et défilez vers lui
      styleSelectorRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [orderInfos.name]);
// navigate from 1 image cart

const HandleOneImageCartSelected=()=>{
  styleSelectorRef.current?.scrollIntoView({ behavior: "smooth" });

}
  // houni yabda l paiement
  const stripePromise = loadStripe(environment.stripePk);
const [clientSecret, setClientSecret] = useState("")

  const [openDialog, setOpenDialog] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const Order_Payment=async()=>{
// console.log(`order price : ${orderPrice}`)

const currency = localStorage.getItem("currency")
// console.log(orderInfos.price)
// console.log(uploadedImgs)
if(orderInfos.price>0 && !uploadedImgs){
  // console.log("called")
  const orderData = {
    Ref: `${orderInfos.name} pachage`,
    Price: orderInfos.price,
    Currency:currency
  };
  const {data}=await axios.post(`${environment.apiUrl}/PaymentAPI/create-checkout-session`,{orderData})
  
  setClientSecret(data.clientSecret)
  setOpenDialog(true);
  if(isComplete==true){
    // console.log("update paymenet status after complet")
   
      }
      return
}
if (orderPrice==0 && uploadedImgs) {
  // console.log("price 0")
  const PaymentStatus='Payed'
  const response =await confirmUpload(PaymentStatus)
  // console.log(response)

  const notifyAdmin={
    user,
    language,
    newOrder: response.order 
  }
   const {data}=await axios.post(`${environment.apiUrl}/ordersAPI/notifyOrderAdmin`,notifyAdmin)
   window.location.href = '/profile'

}else if (uploadedImgs) {
  const PaymentStatus='OnHold'
  const createdOrder = await confirmUpload(PaymentStatus);
  if(createdOrder){
    const orderData = {
      Ref: generateShortcut(title),
      Status: "In progress",
      Price: orderPrice,
      Images: imgsFromFileManger,
      Inspi: inspiration,
      Style: selectedStyle,
      Note: notes,
      Address: address,
      Currency:currency
    };
    const {data}=await axios.post(`${environment.apiUrl}/PaymentAPI/create-checkout-session`,{orderData})
  
    setClientSecret(data.clientSecret)
    setOpenDialog(true);
    if(isComplete==true){
      // console.log("update paymenet status after complet")
     
        }
  }

 
}
  }
  const updateCredit = async () => {
    // console.log("updateCredit called");
  
    try {
      // Step 1: Update user credits
      const { data } = await axios.put(
        `${environment.apiUrl}/usersAPI/userCredit/${user.id}`,
        { Credit: credits }
      );
      // console.log("Updated user data:", data);
  
      // Step 2: Update local storage with new user data
      let credString = localStorage.getItem("cred");
      let cred = JSON.parse(credString);
      cred.user = data.user;
      localStorage.setItem("cred", JSON.stringify(cred));
  
      // Step 3: Purchase a package
      const response = await axios.post(`${environment.apiUrl}/ordersAPI/purchasePackage`, {
        user,
        orderInfos,
        language,
      });
      // console.log("Package purchase response:", response.data); // Log the response data
  
      // Reload the page
      window.location.reload();
  
    } catch (error) {
      // console.error("Error updating credit or purchasing package:", error);
      // Handle errors gracefully
      // Example: Show an error message to the user
    }
  };
  
  
  useEffect(() => {
    if(isComplete && orderInfos.price>0 && !uploadedImgs)  {
      // console.log("isComplete && orderInfos.price>0 && !uploadedImgs")
    
      updateCredit()

    }  
    else if(isComplete){
      // console.log("isComplete")
      const id=localStorage.getItem('orderId')
      const PaymentStatus="Payed"
       const userId=user?.id
       updatePaymenetStatus(id,PaymentStatus,userId)  
    }

  }, [isComplete])
  
  const updatePaymenetStatus=async(id,PaymentStatus,userId)=>{
    setLoading(true)
    try {
      let req={
        id,PaymentStatus,userId
      }
      const {data}=await axios.put(`${environment.apiUrl}/PaymentAPI/updatePayementStatus`,req)
      window.location.href = '/profile'
      setLoading(false)
    } catch (error) {
      // console.log(error)
      setLoading(false)
    }


  }
  const options = {clientSecret};

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleComplete=()=>{
    // console.log(`payement is completed`)
    setIsComplete(true)

    
  }
  const { orders } = useSelector((state) => state);
useEffect(() => {
  if(orders?.message){
    // console.log(orders?.message)
    enqueueSnackbar(orders.message);

  }

}, [orders])



  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="virtual staging, virtual renovation, 3D rendering interior, 3D rendering exterior, 2d textured plan, item removal, image enhancement, photo modification, day to dusk, place order, Meryashome"
        />
        <meta
          name="description"
          content="Place your order for virtual staging and real estate services. Explore our range of services and enhance your property's visual appeal."
        />
        <link rel='canonical' href={`https://www.meryashome.com/prices/${serviceId}`} />
        {/* <link rel='canonical' href={`https://www.meryashome.com/placeOrder/${serviceId}`} /> */}
      </Helmet>
      {/* <Header /> */}
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
          <div id="checkout">
          {clientSecret && (
          <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="">
            <GrClose onClick={handleCloseDialog} style={{    position: "absolute", right: '18px', top: "17px",fontSize: "28px"}} />

        <DialogTitle>Checkout</DialogTitle>
        <DialogContent>
          
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret,
              onComplete: handleComplete}}>
              <EmbeddedCheckout   />
            </EmbeddedCheckoutProvider>
       
        </DialogContent>
      </Dialog>
         )}
    </div>
    {loading && (
        <div className="loader-wrapper">
          <FallingLines
            color="#041C32"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
          />
        </div>
      )}

      {foundService ? (
        <div className="so-container">
          {isVirtualStaging && (
            <div className="element " id="VS-container">
              <h3 style={{fontSize:"30px"}}>{t(`order-form.virtual-staging-element.title`)}</h3>

              {/* <img
                style={{
                  width: "100vw",
                  maxWidth: "900px",
                  paddingBottom: "40px",
                }}
                src={VirtualStagingImg}
                alt="Virtual staging"
              /> */}
              <LineComponent />
              <PricingPacks getOrderInfos={getOrderInfos} currency={currency} HandleOneImageCartSelected={HandleOneImageCartSelected}/>
              <div ref={styleSelectorRef}></div>
              {/* <LineComponent /> */}
            </div>
          )}
          {/*---------------adress---------------*/}

          <div className="element" style={{ paddingTop: "84px" }}>
            {!isVirtualStaging && (
              <h3
                style={{
                  maxWidth: "304px",
                  width: "100%",
                  marginBottom: "70px",
                }}
              >
                {title}
              </h3>
            )}

            <h3>{t("order-form.address")}</h3>
            <textarea
              style={{ maxWidth: "500px", height: "65px" }}
              placeholder="Enter your address..."
              className="textarea-element"
              onChange={handleAddressChange}
            />
          </div>
          {/*---------------adress---------------*/}

          {/*---------------styles---------------*/}

          <div className="element">
            <h3 htmlFor="styles">{t(`order-form.selector-element.title`)}</h3>
            <select
              name="styles"
              id="styles"
              onChange={(e) => handleStyleChange(e)}
            >
              <option value="" selected>
                {t(`order-form.selector-element.default-option`)}
              </option>
              {stylesList.map((style, index) => (
                <option
                  key={index}
                  value={t(`order-form.selector-element.styles.${style}`)}
                >
                  {t(`order-form.selector-element.styles.${style}`)}
                </option>
              ))}
            </select>
          </div>

          {/*---------------styles---------------*/}

          {/*---------------notes---------------*/}
          <div className="element">
            <h3>{t(`order-form.note-element.title`)}</h3>
            <p className="info-element">{t(`order-form.note-element.text`)}</p>
            <div className="imgs-notes">
              <textarea
                placeholder={t(`order-form.upload-element.placeholder`)}
                className="textarea-element"
                onChange={handleNotesChange}
              />
              <ul className="file-list" onClick={handleButtonClick}>
                {inspiration &&
                  inspiration.map((inspi, i) => (
                    <li key={i}>
                      <div className="file-list-iamge-container">
                        {inspi.type.startsWith("image/") ? (
                           <img
                           style={{
                             objectFit: "cover",
                             width: "100px",
                             height: "100px",
                           }}
                           className="file-icon"
                           src={URL.createObjectURL(inspi)}
                           alt="File Icon"
                         />
                        ) : (
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100px",
                              height: "100px",
                            }}
                            className="file-icon"
                            src={fileImg}
                            alt="File Icon"
                          />
                         
                        )}

                        <span className="file-list-title">{inspi.name}</span>
                        <div onClick={(e) => handleRemoveFile(i, e)}>
                          <img
                            src={deleteIcon}
                            alt="Delete Icon"
                            width={"20px"}
                            style={{
                              position: "relative",
                              bottom: "125px",
                              left: "48px",
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                {!inspiration && (
                  <img className="upload-img" src={upload} alt="Upload Icon" />
                )}
                {/* <p className="text-gras">Browse Files</p> */}
              </ul>
              <div className="GrayBand">
                <div className="upload-icon-btn" onClick={handleButtonClick}>
                  <button className="upload-btn">{t(`inspi-btn`)}</button>
                  <img src={iconURL("upload-icon.svg")} alt="upload" />
                </div>
              </div>
            </div>
          </div>
          {/*---------------notes---------------*/}

          {/*---------------uploads---------------*/}
          <div className="element">
            <h3>{t(`order-form.upload-element.title`)}</h3>
            {/* <FileUpload getFiles={getFiles}   title ={title}/> */}
            <FileUpload
              getFiles={getFiles}
              title={title}
              onValidateFiles={handleValidateFiles}
            />
          </div>
          {/*---------------uploads---------------*/}

          {/*---------------credits---------------*/}
          <div className="element">
            {/* <Credit  /> */}
            <Credit
              uploadedImgs={uploadedImgs}
              orderInfos={orderInfos}
              getCredits={getCredits}
              getPrice={getPrice}
              title={title}
              currency={currency}
            />
          </div>
          {/*---------------credits---------------*/}

          <LineComponent />
          {/*---------------------------*/}

          <div className="element" id="payment-element">
            <button
              className={`submit-btn`}
              // onClick={confirmUpload}
              onClick={Order_Payment}
            >
              {t(`order-form.btns.btn2`)}
            </button>
          </div>

          {/*---------------paiement---------------*/}
        </div>
      ) : (
        <NotFoundComponent />
      )}
      <Footer />
    </>
  );
};

export default ServiceOrderPage;