import React from "react";
import vr from "../../../data/visit-vr.json";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const VirtualTour = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadImage = (imgName) => {
    try { 
      if (imgName=="Le 5e Quartier - Triplex") {
        return require(`../../../assets/img/Séjour.jpg`);

      }
      return require(`../../../assets/img/${imgName}`);
    } catch (error) {
      console.error("Image load error:", error);
      return null; // Return a default image or handle the error gracefully
    }
    };
     const navigateToPanoramic = (item) => {
  //  console.log(item)
      //  navigate(`/panoramic/${imageId}`);
      if (item.path=="/vrTour") {
        navigate("/vrTour")
      }else {
        navigate(`/panoramic/${item.id}`)
      }
     };

  return (
    <section>
      <div className="vs-container">
      <h1 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-14 mt-24">Virtual Tour</h1>
      <div className="border max-w-[940px] w-full m-0 !border-[#06466366] "></div>
        <p>{t("gallery.VT.text")}</p>
        <div
          className="vsImagesAll"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          {vr.map((item) => (
            <div
              className="vsImages"
              // key={i}
              style={{
                cursor: "pointer",
                maxWidth: "612px",
                maxHeight: "408px",
                width: "80%",
              }}
              onClick={() => navigateToPanoramic(item)}
            >
              <img
                src={loadImage(item.img)}
                alt={item.name}
                style={{ width: "80%", height: "338px" }}
              />
              <div className="titleRef">
                {/* <h2>{item.name}</h2> */}
                {/* <p>{item.ref}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VirtualTour;
