import React from "react";
import './Contact.css'
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("serviceURL", `contact`);

  }, []);

  const { t } = useTranslation();

  return (
      <div>
        <Helmet>
  {/* Page Title */}
  <title>Contact Us - Meryas Home | Virtual Staging & Real Estate Solutions</title>
  {/* Meta Description */}
  <meta name="description" content="Get in touch with Meryas Home for professional virtual staging, virtual renovation, and real estate enhancement services. We're here to answer your questions and help you elevate your property listings." />
  {/* Keywords */}
  <meta name="keywords" content="contact Meryas Home, virtual staging contact, real estate services contact, property enhancement, home staging support, virtual renovation contact" />
  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content="Contact Us - Meryas Home | Virtual Staging & Real Estate Solutions" />
  <meta property="og:description" content="Reach out to Meryas Home for expert virtual staging and real estate solutions. Our team is ready to assist you with virtual staging, renovation, and other property enhancement services." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.meryashome.com/contact" />
  <meta property="og:image" content="https://www.meryashome.com/images/contact-us-banner.jpg" />
  <meta property="og:site_name" content="Meryas Home" />
  {/* Twitter Meta Tags for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Contact Us - Meryas Home | Virtual Staging & Real Estate Solutions" />
  <meta name="twitter:description" content="Connect with Meryas Home for top-notch virtual staging and real estate enhancement services." />
  <meta name="twitter:image" content="https://www.meryashome.com/images/contact-us-banner.jpg" />
  <meta name="twitter:site" content="@MeryasHome" />
  {/* Canonical Link */}
  <link rel="canonical" href="https://www.meryashome.com/contact" />
</Helmet>
          {/* WE NEED ONSUBMIT FUNTIONALITIES */}
      {/* {loading && (
      <div className="loader-container">
        <span className="loader"></span>
      </div>
    )} */}
          {/* <Header /> */}

      <div className="contact-container">
        <h1>{t("contact-us.title")}</h1>
        <p>{t("contact-us.text")}</p>
        <form className="formSubmit">
          <input
            type="text"
            placeholder={t("contact-us.placeholder1")}
            required
            name="name"
          />
          <input
            type="email"
            placeholder={t("contact-us.placeholder2")}
            required
            name="Email"
          />
          <textarea
            cols="30"
            rows="10"
            placeholder={t("contact-us.placeholder3")}
            required
            name="message"
          ></textarea>
          <button>{t("contact-us.btn")}</button>
        </form>
          </div>
          <Footer />
    </div>
  );
};

export default Contact;
