import React from "react";
import { useParams } from "react-router-dom";
import ReactPannellum, { getConfig } from "react-pannellum";
import vs from "../../../assets/img/Vyce.jpg";
import comingSoon from "../../../assets/img/coming-soon.jpg";
import vr from "../../../data/visit-vr.json";
import { Helmet } from "react-helmet-async";

const Panoramic = () => {
  const { imageId } = useParams();
  const idAsNumber = parseInt(imageId);

  const getImageSource = (id) => {
    let image=vr.filter((el)=>el.id==id)
    // console.log(image[0].name)
    if (image[0].name=="Comming soon") {
      return comingSoon
    }else 
    return vs
  
  };

  const imageSource = getImageSource(idAsNumber);
  // console.log(imageSource)


  if (!imageSource) {
    return <div>Image not found</div>;
  }

  const config = {
    autoRotate: 0,
    autoLoad: true,
  };

  return (
    <div>
      <Helmet>
        <meta name="keywords" content="panoramic view, virtual reality, 360-degree view" />
        <meta name="description" content="Explore panoramic views in virtual reality. Visit our virtual spaces for an immersive 360-degree experience." />
        <link rel='canonical' href='https://www.meryashome.com/panoramic/1' />
      </Helmet>
      <ReactPannellum
        id="1"
        sceneId="firstScene"
        imageSource={imageSource}
        config={config}
        style={{
          width: "100%",
          height: "100vh",
        }}
      />
    </div>
  );
};

export default Panoramic;
