import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./ProfileComponent.css";
import OrderCard from "./OrderCard";
import { FaListUl } from "react-icons/fa";
import { FaTable } from "react-icons/fa";
import WindowWidthComponent from "../../WindowWidthComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  getOrdersForAdmin,
  getOrdersFiltred,
  getOrdersFiltredForAdmin,
} from "../../../redux/actions/OrdersActions";
import { FallingLines } from "react-loader-spinner";

const ProfileComponent = () => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [userNameFilter, setUserNameFilter] = useState(""); // Ajout du state pour le filtre par nom d'utilisateur
  const [addressFilter, setAddressFilter] = useState("");

  let credString = localStorage.getItem("cred");
  let cred = JSON.parse(credString);

  let isAdmin = cred.user.Role === "Superadmin" ? true : false;

  const dispatch = useDispatch();
  useEffect(() => {
    if (isAdmin) {
      dispatch(getOrdersForAdmin());
    } else {
      dispatch(getOrders());
    }
  }, []);

  const { orders } = useSelector((state) => state.getOrders);
  const { ordersAdmin } = useSelector((state) => state.getOrdersForAdmin);
  const { filtredOrders } = useSelector((state) => state.getOrdersFiltred);
  const { filtredOrdersForAdmin } = useSelector(
    (state) => state.getOrdersFiltredForAdmin
  );
  let getLoading = isAdmin ? "getOrdersForAdmin" : "getOrders";
  const { loading } = useSelector((state) =>
    isAdmin ? state[getLoading] : state[getLoading]
  );
  let getLoadingF = isAdmin ? "getOrdersFiltredForAdmin" : "getOrdersFiltred";
  const { loadingF } = useSelector((state) =>
    isAdmin ? state[getLoadingF] : state[getLoadingF]
  );

  const [displayMode, setDisplayMode] = useState("tableau");

  let OrdersToDisplay = isAdmin
    ? selectedFilter === "" && userNameFilter === ""
      ? ordersAdmin
      : selectedFilter === "All" && userNameFilter === ""
      ? ordersAdmin
      : filtredOrdersForAdmin
    : selectedFilter === "" && addressFilter === ""
    ? orders
    : selectedFilter === "All" && addressFilter === ""
    ? orders
    : filtredOrders;

  const OrdersExist =
    OrdersToDisplay &&
    OrdersToDisplay.orders &&
    OrdersToDisplay.orders.length !== 0;

  const { t } = useTranslation();

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
  };
  //for responsive style
  const windowWidth = WindowWidthComponent();

  const handleFilterChange = async (event) => {
    const filterValue = event.target.value;
    setSelectedFilter(filterValue);

    // Ajouter la gestion de l'option "validated" ici
    if (isAdmin) {
      if (filterValue === "validated") {
        dispatch(getOrdersFiltredForAdmin(null, userNameFilter, true));
      } else {
        dispatch(getOrdersFiltredForAdmin(filterValue, userNameFilter));
      }
    } else {
      dispatch(getOrdersFiltred(filterValue, addressFilter));
    }
  };
  const handleUserNameFilterChange = (event) => {
    const userName = event.target.value;

    setUserNameFilter(userName);

    // Update the filter based on both the user name and selected filter
    if (isAdmin) {
      if (selectedFilter === "All") {
        dispatch(getOrdersFiltredForAdmin(null, userName));
      } else if (selectedFilter === "validated") {
        dispatch(getOrdersFiltredForAdmin(null, userName, true));
      } else {
        dispatch(getOrdersFiltredForAdmin(selectedFilter, userName));
      }
    } else {
      dispatch(getOrdersFiltred(selectedFilter, userName));
    }
  };
  const handleAddressFilterChange = (event) => {
    const address = event.target.value;

    setAddressFilter(address);

    if (selectedFilter === "All") {
      dispatch(getOrdersFiltred(null, address));
    } else {
      dispatch(getOrdersFiltred(selectedFilter, address));
    }
  };

  useEffect(() => {}, [filtredOrders]);

  return (
    <div className="profile-container">
      {(loading || loadingF) && (
        <div className="loader-wrapper">
          <FallingLines
            color="#041C32"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
          />
        </div>
      )}
      <div className="profile-title">
        <h1>{t("account.title")}</h1>
        <div className="filter-credit">
          <div className="filter">
            <label>Filter :</label>
            <select className="filter-selector" onChange={handleFilterChange}>
              <option className="item">{t("filters.f3")}</option>
              <option className="item">{t("filters.f1")}</option>
              <option className="item">{t("filters.f2")}</option>
              {isAdmin && (
                <option className="item" value="validated">
                  validated
                </option>
              )}
            </select>
          </div>

          {!isAdmin && (
            <div>
              <label>Search par address:</label>

              <input
                type="text"
                value={addressFilter}
                onChange={handleAddressFilterChange}
              />
            </div>
          )}

          {isAdmin && (
            <>
              <p>search with user name</p>
              <input
                type="text"
                value={userNameFilter}
                onChange={handleUserNameFilterChange}
              />
            </>
          )}
                   

        </div>
      </div>
      {OrdersExist && (
        <div
          className="btns-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <button onClick={() => handleDisplayModeChange("tableau")}>
              <FaTable />
            </button>
            <button onClick={() => handleDisplayModeChange("liste")}>
              <FaListUl />
            </button>
          </div>

          {!isAdmin && (
            <p style={{ fontSize: "15px" }}>
              {t("account.credit")} : {cred.user.Credit} photos
            </p>
          )}
          
        </div>
      )}
      

      {OrdersExist ? (
        <OrderCard
          displayMode={displayMode}
          orders={OrdersToDisplay}
          isAdmin={isAdmin}
        />
      ) : (
        <div
          style={{
            margin: "52px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {!isAdmin && (
            <p style={{ fontSize: "15px" }}>
              {t("account.credit")} : {cred.user.Credit} photos
            </p>
          )}
          <div className="profile-message">
            <p>{t("account.no-order-msg")}</p>
          </div>
        </div>
      )}

      
    </div>
  );
};

export default ProfileComponent;
