import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StepSignup = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const totalSteps = 3;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    if (stepContent.value) {
      if (step < totalSteps) {
        setStep(step + 1);
      } else {
        // Save data to localStorage and navigate to sign-up page on the last step
        localStorage.setItem("signUpData", JSON.stringify(formData));
        navigate("/sign-up");
      }
    }
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return {
          label: t("signup.firstNameLabel"),
          name: "firstName",
          value: formData.firstName,
          placeholder: t("signup.firstNamePlaceholder")
        };
      case 2:
        return {
          label: t("signup.lastNameLabel"),
          name: "lastName",
          value: formData.lastName,
          placeholder: t("signup.lastNamePlaceholder")
        };
      case 3:
        return {
          label: t("signup.emailLabel"),
          name: "email",
          value: formData.email,
          placeholder: t("signup.emailPlaceholder")
        };
      default:
        return {};
    }
  };

  const stepContent = getStepContent();

  return (
    <div className="flex items-center justify-center bg-[#041c32]">
      <div className="flex flex-col md:flex-row items-center space-x-0 md:space-x-8 text-white w-full p-8">
        <div className="md:w-1/2 text-center md:text-left">
          <h3 className="text-[#ecb365] font-bold text-4xl uppercase mb-2 tracking-widest">
            {t("signup.title")}
          </h3>
        </div>
        <div className="md:w-1/2">
          <label className="block text-sm font-medium mb-1" htmlFor={stepContent.name}>
            {stepContent.label}
          </label>
          <div className="w-fit">
            <div className="flex items-center">
              <input
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleNext();
                  }
                }}
                type="text"
                name={stepContent.name}
                id={stepContent.name}
                value={stepContent.value}
                onChange={handleInputChange}
                className="text-black px-2 py-2 w-72 border-none rounded-l-lg"
                placeholder={stepContent.placeholder}
                required
              />
              <button
                onClick={handleNext}
                className="bg-[#ecb365] h-[40px] rounded-r-lg hover:bg-gray-700 transition"
              >
                <FiArrowRight className="text-white text-lg" />
              </button>
            </div>
            <p className="text-xs text-right mt-2">
              {t("signup.steps", { current: step, total: totalSteps })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepSignup;
