import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './Banner.css'
import { LinkContainer } from "react-router-bootstrap";
import bannerData from "../../../data/banner.json"; 
import mobileBannerData from "../../../data/mobile_banner.json"; 
import { useWindowWidth } from "../../../utils/WindowWidth";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Banner = () => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();
  const [banners, setBanners] = useState(bannerData);

  // Adjust banners based on window width
  useEffect(() => {
    if (windowWidth <= 800) {
      setBanners(mobileBannerData);
    } else {
      setBanners(bannerData);
    }
  }, [windowWidth]);

  const customOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 1 },
      940: { items: 1 },
    },
    nav: false,
  };

  const images = (imgName) => {
    return require(`../../../assets/img/banner/${imgName}`);
  };

  const handleOrderClick = () => {
    localStorage.setItem("serviceURL", "placeOrder");
  };

  return (
    <div className="banner-container">
      <div>
        <OwlCarousel className="owl-theme" {...customOptions}>
          {banners.map(banner => (
            <div key={banner.id}>
              <LazyLoadImage
                src={images(banner.image)}
                placeholderSrc={images(banner.image)} // Same image for placeholder
                alt={banner.alt}
                // effect="opacity"
                threshold={300} // Load the image 300px before entering the viewport
                width="100%" // Ensures image fits container
                height="auto"
                effect="blur"
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
      <div className="banner-header">
        <h1 className="banner-header-title">{t("home.banner.title")}</h1>
        <LinkContainer to="/prices">
          <button className="banner-header-btn" onClick={handleOrderClick}>
            Order Now
          </button>
        </LinkContainer>
      </div>
    </div>
  );
};

export default Banner;
