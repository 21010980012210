import React, { useEffect, useState } from "react";
import "./Modeling.css";
import threeDM from "../../../data/3D-modeling.json";
import { useTranslation } from "react-i18next";

const Modeling = () => {
  const { t } = useTranslation();

  const images = (imgName) => {
    return require(`../../../assets/img/3dRendering/${imgName}`);
  };
  const [threeDMData, setThreeDMData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("lng"));

  useEffect(() => {
    setThreeDMData(threeDM);
  }, []);
  const storedLanguage = localStorage.getItem("lng");
  if (storedLanguage !== language) {
    setLanguage(storedLanguage);
  }
  return (
    <>
      <section>
        <div className="vs-container">
        <h1 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-14 mt-24" >3D Rendering</h1>
        <div className="border max-w-[940px] w-full m-0 !border-[#06466366] "></div>
          <p>{t("gallery.3DM.text")}</p>
          <div className="vsImagesAll">
            {threeDMData.map((item, index) => (
              <div className="vsImages" key={index}>
                <img src={images(item.images)} alt={`${item.title}`} />
                <div className="titleRef">
                  {/* {language === "en" ? (
                    <h2>{item.title}</h2>
                  ) : language === "fr" ? (
                    <h2>{item.titleFr}</h2>
                  ) : null} */}
                  {/* <p>{item.Ref} </p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Modeling;
