export const ADD_NEW_USER_REQUEST="ADD_NEW_USER_REQUEST"
export const ADD_NEW_USER_SUCCESS="ADD_NEW_USER_SUCCESS"
export const ADD_NEW_USER_FAIL="ADD_NEW_USER_FAIL"

export const LOGIN_REQUEST="LOGIN_REQUEST"
export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_FAIL="LOGIN_FAIL"
export const LOGOUT="LOGOUT"

export const GET_USER_DETAILS_REQUEST="GET_USER_DETAILS_REQUEST"
export const GET_USER_DETAILS_SUCCESS="GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL="GET_USER_DETAILS_FAIL"

export const RESET_PWD_REQUEST="RESET_PWD_REQUEST"
export const RESET_PWD_SUCCESS="RESET_PWD_SUCCESS"
export const RESET_PWD_FAIL="RESET_PWD_FAIL"


export const UPDATE_USER_CREDITS_REQUEST="UPDATE_USER_CREDITS_REQUEST"
export const UPDATE_USER_CREDITS_SUCCESS="UPDATE_USER_CREDITS_SUCCESS"
export const UPDATE_USER_CREDITS_FAIL="UPDATE_USER_CREDITS_FAIL"



export const GET_ONE_USER_FORADMIN_REQUEST="GET_ONE_USER_FORADMIN_REQUEST"
export const GET_ONE_USER_FORADMIN_SUCCESS="GET_ONE_USER_FORADMIN_SUCCESS"
export const GET_ONE_USER_FORADMIN_FAIL="GET_ONE_USER_FORADMIN_FAIL"