// src/PrivacyPolicy.js

import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 font-sans">
      {/* Header */}
      <div className='flex items-center flex-col justify-center pt-12 pb-12'>
        <h1 style={{ fontSize: "30px", letterSpacing: "0.08em", marginBottom: "25px", marginTop: "10px" }}>
          {t('privacy.title')}
        </h1>
        <div className="border max-w-[940px] w-full m-0 !border-[#06466366]"></div>
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg p-6">
          {/* Introduction Section */}
          <section>
            <h2 className="text-sm font-semibold mb-4">
              {t('privacy.introduction')}
            </h2>
           
          </section>

          {/* Personal Information Collection Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('privacy.personalInfoCollection.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('privacy.personalInfoCollection.content')}
            </p>
          </section>

          {/* Usage of Information Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('privacy.usageOfInformation.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('privacy.usageOfInformation.content')}
            </p>
          </section>

          {/* Protection of Information Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('privacy.protectionOfInformation.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('privacy.protectionOfInformation.content')}
            </p>
          </section>

          {/* Refunds and Returns Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('privacy.refundsAndReturns.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('privacy.refundsAndReturns.content')}
            </p>
          </section>

          {/* Contact Details Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('privacy.contactDetails.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('privacy.contactDetails.content')}
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
