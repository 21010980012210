export const ADD_NEW_RESPONSE="ADD_NEW_RESPONSE"
export const ADD_NEW_RESPONSE_SUCCESS="ADD_NEW_RESPONSE_SUCCESS"
export const ADD_NEW_RESPONSE_FAIL="ADD_NEW_RESPONSE_FAIL"




export const ADD_NEW_ORDER_CHANGES_REQUEST="ADD_NEW_ORDER_CHANGES_REQUEST"
export const ADD_NEW_ORDER_CHANGES_SUCCESS="ADD_NEW_ORDER_CHANGES_SUCCESS"
export const ADD_NEW_ORDER_CHANGES_FAIL="ADD_NEW_ORDER_CHANGES_FAIL"



export const ADD_NEW_CHANGE_RESPONSE="ADD_NEW_CHANGE_RESPONSE"
export const ADD_NEW_CHANGE_RESPONSE_SUCCESS="ADD_NEW_CHANGE_RESPONSE_SUCCESS"
export const ADD_NEW_CHANGE_RESPONSE_FAIL="ADD_NEW_CHANGE_RESPONSE_FAIL"