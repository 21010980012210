import React from "react";
import { useTranslation } from "react-i18next";
import "./ProgressBarComponent.css";

const ProgressBarComponent = ({  address, title, status,userName,isAdmin}) => {
  const { t } = useTranslation();

  const circleStyle = (color, height, width) => ({
    height: height,
    width: width,
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
    textAlign: "center",
    position: "relative",
  });

  const lineStyle = (color) => ({
    width: "200px",
    height: "1px",
    border: `1px solid ${color}`,
    display: "inline-block",
  });

  const textStyle = {
    position: "absolute",
    top: "50px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "Nunito",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "17px",
    letterSpacing: "0.02em",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  return (
    <div className="order-details-container">
          <div className="order-details-title">
              <h1>{t("account.title")}</h1>
              <div className="order-details-title-details">
                <h2>{title}</h2>
                <div>
                <p>Address: {address}</p>
                {isAdmin && <p style={{padding:"20px 0"}}>User Name: {userName}</p>}
                </div>

              </div>
            </div>
      <div className="order-details-body">
  
        <div style={circleStyle("#FF9401", "14px", "14px")}>
          <span style={textStyle}>Order placed</span>
        </div>
        <div style={lineStyle("#FF9401")}></div>

        {status === "In progress" ? (
          <>
            <div style={circleStyle("#FF9401", "28px", "28px")}>
              <span style={textStyle}>In progress</span>
            </div>
            <div style={lineStyle("#FF940180")}></div>
            <div style={circleStyle("#FF940180", "14px", "14px")}>
              <span style={textStyle}>Ready</span>
            </div>
          </>
        ) : (
          <>
            <div style={circleStyle("#FF9401", "14px", "14px")}>
              <span style={textStyle}>In progress</span>
            </div>
            <div style={lineStyle("#FF9401")}></div>
            <div style={circleStyle("#FF9401", "28px", "28px")}>
              <span style={textStyle}>Ready</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgressBarComponent;
