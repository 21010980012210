import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./StagedListing.css";
import { useTranslation } from "react-i18next";
import prevIcon from "../../../assets/img/icons/prev.svg";
import nextIcon from "../../../assets/img/icons/next.svg";
import WindowWidthComponent from "../../WindowWidthComponent";
import axios from "axios";
import { environment } from "../../../envirement";
import ImagesGridLoader from "../../Loader/ImagesGridLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const StagedListing = () => {
  const { t } = useTranslation();
  const windowWidth = WindowWidthComponent();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${environment.apiUrl}/recentlyStaged/getAllRecentlyStaged`
        );
        setListings(response.data);
      } catch (error) {
        console.error("Error fetching listings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, []);

  const options = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    nav: true,
    navText: [
      `<img src=${prevIcon} alt="Previous" style="margin-top: 13px;" />`,
      `<img src=${nextIcon} alt="Next" />`,
    ],
    responsive: {
      0: {
        items: 1, // 1 image per slider for smaller screens
        nav: false,
      },
      600: {
        items: 1,
        nav: true,
      },
      1280: {
        items: 1, // Show 1 slider containing 6 images for larger screens
      },
    },
  };

  return (
    <div className="staged-listing-container">
      <h1 className="vs-title">{t("home.recent-listings.title")}</h1>
      {loading ? (
        <ImagesGridLoader />
      ) : (
        <OwlCarousel className="owl-theme" {...options}>
          {listings?.map((item) => {
            const images = item.Images;

            if (windowWidth <= 1280) {
              // Responsive mode: 1 image per slide
              return images.map((image, index) => (
                <div key={`${item.id}-${index}`} className="item-container">
                 
                  <LazyLoadImage
         
                    id="image-item-responsive"
                    src={imgPath(image)}
                    alt={`${item.id}-${index}`}
                    effect="blur"
                    afterLoad={() => setLoaded(true)}

                  />
                </div>
              ));
            } else {
              // Larger screens: 6 images per slide
              const groupedImages = [];
              for (let i = 0; i < images.length; i += 6) {
                groupedImages.push(images.slice(i, i + 6));
              }

              return groupedImages.map((group, groupIndex) => (
                <div key={`${item.id}-group-${groupIndex}`} className="item-container">
                  <div
                    className="item"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    {group.map((image, index) => (
                      <LazyLoadImage
                        id="image-item"
                        key={`${groupIndex}-${index}`}
                        src={imgPath(image)}
                        alt={`${groupIndex}-${index}`}
                        effect="blur"
                        style={{ flex: "1 1 calc(16.66% - 10px)" }} // Divide space for 6 images
                      />
                    ))}
                  </div>
                </div>
              ));
            }
          })}
        </OwlCarousel>
      )}
    </div>
  );
};

export default StagedListing;
