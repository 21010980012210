import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";

const VrTour = () => {
  const panoramaFrameRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    const height = width < 800 ? "600px" : "700px";

    const iframe = document.createElement('iframe');
    iframe.src = "/assets/pano/index.html"; // Absolute path
    iframe.width = "100%";
    iframe.height = height;
    iframe.frameBorder = "0";
    iframe.allowFullscreen = true;
    iframe.mozallowfullscreen = true;
    iframe.webkitallowfullscreen = true;

    const panoramaFrame = panoramaFrameRef.current;
    if (panoramaFrame) {
      panoramaFrame.appendChild(iframe);
    }

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      // Cleanup
      if (panoramaFrame) {
        panoramaFrame.removeChild(iframe);
      }
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      panoramaFrameRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div>
      <Helmet>
        <meta name="keywords" content="VR tour, virtual reality, immersive experience" />
        <meta name="description" content="Explore our VR tour for an immersive and virtual reality experience. Enter full-screen mode for a more captivating adventure." />
        <link rel='canonical' href='https://www.meryashome.com/vrTour' />
      </Helmet>
      <div ref={panoramaFrameRef}></div>
      <button onClick={toggleFullscreen}>
        {isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
      </button>
    </div>
  );
};

export default VrTour;
