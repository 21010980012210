import {
    ADD_NEW_RESPONSE,
    ADD_NEW_ORDER_CHANGES_REQUEST,
    ADD_NEW_CHANGE_RESPONSE,
    ADD_NEW_ORDER_CHANGES_SUCCESS,
    ADD_NEW_ORDER_CHANGES_FAIL,
  } from "../constants/ResponsesConstants";
  
  const initialState = {
    responses: [],
    changes :[],
    changeResponse :[]
  };
  
  export const addResponseReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_NEW_RESPONSE:
        return {
          ...state,
          responses: [...state.responses, action.payload],
          changeResponse: [...state.changeResponse, action.payload],
          
        };
      default:
        return state;
    }
  };
  
  // get response to order getOrderResponse
  export const addChangesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_NEW_ORDER_CHANGES_REQUEST:
        return {
          ...state,
          changes: [...state.changes, action.payload],
          loading : true
        };
        
        case ADD_NEW_ORDER_CHANGES_SUCCESS:
            return {loading:false,message:action.payload}
        case ADD_NEW_ORDER_CHANGES_FAIL:
            return {loading:false,error:"change already exists"} 
        default:
            return state
    }
  };
  
  // get response to order getOrderResponse

  export const addChangeResponseReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_NEW_CHANGE_RESPONSE:
        return {
          ...state,
          changeResponse: [...state.changeResponse, action.payload],
        };
      default:
        return state;
    }
  };