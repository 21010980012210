// // import React, { useEffect, useState } from 'react';
// // import "./ChangesComponents.css";
// // import { useTranslation } from "react-i18next";
// // import { useDispatch, useSelector } from "react-redux";
// // import {
// // addNewChange
// // } from "../../../../redux/actions/ResponsesActions";
// // import axios from 'axios';
// // import { environment } from '../../../../envirement';
// // import { SnackbarProvider, enqueueSnackbar } from "notistack";

// // const ChangesComponents = ({ changes, feedbacks, setShowButtons, response, order }) => {
// //   const dispatch = useDispatch();

// //   const { t } = useTranslation();
// //   const [infos, setInfos] = useState({
// //     title: "",
// //     description: "",
// //     placeholder: "",
// //     btn: ""
// //   });
// //   const [textInput, setTextInput] = useState(""); // to manage the text input
// //   const [displayedText, setDisplayedText] = useState(""); // to manage displayed text

// //   useEffect(() => {
// //     if (changes) {
// //       setInfos({
// //         title: t("changes.changes-title"),
// //         description: t("changes.description"),
// //         placeholder: t("changes.placeholder"),
// //         btn: t("changes.btn"),
// //       });
// //     } else if (feedbacks) {
// //       setInfos({
// //         title: t("feedbacks.feedbacks-title"),
// //         description: "",
// //         placeholder: t("feedbacks.placeholder"),
// //         btn: t("feedbacks.btn")
// //       });
// //     }
// //   }, [changes, feedbacks, t]);

// //   const {user}=useSelector(state=>state.loginDetails.user)
// //   const Language=localStorage.getItem('i18nextLng')
// //   const handleButtonClick =async () => {
// //     // Update displayedText with the content of the textarea when the button is clicked
// //     if (changes) {
// //       setDisplayedText(textInput);
// //     console.log("textInput")
// //     console.log(textInput)
// //     dispatch(addNewChange(textInput,order.id,response.id));
// // console.log(`changes`)
// //     // Clear the text in the textarea
// //     setTextInput("");
// //     } else {
// //       axios.post(`${environment.apiUrl}/testimonialAPI/AddTestimonial/${user.id}/${order.id}/${Language}`,{Content:textInput})
// //       .then(response => {
// //        console.log(response.data)
// //        enqueueSnackbar(response.data)
// //       })
// //       .catch(error => {
// //         console.error('Error fetching data:', error);
// //         enqueueSnackbar(error)
// //       });
// //     }
// //   };


// //   return (
// //     <div className='changes-container' style={{ paddingTop: setShowButtons && "50px" }}>
// //         <SnackbarProvider
// //         anchorOrigin={{
// //           vertical: "bottom",
// //           horizontal: "center",
// //         }}
// //       />
// //       <h1>{infos.title}</h1>
// //       <p>{infos.description}</p>
// //       {changes && displayedText && (
// //         // Display the entered text in a div when changes is true and there is text to display
// //         <div className="displayed-text">{displayedText}</div>

        
// //       )}
// //       <textarea
// //         placeholder={infos.placeholder}
// //         value={textInput}
// //         onChange={(e) => setTextInput(e.target.value)}
// //       />
// //       <button
// //         style={{ background: changes ? "#FF9401" : "#044C7B" }}
// //         onClick={handleButtonClick}
// //       > 
// //         {infos.btn}
// //       </button>
      
// //     </div>
// //   );
// // };

// // export default ChangesComponents;

// import React, { useEffect, useState } from 'react';
// import "./ChangesComponents.css";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addNewChange
// } from "../../../../redux/actions/ResponsesActions";
// import axios from 'axios';
// import { environment } from '../../../../envirement';
// import { SnackbarProvider, enqueueSnackbar } from "notistack";

// const ChangesComponents = ({ changes, feedbacks, setShowButtons, response, order }) => {
//   const dispatch = useDispatch();

//   const { t } = useTranslation();
//   const [infos, setInfos] = useState({
//     title: "",
//     description: "",
//     placeholder: "",
//     btn: ""
//   });
//   const [textInput, setTextInput] = useState(""); // to manage the text input
//   const [displayedText, setDisplayedText] = useState(""); // to manage displayed text
//   const [buttonClicked, setButtonClicked] = useState(false); // state to track button click

//   useEffect(() => {
//     if (changes) {
//       setInfos({
//         title: t("changes.changes-title"),
//         description: t("changes.description"),
//         placeholder: t("changes.placeholder"),
//         btn: t("changes.btn"),
//       });
//     } else if (feedbacks) {
//       setInfos({
//         title: t("feedbacks.feedbacks-title"),
//         description: "",
//         placeholder: t("feedbacks.placeholder"),
//         btn: t("feedbacks.btn")
//       });
//     }
//   }, [changes, feedbacks, t]);

//   const { user } = useSelector(state => state.loginDetails.user);
//   const Language = localStorage.getItem('i18nextLng');

//   const handleButtonClick = async () => {
//     // Update displayedText with the content of the textarea when the button is clicked
//     if (changes) {
//       setDisplayedText(textInput);
//       dispatch(addNewChange(textInput, order.id, response.id));
//       // Clear the text in the textarea
//       setTextInput("");
//       setButtonClicked(true); // Set buttonClicked to true when the button is clicked
//     } else {
//       axios.post(`${environment.apiUrl}/testimonialAPI/AddTestimonial/${user.id}/${order.id}/${Language}`, { Content: textInput })
//         .then(response => {
//           console.log(response.data)
//           enqueueSnackbar(response.data)
//         })
//         .catch(error => {
//           console.error('Error fetching data:', error);
//           enqueueSnackbar(error)
//         });
//     }
//   };

//   return (
//     <div className='changes-container' style={{ paddingTop: setShowButtons && "50px" }}>
//       <SnackbarProvider
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "center",
//         }}
//       />
//       {buttonClicked && (
//         <>
//             {changes && displayedText && (
//         // Display the entered text in a div when changes is true, there is text to display, and the button is clicked
//         <div className="displayed-text">{displayedText}</div>
//       )}
//         </>
//       )}
//       {  !buttonClicked && <>
//         <h1>{infos.title}</h1>
//       <p>{infos.description}</p>
  
//       <textarea
//         placeholder={infos.placeholder}
//         value={textInput}
//         onChange={(e) => setTextInput(e.target.value)}
//       />
//       <button
//         style={{ background: changes ? "#FF9401" : "#044C7B" }}
//         onClick={handleButtonClick}
//       >
//         {infos.btn}
//       </button>
      
//       </>}
     
      

//     </div>
//   );
// };

// export default ChangesComponents;
import React, { useEffect, useState } from 'react';
import "./ChangesComponents.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FallingLines } from 'react-loader-spinner';

import {
  addNewChange
} from "../../../../redux/actions/ResponsesActions";
import axios from 'axios';
import { environment } from '../../../../envirement';
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const ChangesComponents = ({ changes, feedbacks, setShowButtons, response, order }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // Nouvel état pour contrôler l'affichage du loader
  const [shouldReload, setShouldReload] = useState(false);

  const { t } = useTranslation();
  const [infos, setInfos] = useState({
    title: "",
    description: "",
    placeholder: "",
    btn: ""
  });
  const [textInput, setTextInput] = useState(""); // to manage the text input
  const [displayedText, setDisplayedText] = useState(""); // to manage displayed text
  const [buttonClicked, setButtonClicked] = useState(false); // state to track button click

  useEffect(() => {
    if (changes) {
      setInfos({
        title: t("changes.changes-title"),
        description: t("changes.description"),
        placeholder: t("changes.placeholder"),
        btn: t("changes.btn"),
      });
    } else if (feedbacks) {
      setInfos({
        title: t("feedbacks.feedbacks-title"),
        description: "",
        placeholder: t("feedbacks.placeholder"),
        btn: t("feedbacks.btn")
      });
    }
  }, [changes, feedbacks, t]);

  const { user } = useSelector(state => state.loginDetails.user);
  const Language = localStorage.getItem('i18nextLng');

  const handleButtonClick = async () => {
    // Update displayedText with the content of the textarea when the button is clicked
    if (changes) {
      setDisplayedText(textInput);
      setLoading(true); // Activer le loader au début de la requête
      dispatch(addNewChange(textInput, order.id, response.id));
      // Clear the text in the textarea
      setTextInput("");
      setButtonClicked(true); // Set buttonClicked to true when the button is clicked
        // Refresh the page
    window.location.reload();
    } else {
      axios.post(`${environment.apiUrl}/testimonialAPI/AddTestimonial/${user.id}/${order.id}/${Language}`, { Content: textInput })
        .then(response => {
          // console.log(response.data)
          enqueueSnackbar(response.data)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          enqueueSnackbar(error)
        });
    }
    setShouldReload(true);

  
  };
  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);
  
  return (
    <div className='changes-container' style={{ paddingTop: setShowButtons && "50px" }}>
       {loading && (
        <div className="loader-wrapper">
          <FallingLines
            color="#041C32"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
          />
        </div>
      )}
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      {/* {buttonClicked && (
        <>
          {changes && displayedText && (
            // Display the entered text in a div when changes is true, there is text to display, and the button is clicked
            <div className="displayed-text">{displayedText}</div>
          )}
        </>
      )} */}
      {!buttonClicked && <>
        <h1>{infos.title}</h1>
        <p>{infos.description}</p>

        <textarea
          placeholder={infos.placeholder}
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
        />
        <button
          style={{ background: changes ? "#FF9401" : "#044C7B" }}
          onClick={handleButtonClick}
        >
          {infos.btn}
        </button>

      </>}


    </div>
  );
};

export default ChangesComponents;
