// BlogCardList.jsx
import React, { useEffect } from 'react';
import { blogData } from "../../data/Blogs";
import { blogDataFr } from "../../data/BlogsFr";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import Footer from '../../components/Footer/Footer';
import vs1 from "../../assets/img/vs/1 - VS_Meryas.png"

import vs6 from "../../assets/img/vs/6 - VS_Meryas.png"

import vs11 from "../../assets/img/vs/12 - VS_Meryas.png"

import vs16 from "../../assets/img/vs/17 - VS_Meryas.png"

import vs21 from "../../assets/img/vs/22 - VS_Meryas.png"
import vs22 from "../../assets/img/vs/23 - VS_Meryas.png"
import vs23 from "../../assets/img/vs/24 - VS_Meryas.png"
import vs24 from "../../assets/img/vs/25 - VS_Meryas.png"
import vs25 from "../../assets/img/vs/26 - VS_Meryas.png"
import WhyUs from '../../components/HomeComponents/WhyUs/WhyUs';
import StepSignup from '../../components/SignUpSteps';
import Steps from '../Steps';
import Partners from '../../components/HomeComponents/Partners/Partners';
import { PlaceOrder } from '../../components/HomeComponents/PlaceOrderComponent/PlaceOrder';
import Testimonials from '../../components/Testimonials/Testimonials';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeholder from "../../assets/img/placeholder.webp"
function BlogCardList() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Determine which blog data to use based on the current language
  const blogs = i18n.language === 'fr' ? blogDataFr : blogData;

  const handleClick = (blog) => {
    navigate(`/blogs/${blog.id}/${blog.title.replace(/\s+/g, '-').toLowerCase()}`);
  };

  const blogImages = [
    // Blog 1 images
    [vs1],
    // Blog 2 images
    [vs6],
    // Blog 3 images
    [vs11],
    // Blog 4 images
    [vs16],
    [vs21],
    [vs22],
    [vs23],
    [vs24],
    [vs25]
  ];
  const img = (id) => {
    return blogImages[id - 1] || null; // Subtract 1 to match array index
  };
  return (
  <>
  <Helmet>
    <title>Virtual Staging Blog - Tips, Trends, and Insights | Meryas Home</title>
    <meta name="description" content="Explore Meryas Home's virtual staging blog for the latest tips, trends, and insights in real estate staging. Learn how virtual staging can enhance property listings and attract buyers across the United States, Canada, Europe, and beyond." />
    <meta name="keywords" content="virtual staging blog, real estate staging tips, home staging trends, digital staging, virtual staging for real estate, property enhancement, online home staging, United States, Canada, Europe, United Kingdom, New Zealand, Australia" />
    <meta property="og:title" content="Virtual Staging Blog - Tips, Trends, and Insights | Meryas Home" />
    <meta property="og:description" content="Explore Meryas Home's virtual staging blog for the latest tips, trends, and insights in real estate staging. Learn how virtual staging can enhance property listings and attract buyers globally." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.meryashome.com/blogs" />
    <meta property="og:image" content="https://www.meryashome.com/images/blog-banner.jpg" />
    {/* <link rel="canonical" href="https://www.meryashome.com/blogs" /> */}
  </Helmet>

    <section className="py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-14">
          {i18n.language == 'fr' ? "L'Immobilier et la Mise en Scène Virtuelle : Ce Qu'il Faut Savoir ": ' Know more about Real Estate and Virtual Staging'}
        </h1>
        <div className='border !border-[#06466366] w-full mb-16' ></div>
        
        {/* Responsive grid layout */}
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {blogs?.map((blog) => (
            <div
              key={blog.id}
              onClick={() => handleClick(blog)}
              className="group cursor-pointer border border-gray-300 rounded-2xl p-5 transition-all duration-300 hover:border-indigo-600 h-full flex flex-col justify-between"
            >
              <div>
                <div className="flex items-center mb-6">
                  <LazyLoadImage  placeholderSrc={placeholder} effect="blur" src={img(blog.id)} alt={`${blog.imageAlt} image`} className="rounded-lg w-full object-cover" />
                </div>
                <h4 className="text-gray-900 font-medium leading-8 mb-6">{blog.title}</h4>
              </div>
              
              {/* Footer with author and publication date at the bottom */}
              <div className="flex items-center justify-between mt-auto font-medium pt-4 border-t border-gray-200">
                <h6 className="text-sm text-gray-500 flex items-center gap-1 hover:text-[#ecb365] transition-all ">Read more <ArrowRight size={20} /> </h6>
                {/* <span className="text-sm text-indigo-600">{blog.publishedAt}</span> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <WhyUs/>
    <Steps/>
    <StepSignup/>
    <Partners />
    <PlaceOrder />
    <Testimonials />
  <Footer/>
  </>

  );
}

export default BlogCardList;
