import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import  "./PaymentStatusPage.css"
import { useTranslation } from 'react-i18next';
import { environment } from '../../envirement';
const PaymentStatusPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('payment_status');
    const id = searchParams.get('id');
    const userId=searchParams.get('uid')
    const language=searchParams.get('L')
// console.log(id)

useEffect(() => {
    const updateOrderPaymentStatus = async () => {
      if (paymentStatus === 'success') {
        try {
            const Payment="Payed"
          const { data } = await axios.put(`${environment.apiUrl}/PaymentAPI/updatePayementStatus`,{id:id,Payment,userId,language} );
          // console.log('Data:', data);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
    updateOrderPaymentStatus();

  }, [paymentStatus]);


  return (
    <>
    <div className="success-message" >
        
    <svg viewBox="0 0 76 76" className="success-message__icon icon-checkmark">
      <circle cx="38" cy="38" r="36" />
      <path
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M17.7,40.9l10.9,10.9l28.7-28.7"
      />
    </svg>
    <h1 className="success-message__title">{t(`payment.title`)}</h1>
    <div className="success-message__content">
      <p>{t(`payment.p`)} </p>
    </div>
  </div>
  </>
  )
}



export default PaymentStatusPage
