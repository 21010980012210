import React from 'react'
import NotFoundComponent from '../../components/notFound/NotFoundComponent'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

const NotFoundPage = () => {
  return (
    <div>
        {/* <Header /> */}
        <NotFoundComponent/>
        <Footer/>
    </div>
  )
}

export default NotFoundPage