import React from 'react';
import "./TestimonialLoader.css";

export const TestimonialLoader = () => {
  return (
    <div className='maindiv'>
     <ul className="o-vertical-spacing o-vertical-spacing--l">
        <li className="blog-post o-media">
          <div className="o-media__figure">
            <span className="skeleton-box" style={{width: '176px', height: '209px'}} />
          </div>
          <div className="o-media__body">
            <div className="o-vertical-spacing">
              <h3 className="blog-post__headline">
                <span className="skeleton-box" style={{width: '10%'}} />
              </h3>
              <p className="skeleton-p">
                <span className="skeleton-box" style={{width: '45%'}} />
                <span className="skeleton-box" style={{width: '40%'}} />
                <span className="skeleton-box" style={{width: '45%'}} />
                <span className="skeleton-box" style={{width: '40%'}} />
              </p>
              <div className="blog-post__meta">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TestimonialLoader;
