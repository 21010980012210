import axios from "axios";
import {
  ADD_NEW_RESPONSE,
  ADD_NEW_RESPONSE_SUCCESS,
  ADD_NEW_RESPONSE_FAIL,
  ADD_NEW_ORDER_CHANGES_REQUEST,
ADD_NEW_ORDER_CHANGES_SUCCESS,
ADD_NEW_ORDER_CHANGES_FAIL,
ADD_NEW_CHANGE_RESPONSE,
ADD_NEW_CHANGE_RESPONSE_SUCCESS,
ADD_NEW_CHANGE_RESPONSE_FAIL,
} from "../constants/ResponsesConstants";
import { environment } from "../../envirement";
const API_URL = `${environment.apiUrl}/changesAPI/`;

//response de l admin sur une commande
export const addNewResponse = (newResponse) => async (dispatch, getStatus) => {
  try {

    // console.log(newResponse.Images)
    dispatch({ type: ADD_NEW_RESPONSE, payload: newResponse });
    var formData = new FormData();
    formData.append('OrderId', newResponse.OrderId);
    newResponse.Images && newResponse.Images.map((el)=>formData.append('Images',el))
   
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // console.log(config);
    // console.log("Request Payload:", formData);

    const { data } = await axios.post(
      API_URL + "CreateResponse",
      formData
          //   ,config
    );

    dispatch({ type: ADD_NEW_RESPONSE_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error adding new response:", error);
    dispatch({ type: ADD_NEW_RESPONSE_FAIL });
  }
};
export const addNewChangeResponse = (newChangeResponse, idChange) => async (dispatch, getStatus) => {
  try {

    // console.log(newChangeResponse.orderId)
    dispatch({ type: ADD_NEW_CHANGE_RESPONSE, payload: newChangeResponse });
    var formData = new FormData();
    formData.append('orderId', newChangeResponse.orderId);
    formData.append('userId', newChangeResponse.userId);
    // formData.append('order', newChangeResponse.order);
    const orderValue = typeof newChangeResponse.order === 'object' ? JSON.stringify(newChangeResponse.order) : newChangeResponse.order;
    formData.append('order', orderValue);
    //orderId,userId,order
    newChangeResponse.Images && newChangeResponse.Images.map((el)=>formData.append('Images',el))
   
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // console.log(config);
    // console.log("Request Payload:", formData);
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    const { data } = await axios.post(
      API_URL + `ChangeResponse/${idChange}`,
      formData
          //   ,config
    );

    dispatch({ type: ADD_NEW_CHANGE_RESPONSE_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error adding new change_response:", error);
    dispatch({ type: ADD_NEW_CHANGE_RESPONSE_FAIL });
  }
};

//Demande changes sur retour d une commande par admin
export const addNewChange = (newChange,idOrder,idResponse) => async (dispatch, getStatus) => {
  try {

    dispatch({ type: ADD_NEW_ORDER_CHANGES_REQUEST, payload: newChange });
    // var formData = new FormData();
   
    // formData.append('Description', "zebi 3imed");
const Description=newChange
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // console.log(config);
    // console.log("Request Payload:", formData);
    // for (const iterator of formData) {
    //   console.log(iterator)
    // }

    const { data } = await axios.post(
      API_URL + `createChange/${idOrder}/${idResponse}`,
      {Description:Description},
      config
    );

    dispatch({ type: ADD_NEW_ORDER_CHANGES_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error adding new changes:", error);
    dispatch({ type: ADD_NEW_ORDER_CHANGES_FAIL });
  }
};

