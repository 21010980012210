import { LinkContainer } from "react-router-bootstrap";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import WeOffer from "../../components/HomeComponents/WeOffer/WeOffer";
import WhyUs from "../../components/HomeComponents/WhyUs/WhyUs";
import { t } from "i18next";
import '../../components/HomeComponents/Banner/Banner.css'

const images = [
  'https://static.vecteezy.com/system/resources/previews/022/903/424/non_2x/ai-generative-3d-modern-luxury-real-estate-house-for-sale-and-rent-luxury-property-concept-ai-generated-artwork-photo.jpg',
  'https://static.vecteezy.com/system/resources/previews/022/903/424/non_2x/ai-generative-3d-modern-luxury-real-estate-house-for-sale-and-rent-luxury-property-concept-ai-generated-artwork-photo.jpg',
  'https://static.vecteezy.com/system/resources/previews/022/903/424/non_2x/ai-generative-3d-modern-luxury-real-estate-house-for-sale-and-rent-luxury-property-concept-ai-generated-artwork-photo.jpg',
  'https://static.vecteezy.com/system/resources/previews/022/903/424/non_2x/ai-generative-3d-modern-luxury-real-estate-house-for-sale-and-rent-luxury-property-concept-ai-generated-artwork-photo.jpg',
  'https://static.vecteezy.com/system/resources/previews/022/903/424/non_2x/ai-generative-3d-modern-luxury-real-estate-house-for-sale-and-rent-luxury-property-concept-ai-generated-artwork-photo.jpg',
];

const contentData = [
  {
    title: "Virtual Staging and Real Estate in Montreal",
    text: "Montreal’s real estate market is thriving, and virtual staging has become an essential tool to make properties stand out. With Meryas Home, virtual staging allows sellers to showcase homes in their best light by adding digital furniture and decor, transforming vacant or outdated spaces into attractive listings."
  },
  {
    title: "Why Virtual Staging?",
    text: "In Montreal, where real estate is competitive, staging helps properties sell faster and for more money. According to statistics, 83% of buyers find it easier to visualize a property when it’s staged, and homes that are staged virtually can sell up to 73% faster than non-staged homes."
  },
  {
    title: "3D rendering and Renovations",
    text: "Meryas Home also provides 3D rendering services, which allow potential buyers to envision renovations or upgrades. This is especially helpful in Montreal, where many older homes are being renovated. With 3D models, buyers can see the possibilities before making an offer, increasing the chances of a sale."
  },
  {
    title: "Cost-Effective and Convenient",
    text: "Virtual staging is much more cost-effective than traditional staging. There’s no need to rent furniture, and staging can be done quickly and remotely. Sellers can highlight specific features, such as large windows or open floor plans, to attract buyers."
  },
  {
    title: "What Meryas Home Provides?",
    text: "Meryas Home provides a variety of services to enhance property listings: Virtual staging: Digitally furnish and decorate properties. 3D rendering for renovations: Showcase potential upgrades or redesigns. Virtual tours: Allow buyers to explore properties remotely."
  },
  {
    title: "Why Choose Virtual Staging with Meryas Home?",
    text: "Cost-efficient: No need for expensive physical staging. Flexible design options: Customizable for any property style. Enhanced buyer engagement: Properties with staging generate more interest and sell faster. For sellers looking to maximize their property’s potential and stand out in the Montreal real estate market, Meryas Home’s virtual staging services provide the perfect solution."
  }
];

const City = () => {
  const handleOrderClick = () => {
    localStorage.setItem("serviceURL", "placeOrder");
  };

  return (
    <>
      <div className="px-[8%]"> {/* Apply consistent padding to the container */}
        {/* Title Section */}
        <div className="flex-1 flex-col items-start mt-32 w-full">
          <div className="font-['DM_Sans'] text-5xl not-italic font-semibold w-full max-w-[640px]">
            Virtual Staging and Real Estate in Montreal
          </div>
        </div>

        {/* Image Grid Section */}
        <div className="flex justify-center mt-12">
          <div className="lg:grid grid-cols-3 grid-rows-4 gap-1 w-fit sm:flex">
            {images.map((img, index) => {
              // Dynamically assigning grid position based on index with corrected order
              const classNames = [
                'row-span-2',                         // First image
                'row-span-2 col-start-1 row-start-1', // Second image
                'row-span-2 row-start-3',             // Third image
                'row-span-4 col-start-2 row-start-1 h-full', // Fourth image
                'row-span-2 col-start-3 row-start-3', // Fifth image
              ];

              return (
                <img 
                  key={index}
                  src={img} 
                  alt={`img-${index}`} 
                  className={`sm:h-[200px] sm:w-[200px] lg:h-full lg:w-full rounded-lg object-cover ${classNames[index % classNames.length]} `} 
                />
              );
            })}
          </div>
        </div>

        {/* Text Section */}
        <div className="justify-center w-full items-start gap-8 inline-flex mt-12">
          <div className="w-full  flex-col justify-start items-center inline-flex">
            {contentData.map((section, index) => (
              <div key={index} className={`self-stretch pb-10 border-b border-[#e5e8eb] flex-col justify-start items-start gap-6 flex`}>
                <div className="self-stretch text-[#22262e] text-xl font-semibold font-['DM_Sans'] leading-loose">
                  {section.title}
                </div>
                <div className="self-stretch text-[#777e90] text-base font-normal font-['DM_Sans'] leading-normal">
                  {section.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Additional Components */}
        {/* Banner Section */}
        <div className="banner-header bg-[#041c32]">
        <h1 className="banner-header-title">{t("home.banner.title")}</h1>
        <LinkContainer to={"/prices"}>
        {/* <LinkContainer to={"/placeOrder"}> */}
          <button className="banner-header-btn" onClick={handleOrderClick}>Order Now</button>
        </LinkContainer>
      </div>
      <WeOffer />
      
      <PlaceOrder />


      <WhyUs />
    </>
  );
};

export default City;
