import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import SignUpComponent from "../../../components/Authentification/SignUp/SignUpComponent";
import { Helmet } from 'react-helmet';

const SignUpPage = () => {
  const navigate = useNavigate();
  const storedCred = JSON.parse(localStorage.getItem("cred"));
  const isLoggedIn =
    storedCred && storedCred.user && storedCred.user.id !== null;

  useEffect(() => {
    // If the user is logged in, redirect them to the home page
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <div>
    <Helmet>
  {/* Page Title */}
  <title>Sign Up - Meryas Home | Join Our Virtual Staging Community</title>
  {/* Meta Description */}
  <meta name="description" content="Create your Meryas Home account to access top-quality virtual staging, renovation services, and real estate enhancement tools. Join our community and transform your listings!" />
  {/* Keywords */}
  <meta name="keywords" content="Meryas Home sign up, create account, join Meryas Home, virtual staging signup, property enhancement signup" />
  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content="Sign Up - Meryas Home | Join Our Virtual Staging Community" />
  <meta property="og:description" content="Join Meryas Home to access professional virtual staging and real estate services. Sign up today to elevate your property listings." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.meryashome.com/sign-up" />
  <meta property="og:image" content="https://www.meryashome.com/images/signup-banner.jpg" />
  <meta property="og:site_name" content="Meryas Home" />
  {/* Twitter Meta Tags for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Sign Up - Meryas Home | Join Our Virtual Staging Community" />
  <meta name="twitter:description" content="Create a Meryas Home account to start enhancing your real estate listings with virtual staging and renovation services." />
  <meta name="twitter:image" content="https://www.meryashome.com/images/signup-banner.jpg" />
  <meta name="twitter:site" content="@MeryasHome" />
  {/* Canonical Link */}
  <link rel="canonical" href="https://www.meryashome.com/sign-up" />
</Helmet>
      {/* <Header /> */}
      <SignUpComponent />
      <Footer />
    </div>
  );
};
export default SignUpPage;
