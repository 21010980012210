import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../../Footer/Footer";
import NotFoundPage from "../../../pages/NotFoundPage/NotFoundPage";
import { environment } from "../../../envirement";
import "./AdminStagedListing.css";
import { CiTrash } from "react-icons/ci";
import { IoCheckmarkDone } from "react-icons/io5";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { FallingLines } from "react-loader-spinner";

const AdminStagedListing = () => {
  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };
  const [recentlyStagedItems, setRecentlyStagedItems] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newItemImages, setNewItemImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement

  let credString = localStorage.getItem("cred");
  let cred = JSON.parse(credString);
  const userId = cred.user.id;
  const language = localStorage.getItem("lng");
  const config = {
    headers: {
      auth: cred.token,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsAdmin(cred && cred.user && cred.user.Role === "Superadmin");

        const response = await axios.get(
          `${environment.apiUrl}/recentlyStaged/getAllRecentlyStaged`
        );
        setRecentlyStagedItems(response.data);
      } catch (error) {
        console.error("Error fetching recently staged items:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${environment.apiUrl}/recentlyStaged/deleteRecentlyStaged/${id}`,
        config
      );
      setRecentlyStagedItems(
        recentlyStagedItems.filter((item) => item.id !== id)
      );
    } catch (error) {
      console.error("Error deleting recently staged item:", error);
    }
  };

  const handleUpdate = async (id, newData) => {
    try {
      await axios.put(
        `${environment.apiUrl}/recentlyStaged/updateRecentlyStaged/${id}`,
        newData
      );
      const updatedItems = recentlyStagedItems.map((item) => {
        if (item.id === id) {
          return { ...item, ...newData };
        }
        return item;
      });
      setRecentlyStagedItems(updatedItems);
    } catch (error) {
      console.error("Error updating recently staged item:", error);
    }
  };

  const handleAddNewItem = async () => {
    try {
      setIsLoading(true); // Activez le chargement

      if (newItemImages.length === 0) {
        setErrorMessage("Please select at least one image.");
        setIsLoading(false); // Désactivez le chargement en cas d'erreur
        return;
      }
      if (newItemImages.length > 6) {
        setErrorMessage("Cannot add more than 6 images.");
        setIsLoading(false); // Désactivez le chargement en cas d'erreur
        return;
      }

      const formData = new FormData();
      newItemImages.forEach((imageFile) => {
        formData.append("Images", imageFile);
      });

      const response = await axios.post(
        `${environment.apiUrl}/recentlyStaged/AddRecentlyStaged/${userId}/${language}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...config.headers,
          },
        }
      );
      enqueueSnackbar(response.data);
      setRecentlyStagedItems([
        ...recentlyStagedItems,
        response.data.recentlyStaged,
      ]);
      setNewItemImages([]);
      setErrorMessage("");
    } catch (error) {
      console.error("Error adding recently staged item:", error);
    } finally {
      setIsLoading(false); // Désactivez le chargement une fois la requête terminée
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 6) {
      setErrorMessage("Cannot add more than 6 images.");
      return;
    }

    const images = files.map((file) => file);
    setNewItemImages(images);
    setErrorMessage("");
  };
  const itemsPerPage = 2; // Définissez le nombre d'éléments par page

  // Pagination logic
  const sortedTestimonials = [...recentlyStagedItems].sort((a, b) => b.id - a.id);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = sortedTestimonials.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {isLoading && <div className="loader-wrapper">
          <FallingLines
            color="#041C32"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
          />
        </div>} 
      {isAdmin ? (
        <div className="admin-staged-listing-container">
          <SnackbarProvider
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          />
          <div className="add-item-section">
            <h2>Add Recently Added Item</h2>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            <button className="green-btn" onClick={handleAddNewItem}>
              <IoCheckmarkDone />
            </button>
            {errorMessage && (
              <p className="error-message">{errorMessage}</p>
            )}
            <div className="preview-images">
              {newItemImages.map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt={`${index}`}
                  className="preview-image"
                />
              ))}
            </div>
          </div>
          <h2>Recently Staged Items</h2>
          <table className="recent-items-table">
            <thead>
              <tr>
                <th>Images</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 &&
                currentItems.map((item) => (
                  <tr key={item?.id}>
                    <td>
                      <ul className="image-list">
                        {item?.Images.map((image, index) => (
                          <li key={index}>
                            <img
                              src={imgPath(image)}
                              alt={`${index}`}
                              className="table-image"
                            />
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <button
                        className="red-btn"
                        onClick={() => handleDelete(item.id)}
                      >
                        <CiTrash />
                      </button>
                      {/* Modal or form for updating */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(recentlyStagedItems.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className="page-btn"
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
      <Footer />
    </>
  );
};

export default AdminStagedListing;

