// src/TermsAndConditions.js

import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 font-sans">
      {/* Header */}
      <div className='flex items-center flex-col justify-center pt-12 pb-12'>
        <h1 style={{ fontSize: "30px", letterSpacing: "0.08em", marginBottom: "25px", marginTop: "10px" }}>
          {t('terms.generalWorkingRelationship.title')}
        </h1>
        <div className="border max-w-[940px] w-full m-0 !border-[#06466366]"></div>
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg p-6">
          {/* General Working Relationship Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.generalWorkingRelationship.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.generalWorkingRelationship.content')}
            </p>
          </section>

          {/* Service Overview Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.serviceOverview.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.serviceOverview.content')}
            </p>
          </section>

          {/* Content Modification Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.contentModification.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.contentModification.content')}
            </p>
          </section>

          {/* Refund Policy Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.refundPolicy.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.refundPolicy.content')}
            </p>
          </section>

          {/* Client's Responsibility for Materials Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.clientsResponsibilityForMaterials.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.clientsResponsibilityForMaterials.content')}
            </p>
          </section>

          {/* Copyright and Ownership Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.copyrightAndOwnership.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.copyrightAndOwnership.content')}
            </p>
          </section>

          {/* Terms Update Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.termsUpdate.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.termsUpdate.content')}
            </p>
          </section>

          {/* Agreement to Terms Section */}
          <section className="mt-6">
            <h2 className="text-2xl font-semibold mb-4">
              {t('terms.agreementToTerms.title')}
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              {t('terms.agreementToTerms.content')}
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default TermsAndConditions;
