import axios from "axios";
import {
  ADD_NEW_ORDER,
  ADD_NEW_ORDER_SUCCESS,
  ADD_NEW_ORDER_FAIL,
  GET_ORDER_FAIL,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ALL_ORDER_REQUEST,
  GET_ALL_ORDER_SUCCESS,
  GET_ALL_ORDER_FAIL,
  MODIF_ORDER_STATUS_REQUEST,
  MODIF_ORDER_STATUS_SUCCESS,
  MODIF_ORDER_STATUS_FAIL,
  GET_ONE_ORDER_REQUEST,
  GET_ONE_ORDER_SUCCESS,
  GET_ONE_ORDER_FAIL,
  GET_ONE_ORDER_FORADMIN_REQUEST,
  GET_ONE_ORDER_FORADMIN_SUCCESS,
  GET_ONE_ORDER_FORADMIN_FAIL,
  GET_ORDERS_FILTRED_REQUEST,
  GET_ORDERS_FILTERS_SUCCESS,
  GET_ORDERS_FILTRED_FAIL,
  GET_ORDERS_FILTRED_FOR_ADMIN_REQUEST,
  GET_ORDERS_FILTERS_FOR_ADMIN_SUCCESS,
  GET_ORDERS_FILTRED_FOR_ADMIN_FAIL,
  VALIDATE_ORDER_REQUEST,
  VALIDATE_ORDER_SUCCESS,
  VALIDATE_ORDER_FAIL,
} from "../constants/OrdersConstants";
import { environment } from "../../envirement";

const API_URL = `${environment.apiUrl}/ordersAPI/`;

export const addNewOrder = (newOrder, orderInfos, uploadedImgs, redirectionURL) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_NEW_ORDER, payload: newOrder });
console.log(newOrder)
    // const formData = new FormData();
    // formData.append("Ref", newOrder.Ref);
    // formData.append("Status", newOrder.Status);
    // formData.append("Price", newOrder.Price);
    // formData.append("Address", newOrder.Address);
    // formData.append("PaymentStatus", newOrder.PaymentStatus);


    const { loginDetails: { user } } = getState();
    const config = { headers: { auth: user.token } };

    const { data } = await axios.post(`${API_URL}createOrder`, newOrder, config);
    const orderId = data.order.id;
        // Upload images one by one
        for (const img of newOrder.Images) {
          const imageFormData = new FormData();
          imageFormData.append('Image', img.file);
          imageFormData.append('Comment', img.comment);
          imageFormData.append('Item_Removal', img.itemRemoval);
          imageFormData.append('Type', img.type);
          imageFormData.append('orderId', orderId);
    
          await axios.post(`${API_URL}uploadImages`, imageFormData, config);
        }
    
        // Upload Inspi images one by one
        if (newOrder.Inspi) {
          for (const inspi of newOrder.Inspi) {
            const inspiFormData = new FormData();
            inspiFormData.append('Inspi', inspi);
            inspiFormData.append('orderId', orderId);
    
            await axios.post(`${API_URL}uploadInspirations`, inspiFormData, config);
          }
        }
    // const language = localStorage.getItem('i18nextLng');
    // const userId = user.user.id;
    localStorage.setItem('orderId',orderId)


    dispatch({ type: ADD_NEW_ORDER_SUCCESS, payload: data });
    // window.location.href = '/profile';
    return data;
  } catch (error) {
    console.error("Error adding new order:", error);
    dispatch({ type: ADD_NEW_ORDER_FAIL,payload: error.message });
  }
};

export const getOrders = () => async (dispatch, getStatus) => {
  try {
    dispatch({ type: GET_ORDER_REQUEST });
    //
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // ${environment.apiUrl}/ordersAPI//ordersAPI/getManyOrders with filters : ${environment.apiUrl}/ordersAPI//ordersAPI/getManyOrders?Status=Done ...
    const { data } = await axios.get(API_URL + "getManyOrders", config);
    dispatch({ type: GET_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ORDER_FAIL });
  }
};

export const getOrdersForAdmin = () => async (dispatch, getStatus) => {
  try {
    dispatch({ type: GET_ALL_ORDER_REQUEST });
    //
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
   

    //${environment.apiUrl}/ordersAPI//ordersAPI/orders
    const { data } = await axios.get(API_URL + "orders", config);
    dispatch({ type: GET_ALL_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_ORDER_FAIL });
  }
};

export const modifOrderStatus = (id, status) => async (dispatch, getStatus) => {
  try {
    dispatch({ type: MODIF_ORDER_STATUS_REQUEST });
    //
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    //     console.log(config);
    // console.log(`sttus ${status}`)
    //${environment.apiUrl}/ordersAPI//ordersAPI/orderStatus/3
    const { data } = await axios.put(
      API_URL + `orderStatus/${id}`,
      { status: status },
      config
    );
    dispatch({ type: MODIF_ORDER_STATUS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MODIF_ORDER_STATUS_FAIL });
  }
};

//get one oreder details for user

export const getOneOrder = (id) => async (dispatch, getStatus) => {
  try {
    dispatch({ type: GET_ONE_ORDER_REQUEST });
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // console.log(config);
    const { data } = await axios.get(API_URL + `getOneOrder/${id}`, config);
    data?.order?.Response?.changes &&
      data?.order?.Response?.changes.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );

    dispatch({ type: GET_ONE_ORDER_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error getting one order:", error);

    dispatch({ type: GET_ONE_ORDER_FAIL });
  }
};

export const getOneOrderForAdmin = (id) => async (dispatch, getStatus) => {
  try {
    dispatch({ type: GET_ONE_ORDER_FORADMIN_REQUEST });
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // console.log(config);
    const { data } = await axios.get(API_URL + `getOrder/${id}`, config);
    data?.order?.Response?.changes &&
      data?.order?.Response?.changes.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    dispatch({ type: GET_ONE_ORDER_FORADMIN_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error getting one order:", error);

    dispatch({ type: GET_ONE_ORDER_FORADMIN_FAIL });
  }
};

//get Orders with filters for user :
//hedhi
export const getOrdersFiltred = (status,address) => async (dispatch, getStatus) => {
  try {
    dispatch({ type: GET_ORDERS_FILTRED_REQUEST });
    //
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };
    // console.log(config);

    //
    // ${environment.apiUrl}/ordersAPI//ordersAPI/getManyOrders with filters : ${environment.apiUrl}/ordersAPI//ordersAPI/getManyOrders?Status=Done ...
     // Construire l'URL en fonction des paramètres fournis
     let url = API_URL + "getManyOrders?";

     if (status) {
       url += `Status=${status}&`;
     }
 
     if (address) {
       url += `Address=${address}&`;
     }
  url = url.slice(0, -1);
 
     const { data } = await axios.get(url, config);
    dispatch({ type: GET_ORDERS_FILTERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ORDERS_FILTRED_FAIL });
  }
};

export const getOrdersFiltredForAdmin = (status, userFirstName, validated) => async (dispatch, getStatus) => {
  try {
    dispatch({ type: GET_ORDERS_FILTRED_FOR_ADMIN_REQUEST });

    const {
      loginDetails: { user },
    } = getStatus();

    const config = { headers: { auth: user.token } };

    // Construire l'URL en fonction des paramètres fournis
    let url = API_URL + "orders?";

    if (status) {
      url += `Status=${status}&`;
    }

    if (userFirstName) {
      url += `User_First_Name=${userFirstName}&`;
    }

    // Ajouter le paramètre validated à l'URL si nécessaire
    if (validated !== undefined) {
      url += `validated=${validated}&`;
    }

    // Supprimer le dernier '&' de l'URL, s'il existe
    url = url.slice(0, -1);

    const { data } = await axios.get(url, config);
    dispatch({ type: GET_ORDERS_FILTERS_FOR_ADMIN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ORDERS_FILTRED_FOR_ADMIN_FAIL });
  }
};


export const validateOrder = (id) => async (dispatch, getStatus) => {
  try {
    dispatch({ type: VALIDATE_ORDER_REQUEST });
    const {
      loginDetails: { user },
    } = getStatus();
    const config = { headers: { auth: user.token } };

    const { data } = await axios.put(API_URL + `validate/${id}`, null, config);
    dispatch({ type: VALIDATE_ORDER_SUCCESS, payload: data });
  } catch (error) {
    console.error("Error validating order:", error);

    dispatch({ type: VALIDATE_ORDER_FAIL });
  }
};
