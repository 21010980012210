import React, { useState } from 'react'
// import './ItemRemoval.css'
import { useTranslation } from "react-i18next";
import dtd from "../../../data/DayToDust.json";



const DayToDust = () => {
    const { t } = useTranslation();

    const before = (imgName) => {
      return require(`../../../assets/img/DayToDust/${imgName}`);
    };
    const after = (imgName) => {
      return require(`../../../assets/img/DayToDust/${imgName}`);
    };

        const [dtdData, setDtdData] = useState(dtd);

 const [language, setLanguage] = useState(localStorage.getItem("lng"));

 const storedLanguage = localStorage.getItem("lng");
 if (storedLanguage !== language) {
   setLanguage(storedLanguage);
 }
  return (
    <>
      <section>
        <div className="vs-container">
        <h1 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-14 mt-24" >Day To Dusk</h1>
        <div className="border max-w-[940px] w-full m-0 !border-[#06466366] "></div>
          <p>{t("gallery.DT.text")}</p>
          <div className="vsImagesAll">
            {dtdData.map((item, index) => (
              <div className="vsImages" key={index}>
                <img-comparison-slider
                  style={{
                    cursor: "col-resize",
                    maxWidth: "612px",
                    // maxHeight: "408px",
                    // width: "80%",
                    // maxHeight:"333px"
                  }}
                >
                  <img
                    slot="first"
                    src={before(item.imageBefore)}
                    style={{ objectFit:"cover",width: "100%", height: "100%" }}
                    alt={`${item.text}`}
                  />
                  <img
                    slot="second"
                    src={after(item.imageAfter)}
                    style={{objectFit:"cover", width: "100%", height: "100%" }}
                    alt={`${item.text}`}
                  />
                </img-comparison-slider>
                <div className="titleRef">
                  {/* {language === "en" && <h2>{item.text}</h2>}
                  {language === "fr" && <h2>{item.textFr}</h2>} */}
                  {/* <p>{item.Ref} </p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DayToDust