import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser } from "../../../redux/actions/UsersActions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./SignUpComponent.css";
import { useFormik } from "formik";
import validationSchema from "./signup-schema";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CircleAlert, CircleCheckBig, LoaderCircle } from "lucide-react";
import { FaSpinner } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";
import { environment } from "../../../envirement";

const SignUpComponent = () => {
  const captchaRef = useRef(null);
  const [preSignupData, setPreSignupData] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("signUpData"));
    setPreSignupData(data);
  }, []);
  
  const [phone, setPhone] = useState('');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cred, setCred] = useState({});
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector((state) => state.addNewUser);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [recaptchaChecked, setRecaptchaChecked] = useState(false); // State to track reCAPTCHA status
  const [recaptchaError, setRecaptchaError] = useState(false); // State to track reCAPTCHA error
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      First_Name: preSignupData?.firstName || "",
      Last_Name: preSignupData?.lastName || "",
      phone_number: "",
      Email: preSignupData?.email || "",
      Password: "",
      Confirme_Password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaChecked) {
        setRecaptchaError(true);
        return;
      }
      setCred({ ...values, Role: "User" });
      dispatch(addNewUser({ ...values, Role: "User" }));
      captchaRef.current.reset();
    },
  });


  useEffect(() => {
    if (message ) {
      setPopupMessage(<span className="flex justify-center items-center flex-col gap-3 text-center" >
        <CircleCheckBig className="bg-[#dcfce7] rounded-full p-2 text-green-900 w-10 h-10 " style={{ marginRight: "8px", verticalAlign: "middle" }} />
        {message.message}
      </span>);
      setShowPopup(true);
    }
  }, [ message]);



  useEffect(() => {
    if (error) {
      setPopupMessage( <span className="flex justify-center items-center flex-col gap-3 text-center" >
        <CircleAlert  className="bg-red-100 rounded-full p-2 text-red-600 w-10 h-10 " style={{ marginRight: "8px", verticalAlign: "middle" }} />
        {error}
      </span>);
      setShowPopup(true);
    }
  }, [error]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleToggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
    if(message ){
      navigate("/login")
    }
  };
console.log(popupMessage)
  return (
    <div className="signup-container">
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-2xl shadow-2xl p-6 max-w-lg w-full relative">
            <p className="text-gray-900 text-lg font-semibold mb-4">{popupMessage}</p>
            <button
              onClick={handleClosePopup}
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-2xl bg-transparent"
              aria-label="Close"
            >
              &times;
            </button>
          </div>
        </div>
      )}
      <h1 style={{ fontSize: "30px", letterSpacing: "0.08em", marginBottom: "25px", marginTop: "10px" }}>
        {t(`logIn.Signup.title`)}
      </h1>
      <div className="border max-w-[940px] w-full m-0 !border-[#06466366]"></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputs-container">
          <div className="signup-input-groupe">
            <div className="signup-input">
              <label htmlFor="firstname">{t(`logIn.Signup.label1`)}</label>
              <input
                className="su-input"
                type="text"
                id="firstname"
                placeholder={t(`logIn.Signup.label1placeholder`)}
                {...formik.getFieldProps("First_Name")}
              />
              {formik.touched.First_Name && formik.errors.First_Name && (
                <div className="error-message">{formik.errors.First_Name}</div>
              )}
            </div>
            <div className="signup-input">
              <label htmlFor="lastname">{t(`logIn.Signup.label2`)}</label>
              <input
                className="su-input"
                type="text"
                id="lastname"
                placeholder={t(`logIn.Signup.label2placeholder`)}
                {...formik.getFieldProps("Last_Name")}
              />
              {formik.touched.Last_Name && formik.errors.Last_Name && (
                <div className="error-message">{formik.errors.Last_Name}</div>
              )}
            </div>
          </div>

          <div className="signup-input-groupe">
            <div className="signup-input">
              <label htmlFor="phone_number">
                {t('logIn.Signup.label5')} {t('champoptionel')}
              </label>
              <PhoneInput
                country={'us'}
                value={phone}
                onChange={(phone) => {
                  setPhone(phone);
                  formik.setFieldValue('phone_number', phone);
                }}
                inputProps={{
                  id: 'phone_number',
                  name: 'phone_number',
                }}
              />
              {formik.touched.phone_number && formik.errors.phone_number && (
                <div className="error-message">{formik.errors.phone_number}</div>
              )}
            </div>

            <div className="signup-input">
              <label htmlFor="Email">{t(`logIn.Signup.label6`)}</label>
              <input
                className="su-input"
                type="text"
                id="Email"
                placeholder="example@example.com"
                {...formik.getFieldProps("Email")}
              />
              {formik.touched.Email && formik.errors.Email && (
                <div className="error-message">{formik.errors.Email}</div>
              )}
            </div>
          </div>

          <div className="signup-input-groupe space-y-4 flex justify-center flex-wrap">
            <div className="signup-input w-fit">
              <label htmlFor="Password">{t(`logIn.Signup.label4`)}</label>
              <div className="relative w-fit">
                <input
                  type={showPassword ? "text" : "password"}
                  id="Password"
                  className="su-input w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...formik.getFieldProps("Password")}
                />
                <button
                  type="button"
                  onClick={handleTogglePasswordVisibility}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-gray-700"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </button>
              </div>
              {formik.touched.Password && formik.errors.Password && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.Password}</div>
              )}
            </div>

            <div className="signup-input w-fit">
              <label htmlFor="Confirme_Password">{t(`logIn.Signup.label8`)}</label>
              <div className="relative w-fit">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="Confirme_Password"
                  className="su-input w-full px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...formik.getFieldProps("Confirme_Password")}
                />
                <button
                  type="button"
                  onClick={handleToggleConfirmPasswordVisibility}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-gray-700"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </button>
              </div>
              {formik.touched.Confirme_Password && formik.errors.Confirme_Password && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.Confirme_Password}</div>
              )}
            </div>
          </div>
        </div>

        <ReCAPTCHA
          sitekey={environment.captchaKey}
          ref={captchaRef}
          onChange={() => setRecaptchaChecked(true)}
          className={recaptchaError ? "recaptcha-error" : ""}
        />

<button
  type="submit"
  className="submit-btn-login"
  disabled={loading} // Disable button when loading
>
  {loading ? (
    <TailSpin
      visible={true}
      height="30"
      width="30"
      color="#fff"
      ariaLabel="tail-spin-loading"
      radius="1"
      strokeWidth={5}
    />
  ) : (
    t(`logIn.Signup.btn`)
  )}
</button>

      </form>
      <p className="text-base pb-2 pt-2">
{t(`agreement`)}
  <a href="/terms_and_conditions" className="text-blue-600 hover:underline pl-1">
  {t(`termsAndConditions`)}
  </a> {t(`and`)} 
  <a href="/privacy_policy" className="text-blue-600 hover:underline pl-1">
  {t(`privacyPolicy`)}
    </a>.
</p>

    </div>
  );
};

export default SignUpComponent;
