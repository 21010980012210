import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JSZip from "jszip";
import FileSaver from "file-saver";
import "./OrderRenderComponent.css";
import DescpRetourComponent from "./DescpRetourComponent";
import { useDispatch, useSelector } from "react-redux";
import { getOneUserForAdmin } from "../../../../redux/actions/UsersActions";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsDownload } from "react-icons/bs";
import fileImg from "../../../../assets/img/fileimage_13.png";
import { environment } from "../../../../envirement";

const OrderRenderComponent = ({ order, isAdmin }) => {
  const [imageFullscreen, setImageFullscreen] = useState(null);

  const handleImageClick = (image) => {
    setImageFullscreen(image);
  };

  const handleCloseFullscreen = () => {
    setImageFullscreen(null);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAdmin) {
      dispatch(getOneUserForAdmin(order.order.User_Id));
    }
  }, []);

  const { userAdmin } = useSelector((state) => state.getOneUserForAdmin);

  const { t } = useTranslation();
  const listing = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };

  const downloadAllImages = () => {
    const zip = new JSZip();

    // Fetch images and add them to the zip file
    const imagePromises = order.order.Images.map((image) =>
      fetch(listing(image.Image)).then((response) => response.blob())
    );

    Promise.all(imagePromises)
      .then((blobs) => {
        blobs.forEach((blob, id) => {
          const img = order.order.Images[id].Image;
          zip.file(`${img}`, blob);
        });

        // Generate the zip file
        return zip.generateAsync({ type: "blob" });
      })
      .then((content) => {
        // Save the zip file using FileSaver.js
        FileSaver.saveAs(
          content,
          `${userAdmin.user.First_Name}_${userAdmin.user.Last_Name}.zip`
        );
      })
      .catch((error) => {
        console.error("Error during ZIP file generation:", error);
      });
  };

  const downloadAllImagesForUsers = (deliveryId, images) => {
    const zip = new JSZip();

    // Fetch images and add them to the zip file
    const imagePromises = images.map((image) =>
      fetch(listing(image)).then((response) => response.blob())
    );

    Promise.all(imagePromises)
      .then((blobs) => {
        blobs.forEach((blob, id) => {
          const img = images[id];
          zip.file(`${img}`, blob);
        });

        // Generate the zip file
        return zip.generateAsync({ type: "blob" });
      })
      .then((content) => {
        // Save the zip file using FileSaver.js
        FileSaver.saveAs(content, `Delivery${deliveryId}.zip`);
      })
      .catch((error) => {
        console.error("Error during ZIP file generation:", error);
      });
  };

  const imagesArray =
    order &&
    order.order &&
    order.order.Response &&
    order.order.Response.changes &&
    order.order.Response.changes.length > 0 &&
    order.order.Response.changes.map((c) => {
      return c.ChangesResponse && c.ChangesResponse.Images;
    });

  const getFileType = (filePath) => {
    const extension = filePath.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif","jfif","bmp","tiff","svg","raw","webp","heic","heif","psd","eps","ico"];
    const videoExtensions = ["mp4","avi","mkv","mov","wmv","flv","webm","m4v","mpeg","3gp","ogg","ogv","qt","rm","swf","ts","vob","wmv"];
    const documentExtensions = ["doc", "docx", "pdf", "pde", "txt", "xls", "xlsx", "ppt", "pptx", "odt", "ods", "odp", "rtf", "csv", "html", "xml", "json", "yaml", "md", "tex", "log", "ini", "cfg", "msg", "odf", "pages", "numbers", "key", "wps", "sxw", "sxc", "sxi", "wpd", "tex", "odg"]
    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else if (documentExtensions.includes(extension)) {
      return "document";
    } else {
      return "unknown";
    }
  };

  return (
    <>
      {isAdmin && order && order.order.Images.length !== 0 ? (
        <div className="admin-order-imgs">
          <div className="retour-message">
            <div className="images-container" style={{ overflowY: "auto" }}>
              {order.order.Images.map((image, id) => (
                <div className="image-card" key={id}>
                  <img
                    key={id}
                    src={listing(image.Image)}
                    alt={""}
                    style={{}}
                  />
                  
                  <div
                    style={{
                      padding: "0 10px",
                      display: "grid",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <h4>
                      Type : <span>{image.Type}</span>{" "}
                    </h4>
                    <h4>
                      Item Removal :{" "}
                      <span>
                        {image.Item_Removal ? (
                          <span>oui</span>
                        ) : (
                          <span>non</span>
                        )}
                      </span>{" "}
                    </h4>
                    <h4>
                      Comment : <span>{image.Comment}</span>{" "}
                    </h4>
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
          <button className="btn-download" onClick={downloadAllImages}>
            <span style={{ marginRight: "5px" }}>Download all</span>
            <BsDownload />
          </button>
          {order?.order?.Inspirations?.length !== 0 && (
            <>
              <p>Inspirations :</p>
              <div
              className="retour-message"
                // style={{
                //   margin: "35px",
                //   display: "flex",
                //   flexDirection: "column",
                //   alignItems: "center",
                // }}
              >
                <div
                className="grid-container"
                  style={{
                    // width: "1041px",
                    // left: "3428px",
                    // backgroundColor: "#F8F7F780",
                    // border: "1px solid #00000033",
                    // overflowX: "auto",
                    // whiteSpace: "nowrap",
                    // padding: "10px",
                  }}
                >
                  {order.order.Inspirations &&
                    order.order.Inspirations.map((image, id) => (
                      // <div
                      //   key={id}
                      //   style={{
                      //     display: "inline-block",
                      //     margin: "0 10px",
                      //     width: "417px",
                      //     height: "278px",
                      //     border:"solid"
                          
                      //   }}
                      //   className="grid-item"
                      // >
                        <img
                          src={listing(image.Image)}
                          alt={""}
                          onClick={() => handleImageClick(image.Image)}
                          // style={{
                          //   width: "100%",
                          //   height: "100%",
                          //   objectFit: "cover",
                          // }}
                          className="grid-item"
                        />
                      // </div>
                    ))}
                </div>

                {imageFullscreen && (
                  <div className="fullscreen-overlay">
                    <span className="close-btn" onClick={handleCloseFullscreen}>
                      <IoIosCloseCircleOutline />
                    </span>
                    <img
                      src={listing(imageFullscreen)}
                      alt="order"
                      style={{
                        // width: "100%",
                        // height: "100%",
                        maxWidth: "90%",
                        maxHeight: "90vh",
                        objectFit: "contain",
                        borderRadius: "8px",
                    
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <DescpRetourComponent order={order} />

          {order?.order?.Validated && (
            <div className="validated-order-message">
              <p>{t("order-validated-to-admin")}</p>
            </div>
          )}
        </div>
      ) : !isAdmin &&
        order &&
        order.order.Response &&
        order.order.Response.Images.length !== 0 ? (
        <>
          {" "}
          <div className="delivery-container">
            <p
              style={{
                width: "186px",
                height: "19px",
                top: "310px",
                left: "5244px",
                color: "#041C32",
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "19px",
                letterSpacing: "0.08em",
                textAlign: "center",
              }}
            >
              Delivery 1
            </p>
          </div>
          <div
            // style={{
            //   margin: "35px",
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "center",
            // }}
            className="retour-message"
          >
            <div
              // style={{
              //   width: "1041px",
              //   left: "3428px",
              //   backgroundColor: "#F8F7F780",
              //   border: "1px solid #00000033",
              //   overflowX: "auto",
              //   whiteSpace: "nowrap",
              //   padding: "10px",
              // }}
              className="grid-container"
            >
              {order.order.Response.Images.map((image, id) => (
                <div
                // className="grid-container"
                  key={id}
                  // style={{
                  //   display: "inline-block",
                  //   margin: "0 10px",
                  //   width: "417px",
                  //   height: "278px",
                  // }}
                >
                  
                   {getFileType(image) === "image" && (
                                <img
                                
                                  src={listing(image)}
                                  alt={""}
                                  onClick={() => handleImageClick(image)}
                                  style={{
                                    width: "100%",
                                    height: "233px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              {getFileType(image) === "video" && (
                                <video
                                  controls
                                  style={{
                                    width: "100%",
                                    height: "233px",
                                    objectFit: "cover",
                                  }}
                                >
                                  <source
                                    src={listing(image)}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                              {getFileType(image) === "document" && (
                                <div className="document-container" >
                                <a href={listing(image)} download>
                                  <img
                                    src={fileImg}
                                    alt="Document"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                  />
                                   
                                </a>
                                <p className="filename" >{image.split('\\')[1]} </p>
                                </div>
                              )}
                              {getFileType(image) === "unknown" && (
                                <p>Type de fichier inconnu</p>
                              )}
                </div>
              ))}
             
            </div>

            {imageFullscreen && (
              <div className="fullscreen-overlay">
                <span className="close-btn" onClick={handleCloseFullscreen}>
                  <IoIosCloseCircleOutline />
                </span>
                <img
                      src={listing(imageFullscreen)}
                      alt="order"
                      style={{
                        // width: "100%",
                        // height: "100%",
                        maxWidth: "90%",
                        maxHeight: "90vh",
                        objectFit: "contain",
                        borderRadius: "8px",
                    
                      }}
                    />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              className="btn-download"
              onClick={() =>
                downloadAllImagesForUsers(
                  1, // Delivery ID
                  order.order.Response.Images // Images array for the specific delivery
                )
              }
            >
              <span style={{ marginRight: "5px" }}>Download all</span>
              <BsDownload />
            </button>
          </div>
          {/* chepa */}
          {imagesArray &&
            imagesArray.map((images, index) => (
              <>
                {order &&
                  order.order &&
                  order.order.Response &&
                  order.order.Response.changes &&
                  order.order.Response.changes.length > 0 && (
                    <div className="description-container">
                      <p
                        style={{
                          fontFamily: "Nunito",
                          fontSize: "20px",
                          fontWeight: 600,
                          lineHeight: "23px",
                          letterSpacing: "0.065em",
                          textAlign: "center", // Center the text
                        }}
                      >
                        <strong>{index + 1}</strong> -{" "}
                        {order.order.Response.changes[index].Description}
                      </p>
                    </div>
                  )}

                {order &&
                order.order &&
                order.order.Response &&
                order.order.Response.changes &&
                order.order.Response.changes.length > 0 &&
                order.order.Response.changes[index].ChangesResponse !== null ? (
                  <div className="delivery-container">
                    <p
                      style={{
                        margin: "35px 0",
                        color: "#041C32",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "19px",
                        letterSpacing: "0.08em",
                        textAlign: "center", // Center the text
                      }}
                    >
                      Delivery {index + 2}
                    </p>

                    <div className="retour-message" style={{ width: "100%" }}>
                      <div
                      className="grid-container"
                        style={{
                          // width: "1041px",
                          // overflowX: "auto",
                          // whiteSpace: "nowrap",
                        }}
                      >
                        {images &&
                          images.map((image, id) => (
                            <div
                              key={id}
                              style={{
                                // display: "inline-block",
                                // margin: "0 10px",
                                // width: "417px",
                                // height: "278px",
                              }}
                            >
                              {getFileType(image) === "image" && (
                                <img
                                  src={listing(image)}
                                  alt={""}
                                  onClick={() => handleImageClick(image)}
                                  style={{
                                    width: "100%",
                                    height: "233px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              {getFileType(image) === "video" && (
                                <video
                                  controls
                                  style={{
                                    width: "100%",
                                    height: "233px",
                                    objectFit: "cover",
                                  }}
                                >
                                  <source
                                    src={listing(image)}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                              {getFileType(image) === "document" && (
                                <a href={listing(image)} download>
                                  <img
                                    src={fileImg}
                                    alt="Document"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </a>
                              )}
                              {getFileType(image) === "unknown" && (
                                <p>Type de fichier inconnu</p>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                    <button
                      className="btn-download"
                      onClick={() =>
                        downloadAllImagesForUsers(
                          index + 2, // Delivery ID
                          imagesArray[index] // Images array for the specific delivery
                        )
                      }
                    >
                      <span style={{ marginRight: "5px" }}>Download all</span>
                      <BsDownload />
                    </button>
                  </div>
                ) : (
                  <div className="profile-message">
                    <p>{t("account.order-message")}</p>
                  </div>
                )}
              </>
            ))}
          {/* chepa */}
        </>
      ) : (
        <div className="profile-message">
          <p>{t("account.order-message")}</p>
        </div>
      )}
    </>
  );
};

export default OrderRenderComponent;
