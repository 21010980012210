import {
  ADD_NEW_ORDER,
  GET_ORDER_FAIL,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ALL_ORDER_REQUEST,
  GET_ALL_ORDER_SUCCESS,
  GET_ALL_ORDER_FAIL,
  MODIF_ORDER_STATUS_REQUEST,
MODIF_ORDER_STATUS_SUCCESS,
MODIF_ORDER_STATUS_FAIL,
GET_ONE_ORDER_REQUEST,
GET_ONE_ORDER_SUCCESS,
GET_ONE_ORDER_FAIL,
GET_ONE_ORDER_FORADMIN_REQUEST, 
GET_ONE_ORDER_FORADMIN_SUCCESS  , 
GET_ONE_ORDER_FORADMIN_FAIL , 
GET_ORDERS_FILTRED_REQUEST,
GET_ORDERS_FILTERS_SUCCESS,
GET_ORDERS_FILTRED_FAIL,     
GET_ORDERS_FILTRED_FOR_ADMIN_REQUEST,
GET_ORDERS_FILTERS_FOR_ADMIN_SUCCESS,
GET_ORDERS_FILTRED_FOR_ADMIN_FAIL,
VALIDATE_ORDER_REQUEST,
VALIDATE_ORDER_SUCCESS,
VALIDATE_ORDER_FAIL,
ADD_NEW_ORDER_SUCCESS,
ADD_NEW_ORDER_FAIL,
} from "../constants/OrdersConstants";
import { ADD_NEW_USER_FAIL } from "../constants/UsersConstants";

const initialState = {
  orders: [],
  filtredOrders: [],
  filtredOrdersForAdmin: [],
  order :[]
};

// export const ordersReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case ADD_NEW_ORDER:
//       return {
//         ...state,
//         orders: [...state.orders, action.payload],
//       };
//     default:
//       return state;
//   }
// };
const getErrorMessage = (language) => {
  const messages = {
    en: "Failed to add new order. Please try again",
    fr: "Échec de l'ajout de la nouvelle commande. Essayer de nouveau",
    // Add other languages as needed
  };

  return messages[language] || messages.en;
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_ORDER:
      return {
        ...state,
        orders: [...state.orders, action.payload],
  
      };

    case ADD_NEW_ORDER_FAIL:
      const language = localStorage.getItem('language') || 'en';
      const errorMessage = getErrorMessage(language);
      return {
        ...state,
        loading: false,
        message: errorMessage,
      };

    default:
      return state;
  }
};
export const getOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_REQUEST:
      return { loading: true };

    case GET_ORDER_SUCCESS:
      return { loading: false, orders: action.payload };
    case GET_ORDER_FAIL:
      return { loading: false, message: action.payload };
    default:
      return state;
  }
};
export const getOrdersFiltredReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERS_FILTRED_REQUEST:
      return { loadingF: true };

    case GET_ORDERS_FILTERS_SUCCESS:
      return { loadingF: false, filtredOrders: action.payload };
    case GET_ORDERS_FILTRED_FAIL:
      return { loadingF: false, message: action.payload };
    default:
      return state;
  }
};
export const getOrdersFiltredForAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERS_FILTRED_FOR_ADMIN_REQUEST:
      return { loadingF: true };

    case GET_ORDERS_FILTERS_FOR_ADMIN_SUCCESS:
      return { loadingF: false, filtredOrdersForAdmin: action.payload };
    case GET_ORDERS_FILTRED_FOR_ADMIN_FAIL:
      return { loadingF: false, message: action.payload };
    default:
      return state;
  }
};

export const getOrderForAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_ORDER_REQUEST:
      return { loading: true };

    case GET_ALL_ORDER_SUCCESS:
      return { loading: false, ordersAdmin: action.payload };
    case GET_ALL_ORDER_FAIL:
      return { loading: false, message: action.payload };
    default:
      return state;
  }
};


export const modifOrderStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case MODIF_ORDER_STATUS_REQUEST:
      return { loading: true };

    case MODIF_ORDER_STATUS_SUCCESS:
      return { loading: false, order: action.payload };
    case MODIF_ORDER_STATUS_FAIL:
      return { loading: false, message: action.payload };
    default:
      return state;
  }
};


export const getOneOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ONE_ORDER_REQUEST:
      return { loading: true };

    case GET_ONE_ORDER_SUCCESS:
      return { loading: false, order: action.payload };
    case GET_ONE_ORDER_FAIL:
      return { loading: false, message: action.payload };
    default:
      return state;
  }
};
export const getOneOrderForAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ONE_ORDER_FORADMIN_REQUEST:
      return { loading: true };

    case GET_ONE_ORDER_FORADMIN_SUCCESS:
      return { loading: false, orderAdmin: action.payload };
    case GET_ONE_ORDER_FORADMIN_FAIL:
      return { loading: false, message: action.payload };
    default:
      return state;
  }
};
export const validateOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_ORDER_REQUEST:
      return { loading: true };

    case VALIDATE_ORDER_SUCCESS:
      return { loading: false, order: action.payload };
    case VALIDATE_ORDER_FAIL:
      return { loading: false, message: action.payload };
    default:
      return state;
  }
};