import React, { useEffect } from "react";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { environment } from "../../../envirement";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AccountVerification = () => {
  const { resetToken } = useParams();
  const Language = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const response = await axios.post(
          `${environment.apiUrl}/usersAPI/verificationMail/${resetToken}`,
          {
            language: Language,
          }
        );

        const { message } = response.data;
        // enqueueSnackbar(message, { variant: "success" });
        // navigate("/login");
      } catch (error) {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Something went wrong.";
        // enqueueSnackbar(errorMessage, { variant: "error" });
      }
    };

    verifyAccount();
  }, []);

  return (
    <>
      <div className="success-message">
        <svg
          viewBox="0 0 76 76"
          className="success-message__icon icon-checkmark"
        >
          <circle cx="38" cy="38" r="36" />
          <path
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M17.7,40.9l10.9,10.9l28.7-28.7"
          />
        </svg>
        <h1 className="success-message__title">
          Your account has been verified
        </h1>
        <div className="success-message__content">
          <p>Welcome ! </p>
          <Link
            to={"/login"}
            className="a nav-link-btn"
            style={{
              width: "fit-content",
              padding: "5px",
              justifyContent: "center",
              background: "#ecb365",
              boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.15)",
              borderRadius: "2px",
            }}
          >
            {t("header.topPart.Login")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default AccountVerification;
