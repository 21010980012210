import {GET_ONE_USER_FORADMIN_REQUEST,
    GET_ONE_USER_FORADMIN_SUCCESS,
    GET_ONE_USER_FORADMIN_FAIL, ADD_NEW_USER_FAIL, ADD_NEW_USER_REQUEST, ADD_NEW_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, RESET_PWD_FAIL, RESET_PWD_REQUEST, RESET_PWD_SUCCESS } from "../constants/UsersConstants";


export const addNewUserReducer=(state={},action)=>{
switch (action.type) {
    case ADD_NEW_USER_REQUEST:
        return {loading:true}
        
    case ADD_NEW_USER_SUCCESS:
        return {loading:false,message:action.payload}
    case ADD_NEW_USER_FAIL :
        return {loading:false,error:"user already exists"} 
    default:
        return state
}
}

export const loginReducer=(state={},action)=>{
    switch (action.type) {
        case LOGIN_REQUEST:
            return {loading:true}
            
        case LOGIN_SUCCESS:
            return {loading:false,user:action.payload}
        case LOGIN_FAIL:
            return{loading:false,message:action.payload}
        case LOGOUT:
            return{}
        default:
            return state;
    }
}

export const resetPWDReducer=(state={},action)=>{
    switch (action.type) {
        case RESET_PWD_REQUEST:
            return {loading:true}
            
        case RESET_PWD_SUCCESS:
            return {loading:false,message:action.payload}
        case RESET_PWD_FAIL :
            return {loading:false,error:"user already exists"} 
        default:
            return state
    }
    }


    //getOneUserForAdmin

    export const getOneUserForAdminReducer = (state = {}, action) => {
        switch (action.type) {
          case GET_ONE_USER_FORADMIN_REQUEST:
            return { loading: true };
      
          case GET_ONE_USER_FORADMIN_SUCCESS:
            return { loading: false, userAdmin: action.payload };
          case GET_ONE_USER_FORADMIN_FAIL:
            return { loading: false, message: action.payload };
          default:
            return state;
        }
      };