import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminTestimonial.css";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import Footer from "../../components/Footer/Footer";
import { environment } from "../../envirement";
import { CiTrash } from "react-icons/ci";
import { FaEdit } from "react-icons/fa";
import { IoCheckmarkDone } from "react-icons/io5";
import { RxUpdate } from "react-icons/rx";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const AdminTestimonial = () => {
  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };

  const [testimonials, setTestimonials] = useState([]);
  const [testimonial, setTestimonial] = useState({
    Name: "",
    Company: "",
    Text: "",
    ClientPhoto: null,
  });
  const [editingTestimonialId, setEditingTestimonialId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const testimonialsPerPage = 4;

  let credString = localStorage.getItem("cred");
  let cred = JSON.parse(credString);
  let isAdmin = cred && cred.user && cred.user.Role === "Superadmin";
  const config = {
    headers: {
      auth: cred.token,
    },
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        `${environment.apiUrl}/testimonialAdmin/GetTestimonialAdminList`
      );
      setTestimonials(response.data.testimonialList);
    } catch (error) {
      console.error("Error fetching testimonials", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTestimonial({ ...testimonial, [name]: value });
  };

  const handleFileChange = (event) => {
    setTestimonial({ ...testimonial, ClientPhoto: event.target.files[0] });
  };

  const handleEdit = (testimonial) => {
    setTestimonial(testimonial);
    setEditingTestimonialId(testimonial.id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("Name", testimonial.Name);
    formData.append("Company", testimonial.Company);
    formData.append("Text", testimonial.Text);
    formData.append("ClientPhoto", testimonial.ClientPhoto);

    const idUser = cred.user.id;
    const langue = localStorage.getItem("lng");

    try {
      if (editingTestimonialId) {
        await axios.put(
          `${environment.apiUrl}/testimonialAdmin/UpdateTestimonialAdmin/${editingTestimonialId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            ...config,
          }
        );
        setEditingTestimonialId(null);
      } else {
        await axios
          .post(
            `${environment.apiUrl}/testimonialAdmin/AddTestimonialAdmin/${idUser}/${langue}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              ...config,
            }
          )
          .then((response) => {
            enqueueSnackbar(response.data);
          })
          .catch((error) => {
            enqueueSnackbar(error);
          });
      }
      setTestimonial({
        Name: "",
        Company: "",
        Text: "",
        ClientPhoto: null,
      });
      fetchTestimonials();
    } catch (error) {
      console.error("Error adding/updating testimonial", error);
    }
  };

  const handleDelete = async (testimonialId) => {
    try {
      await axios.delete(
        `${environment.apiUrl}/testimonialAdmin/DeleteTestimonialAdmin/${testimonialId}`,
        config
      );
      fetchTestimonials();
    } catch (error) {
      console.error("Error deleting testimonial", error);
    }
  };

  // Pagination logic
  const sortedTestimonials = [...testimonials].sort((a, b) => b.id - a.id);
  const indexOfLastTestimonial = currentPage * testimonialsPerPage;
  const indexOfFirstTestimonial = indexOfLastTestimonial - testimonialsPerPage;
  const currentTestimonials = sortedTestimonials.slice(
    indexOfFirstTestimonial,
    indexOfLastTestimonial
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {isAdmin ? (
        <div className="admin-testimonial-container">
          <SnackbarProvider
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          />
          <form className="testimonial-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                name="Name"
                placeholder="Name"
                onChange={handleInputChange}
                value={testimonial.Name}
              />
              <input
                type="text"
                name="Company"
                placeholder="Company"
                onChange={handleInputChange}
                value={testimonial.Company}
              />
            </div>
            <div className="input-group">
              <textarea
                name="Text"
                placeholder="Text"
                onChange={handleInputChange}
                value={testimonial.Text}
              ></textarea>
              <input
                type="file"
                name="ClientPhoto"
                onChange={handleFileChange}
              />
            </div>
            <button type="submit" className="submit-btn btn-icon">
              {editingTestimonialId ? <RxUpdate /> : <IoCheckmarkDone />}
            </button>
          </form>
          <div className="testimonial-list">
            <h2>Testimonials</h2>
            <table>
              <thead>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Text</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentTestimonials.map((test) => (
                  <tr key={test.id}>
                    <td>
                      <img
                        src={imgPath(test.ClientPhoto)}
                        alt="client"
                        className="small-image"
                      />
                    </td>
                    <td>{test.Name}</td>
                    <td>{test.Company}</td>
                    <td>{test.Text}</td>
                    <td>
                      <button
                        onClick={() => handleEdit(test)}
                        className="edit-btn"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDelete(test.id)}
                        className="delete-btn"
                      >
                        <CiTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="pagination">
              {Array.from(
                {
                  length: Math.ceil(testimonials.length / testimonialsPerPage),
                },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className="page-btn"
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <NotFoundPage />
      )}
      <Footer />
    </>
  );
};

export default AdminTestimonial;
