import React, { useState } from "react";
import "./Content.css";
import Virtualstaging from "../Virtual-staging/Virtualstaging";
import Modeling from "../Modeling/Modeling";
import VirtualRenovation from "../Virtual-renovation/VirtualRenovation";
import ItemRemoval from "../Item-removal/ItemRemoval";
import VirtualTour from "../Virtual-tour/VirtualTour";
import { Link, useLocation } from "react-router-dom"; // Add useLocation hook
import { useEffect } from "react";
import DayToDust from "../DayToDust/DayToDust";
import Plan2D from "../2DPlan/Plan2D";
import PhotoModification from "../photoModification/PhotoModification";

const Content = ({ navigate }) => {
  // State to keep track of the active tab
  const [active, setActive] = useState(1);

  // Get the current location
  const location = useLocation();

  useEffect(() => {
    // Extract the active tab from the URL
    const pathname = location.pathname;
    const lastIndex = pathname.lastIndexOf('/');
    const index = parseInt(pathname.substring(lastIndex + 1));
    if (!isNaN(index)) {
      setActive(index);
    }
  }, [location.pathname]);

  // Function to handle tab clicks and update the URL
  const handleTabClick = (index) => {
    setActive(index);

    // Update the URL when a menu button is clicked
    navigate(`/gallery/${getTabName(index)}/${index}`);
  };

  // Helper function to get the tab name based on index
  const getTabName = (index) => {
    switch (index) {
      case 1:
        return "virtual-staging";
      case 2:
        return "3D-rendering";
      case 3:
        return "virtual-renovation";
      case 4:
        return "item-removal";
      case 5:
        return "virtual-tour";
      case 6:
        return "day-to-dusk";
      case 7:
        return "2D-Plan";
      case 8:
        return "Photo-modifcation";
      default:
        return "";
    }
  };

  // Function to render the component based on the active tab
  const renderComponent = () => {
    switch (active) {
      case 1:
        return <Virtualstaging />;
      case 2:
        return <Modeling />;
      case 3:
        return <VirtualRenovation />;
      case 4:
        return <ItemRemoval />;
      case 5:
        return <VirtualTour />;
      case 6:
        return <DayToDust/>
      case 7:
        return <Plan2D/>
      case 8:
        return <PhotoModification/>
      default:
        return null;
    }
  };

  return (
    <>
      <section className="galleryContentContainer">
      <div className="flex border-b border-gray-300 h-14 fixed bg-white z-10 top-[121px]  w-[100vw] overflow-auto lg:w-full lg:overflow-auto  ">
  <div
    className={` whitespace-nowrap p-4 pt-3 pb-0 h-14 cursor-pointer ${
      active === 1 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(1)}
  >
    <Link to="/gallery/virtual-staging/1" className="hover:text-[#ecb365]">Virtual staging</Link>
  </div>
  <div
    className={` whitespace-nowrap p-4 pt-3 pb-0 h-14 pt-3 cursor-pointer ${
      active === 2 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(2)}
  >
    <Link to="/gallery/3D-rendering/2" className="hover:text-[#ecb365]">3D rendering</Link>
  </div>
  <div
    className={`whitespace-nowrap p-4 pt-3 pb-0 h-14 cursor-pointer ${
      active === 3 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(3)}
  >
    <Link to="/gallery/virtual-renovation/3" className="hover:text-[#ecb365]">Virtual renovation</Link>
  </div>
  <div
    className={`whitespace-nowrap p-4 pt-3 pb-0 h-14 cursor-pointer ${
      active === 4 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(4)}
  >
    <Link to="/gallery/item-removal/4" className="hover:text-[#ecb365]">Item removal</Link>
  </div>
  <div
    className={`whitespace-nowrap p-4 pt-3 pb-0 h-14 cursor-pointer ${
      active === 5 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(5)}
  >
    <Link to="/gallery/virtual-tour/5" className="hover:text-[#ecb365]">Virtual tour</Link>
  </div>
  <div
    className={`whitespace-nowrap p-4 pt-3 pb-0 h-14 cursor-pointer ${
      active === 6 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(6)}
  >
    <Link to="/gallery/day-to-dusk/6" className="hover:text-[#ecb365]">Day to dusk</Link>
  </div>
  <div
    className={`whitespace-nowrap p-4 pt-3 pb-0 h-14 cursor-pointer ${
      active === 7 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(7)}
  >
    <Link to="/gallery/2D-Plan/7" className="hover:text-[#ecb365]">2D Plan</Link>
  </div>
  <div
    className={`whitespace-nowrap p-4 pt-3 cursor-pointer ${
      active === 8 ? "text-[#ecb365] border-b-2 border-[#ecb365]" : "text-gray-600"
    }`}
    onClick={() => handleTabClick(8)}
  >
    <Link to="/gallery/Photo-modifcation/8" className="hover:text-[#ecb365]">Photo modification</Link>
  </div>
</div>



        <div className="tab-content mt-2">{renderComponent()}</div>
      </section>
    </>
  );
};

export default Content;

