import React, { useEffect, useState } from "react";
import "./AdminFileUpload.css";
import upload from "../../assets/img/icons/drag-and-drop-admin.svg";
// import deleteIcon from "/delete.svg";
import deleteIcon from "../../assets/img/icons/Close.svg";

const AdminFileUpload = ({ getFiles }) => {
  const [files, setFiles] = useState(null);

  
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) =>
      prevFiles ? [...prevFiles, ...newFiles] : newFiles
    );
  };

  const handleRemoveFile = (index, e) => {
    // Check if the click event originated from the delete icon
    if (e.target.tagName.toLowerCase() === "img") {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles.length > 0 ? newFiles : null);
    }
    // Stop the propagation of the click event to prevent triggering handleButtonClick
    e.stopPropagation();
  };

  const handleButtonClick = () => {
    const dynamicInput = document.createElement("input");
    dynamicInput.type = "file";
    dynamicInput.multiple = true;
    dynamicInput.onchange = handleFileInputChange;
    dynamicInput.click();
  };

  const handleFileInputChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) =>
      prevFiles ? [...prevFiles, ...newFiles] : newFiles
    );
  };

  useEffect(() => {
    getFiles(files);
  }, [files]);

  return (
    <div>
      {files && (
        <>
        uploaded images : {files.length}
        <button
            className="upload-input-admin"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <ul className="file-list" id="file-list-container">
              {files.map((file, i) => (
                <li key={i}>
                  <div>
                    <img  style={{objectFit:"cover",width:"100px",height:"100px"}} className="file-icon" src={URL.createObjectURL(file)} alt="File Icon" />
                    <span className="file-list-title">{file.name}</span>
                    <div onClick={(e) => handleRemoveFile(i, e)}>
                      <img src={deleteIcon} alt="Delete Icon" width={"20px"} style={{position:"relative",bottom: "125px", left: "48px"}} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </button></>
)}


      {!files && (
        <>
          uploaded photos : 0
          <button
            className="upload-input-admin"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <img className="upload-img-admin" src={upload} alt="Upload Icon" />
          </button>
        </>
      )}
    </div>
  );
};

export default AdminFileUpload;