import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import LoginComponent from '../../../components/Authentification/Login/LoginComponent';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
    return (
        <div>
        <Helmet>
  {/* Page Title */}
  <title>Login - Meryas Home | Access Your Account</title>
  {/* Meta Description */}
  <meta name="description" content="Log in to your Meryas Home account to access personalized virtual staging, renovation services, and manage your real estate projects." />
  {/* Keywords */}
  <meta name="keywords" content="Meryas Home login, user login, account access, virtual staging login, property enhancement login" />
  {/* Open Graph Meta Tags for Social Sharing */}
  <meta property="og:title" content="Login - Meryas Home | Access Your Account" />
  <meta property="og:description" content="Access your Meryas Home account to manage virtual staging, renovation services, and real estate projects." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.meryashome.com/login" />
  <meta property="og:image" content="https://www.meryashome.com/images/login-banner.jpg" />
  <meta property="og:site_name" content="Meryas Home" />
  {/* Twitter Meta Tags for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Login - Meryas Home | Access Your Account" />
  <meta name="twitter:description" content="Log in to your Meryas Home account to access virtual staging services and project management tools." />
  <meta name="twitter:image" content="https://www.meryashome.com/images/login-banner.jpg" />
  <meta name="twitter:site" content="@MeryasHome" />
  {/* Canonical Link */}
  <link rel="canonical" href="https://www.meryashome.com/login" />
</Helmet>
            <LoginComponent/>
            <Footer/>
        </div>
    );
};

export default LoginPage;