import React, { useEffect, useState } from "react";
import "./FileUpload.css";
import upload from "../../assets/img/icons/upload1.svg";
import deleteIcon from "../../assets/img/icons/Close.svg";
import FileManager from "./FileManager";
// import Loader from "./Loader";  // Import a loader component

const FileUpload = ({ getFiles, title, onValidateFiles }) => {
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setLoading(true);
    setFiles((prevFiles) =>
      prevFiles ? [...prevFiles, ...newFiles] : newFiles
    );
    setLoading(false);
  };

  const handleRemoveFile = (index, e) => {
    if (e.target.tagName.toLowerCase() === "img") {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(newFiles.length > 0 ? newFiles : null);
    }
    e.stopPropagation();
  };

  const handleButtonClick = () => {
    const dynamicInput = document.createElement("input");
    dynamicInput.type = "file";
    dynamicInput.multiple = true;
    dynamicInput.onchange = handleFileInputChange;
    dynamicInput.click();
  };

  const handleFileInputChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setLoading(true);
    setFiles((prevFiles) =>
      prevFiles ? [...prevFiles, ...newFiles] : newFiles
    );
    setLoading(false);
  };

  useEffect(() => {
    getFiles(files);
  }, [files]);

  const handleDeleteSelectedFiles = (updatedFiles) => {
    setFiles(updatedFiles);
  };

  const handleValidateFiles = (validatedFiles) => {
    onValidateFiles(validatedFiles);
  };

  const isVirtualStaging = title === "virtual staging" || title === "mise en scène virtuelle";

  return (
    <div>
      {loading &&  <div className="loader">
      <div className="spinner" />
      <p>Loading...</p>
    </div>} {/* Show loader if loading is true */}
      
      {!loading && files && (
        <div>
          <p style={{ marginLeft: "350px" }}>
            uploaded photos: {files.length}
          </p>
          {files.length !== 0 ? (
            <FileManager files={files} updateFiles={handleDeleteSelectedFiles} onValidate={handleValidateFiles} isVirtualStaging={isVirtualStaging} />
          ) : (
            <>
              <button
                className="upload-input"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleButtonClick}
              >
                <img className="upload-img" src={upload} alt="Upload Icon" />
                <p className="text-gras">Browse Files</p>
                <p className="text-fin">Drag and drop files here</p>
              </button>
            </>
          )}
        </div>
      )}

      {!loading && !files && (
        <>
          uploaded photos: 0
          <button
            className="upload-input"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <img className="upload-img" src={upload} alt="Upload Icon" />
            <p className="text-gras">Browse Files</p>
            <p className="text-fin">Drag and drop files here</p>
          </button>
        </>
      )}
    </div>
  );
};

export default FileUpload;
