import React, { useState } from "react";
import "./ButtonsComponents.css";
import ChangesComponents from "./ChangesComponents";
import {
  validateOrder
} from "../../../../redux/actions/OrdersActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";



const ButtonsComponents = ({ response, order }) => {
  const [showChanges, setShowChanges] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

// console.log("order")
// console.log(order.id)
  const handleMakeChangesClick = () => {
    setShowChanges(true);
    setShowFeedback(false);
    setShowButtons(true);
  };

  const handleValidateClick = async () => {
    try {
     
   dispatch(validateOrder(order.id));

      setShowFeedback(true);
      setShowChanges(false);
      setShowButtons(false);
    } catch (error) {
      // Gérer les erreurs ici
      console.error("Erreur lors de l'appel à l'API :", error.message);
    }
  };
  

  
  return (
    <div>
      {showButtons && (
        <div className="buttons-container">
          <div className="btn-container">
            <button className="changes-btn" onClick={handleMakeChangesClick}>
              {/* Make changes */}
              {t("change-order-btn")}
            </button>
            <button className="validation-btn" onClick={handleValidateClick}>
              {/* Validate order */}
              {t("validate-order-btn")}

              
            </button>
          </div>
        </div>
      )}

      {showChanges && (
        <ChangesComponents changes={true} response={response} order={order} />
      )}
      {showFeedback && (
        <ChangesComponents
          feedbacks={true}
          setShowButtons={setShowButtons}
          response={response}
          order={order}
        />
      )}
    </div>
  );
};
export default ButtonsComponents;
