// import { useState, useEffect } from 'react';

// const WindowWidthComponent = () => {
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     // Clean up the event listener on component unmount
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return windowWidth;
// };

// export default WindowWidthComponent;


import { useState, useEffect } from 'react';

const WindowWidthComponent = () => {
  const [windowWidth, setWindowWidth] = useState(null);
console.log(windowWidth)
  useEffect(() => {
    // Run only on the client-side
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize(); // Set initial width on component mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export default WindowWidthComponent;
