import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../../../components/Footer/Footer'
import { environment } from '../../../envirement';
import { CiTrash } from "react-icons/ci";
import './AdminUsers.css'; 
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { FaEdit } from "react-icons/fa";
import { BiSort } from "react-icons/bi";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [formData, setFormData] = useState({
    First_Name: '',
    Last_Name: '',
    phone_number: '',
    Email: '',
    Password: '',
    Role: ''
  });
  const [selectedUserId, setSelectedUserId] = useState(null); // Nouvel état pour stocker l'ID de l'utilisateur sélectionné
  const [creditToUpdate, setCreditToUpdate] = useState([]); // Nouvel état pour stocker les modifications du crédit

  const updateCred=(creditInfo)=>{
    const updatedCreditToUpdate = [...creditToUpdate];
    const index = updatedCreditToUpdate.findIndex(item => item.userId === creditInfo.userId);
    if (index !== -1) {
      updatedCreditToUpdate[index] = creditInfo; // Update the existing creditInfo
    } else {
      updatedCreditToUpdate.push(creditInfo); // Add the new creditInfo
    }
    setCreditToUpdate(updatedCreditToUpdate);  }
  console.log(creditToUpdate)

  const credStr = localStorage.getItem("cred"); 
  const cred = JSON.parse(credStr);
  const config = {
    headers: {
      auth: cred.token,
    },
  };
  const updateUserCredit = async (creditToUpdate, userId) => {
    console.log(creditToUpdate);
    console.log(userId);
    
    // Find the credit info for the specific user
    const creditInfo = creditToUpdate.find(el => el.userId === userId);
    console.log(creditInfo);
  
    // Extract the credit value
    const credit = creditInfo.credit;
  
    try {
      await axios.put(
        `${environment.apiUrl}/usersAPI/userCreditAdmin/${userId}`,
        { Credit: credit }, // Pass the credit as an object
        { headers: { auth: cred.token } }
      );
  
      // Mettre à jour l'utilisateur dans l'état local avec le nouveau crédit
      const updatedUsers = users.map(user =>
        user.id === userId ? { ...user, Credit: credit } : user
      );
      setUsers(updatedUsers);
      enqueueSnackbar("User credit updated successfully");
    } catch (error) {
      console.error('Error updating user credit:', error);
    }
  };
  


  const handleUpdateCredit = (userId, credit) => {
    updateUserCredit(userId, credit);
    setCreditToUpdate({}); // Réinitialiser les modifications du crédit après la mise à jour
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${environment.apiUrl}/usersAPI/users`, config); 
      const formattedUsers = response.data.users.map(user => ({
        ...user,
        formattedCreatedAt: formatDate(user.createdAt)
      }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedUserId) { // Si un utilisateur est sélectionné pour la mise à jour
        await axios.put(`${environment.apiUrl}/usersAPI/user/${selectedUserId}`, formData, config); 
        enqueueSnackbar("User updated successfully");
      } else {
        const formDataWithConfirmPassword = { ...formData, Confirme_Password: formData.Password };
        await axios.post(`${environment.apiUrl}/usersAPI/signup`, formDataWithConfirmPassword); 
        enqueueSnackbar("User added successfully");
      }
      fetchUsers();
      setFormData({
        First_Name: '',
        Last_Name: '',
        phone_number: '',
        Email: '',
        Password: '',
        Role: ''
      });
      setSelectedUserId(null); // Réinitialiser l'ID de l'utilisateur sélectionné
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axios.delete(`${environment.apiUrl}/usersAPI/user/${userId}`, config); 
      fetchUsers(); 
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const updateUser = (userId) => {
    const userToUpdate = users.find(user => user.id === userId);
    setFormData({ // Remplir le formulaire avec les données de l'utilisateur sélectionné pour la mise à jour
      First_Name: userToUpdate.First_Name,
      Last_Name: userToUpdate.Last_Name,
      phone_number: userToUpdate.phone_number,
      Email: userToUpdate.Email,
      Password: userToUpdate.Password,
      Role: userToUpdate.Role
    });
    setSelectedUserId(userId); // Définir l'ID de l'utilisateur sélectionné pour la mise à jour
  };

  // Fonction de tri par date de création
  const sortByCreatedAt = () => {
    const sortedUsers = [...users].sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setUsers(sortedUsers);
  };

  // Fonction de tri par prénom
  const sortByFirstName = () => {
    const sortedUsers = [...users].sort((a, b) => {
      return a.First_Name.localeCompare(b.First_Name);
    });
    setUsers(sortedUsers);
  };

  // Appel de la fonction de tri appropriée en fonction du critère sélectionné
  useEffect(() => {
    if (sortBy === 'createdAt') {
      sortByCreatedAt();
    } else if (sortBy === 'firstName') {
      sortByFirstName();
    }
  }, [sortBy]);

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <form onSubmit={handleSubmit} className="user-form">
        <h2>{selectedUserId ? 'Update User' : 'Add User'}</h2> {/* Modifier le titre du formulaire en fonction de l'action */}
        <div className="form-group">
          <input type="text" name="First_Name" placeholder="First Name" value={formData.First_Name} onChange={handleInputChange} required />
          <input type="text" name="Last_Name" placeholder="Last Name" value={formData.Last_Name} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <input type="text" name="phone_number" placeholder="Phone Number" value={formData.phone_number} onChange={handleInputChange} />
          <input type="email" name="Email" placeholder="Email" value={formData.Email} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <input type="password" name="Password" placeholder="Password" value={formData.Password} onChange={handleInputChange} required />
          <select name="Role" value={formData.Role} onChange={handleInputChange} required>
            <option value="">Select Role</option>
            <option value="Superadmin">Superadmin</option>
            <option value="Admin">Admin</option>
            <option value="User">User</option>
          </select>
        </div>
        <button type="submit" className="add-user-btn">{selectedUserId ? 'Update User' : 'Add User'}</button> {/* Modifier le texte du bouton en fonction de l'action */}
      </form>

      <div className="users-table-container">
        <h2>Users</h2>
        <div style={{width: "46%" ,display:"flex", justifyContent: "space-between", padding:"10px 0"}} >
          <button style={{backgroundColor:"#35353529", color:"black", fontSize :"14px"}} onClick={() => setSortBy('createdAt')}><BiSort />
            Sort by Date</button>
          <button style={{backgroundColor:"#35353529", color:"black", fontSize :"14px"}} onClick={() => setSortBy('firstName')}><BiSort />
            Sort by First Name</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Created on</th>
              <th>Update credits</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user.id}>
                <td>{user.First_Name}</td>
                <td>{user.Last_Name}</td>
                <td>{user.Email}</td>
                <td>{user.Role}</td>
                <td>{user.formattedCreatedAt}</td>
                <td>
                  {creditToUpdate[user.id] !== undefined ? (
                    <input
                      type="number"
                      style={{ width: "35px" }}
                      value={creditToUpdate[user.id]}
                      onChange={(e) =>
                        setCreditToUpdate({
                          ...creditToUpdate,
                          [user.id]: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <>
                      <input
                        type="number"
                        style={{ width: "50px" }}
                        defaultValue={user.Credit}
                        onChange={(e) => updateCred({userId:user.id, credit:e.target.value})}
                      />
                      <FaEdit onClick={()=>updateUserCredit(creditToUpdate,user.id)} />
                    </>
                  )}
                </td>
                <td>
                  <button className="delete-btn" onClick={() => deleteUser(user.id)}><CiTrash /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer/>
    </>
  );
};

export default AdminUsers;
