import axios from "axios";
import {
  ADD_NEW_USER_FAIL,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_PWD_FAIL,
  RESET_PWD_REQUEST,
  RESET_PWD_SUCCESS,
  UPDATE_USER_CREDITS_REQUEST,
UPDATE_USER_CREDITS_SUCCESS,
UPDATE_USER_CREDITS_FAIL,
GET_ONE_USER_FORADMIN_REQUEST ,
GET_ONE_USER_FORADMIN_SUCCESS,
GET_ONE_USER_FORADMIN_FAIL,
} from "../constants/UsersConstants";
import { environment } from "../../envirement";
const API_URL = `${environment.apiUrl}/usersAPI/`;

export const addNewUser = (newUser) => async (dispatch) => {

  // console.log(newUser);
  try {
    dispatch({ type: ADD_NEW_USER_REQUEST });
    const { data } = await axios.post(API_URL + "signup",newUser);
    // console.log(data);
    dispatch({ type: ADD_NEW_USER_SUCCESS, payload: data });
  } catch (error) {
    // console.log("error fel action")
    // console.log(error)
    dispatch({ type: ADD_NEW_USER_FAIL });
  }
};




export const login = (cred) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const response = await axios.post(API_URL + "login", cred);
    const { data, status } = response;
    // console.log(data)
    localStorage.setItem("cred", JSON.stringify(data));
    dispatch({ type: LOGIN_SUCCESS, payload: data });
  } catch (error) {
    // console.log("-------------------response in catch-------------------");
    // console.log(error.response); // Assuming axios error response contains useful information

    dispatch({ type: LOGIN_FAIL, payload: error.response.data });
  }
};


export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  localStorage.removeItem("cred");
};

export const resetpwd = (idUser) => async (dispatch) => {

  // console.log(idUser);
  try {
    dispatch({ type: RESET_PWD_REQUEST });
    const { data } = await axios.put(API_URL + "resetPassword",idUser);
    // console.log(data);
    dispatch({ type: RESET_PWD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RESET_PWD_FAIL });
  }
};

export const updateCredits = (credit, userId) => async (dispatch) => {
  // console.log("Credit from action usser")
  const Credit=credit
  try {
    dispatch({ type: UPDATE_USER_CREDITS_REQUEST });
    const { data } = await axios.put(`${API_URL}userCredit/${userId}`, {Credit: Credit});

    // console.log(data);
    dispatch({ type: UPDATE_USER_CREDITS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_USER_CREDITS_FAIL });
  }
};


///user/:id

export const getOneUserForAdmin=(id)=>async(dispatch, getStatus)=>{

  try {
      dispatch({type:GET_ONE_USER_FORADMIN_REQUEST})
      const {
        loginDetails: { user },
      } = getStatus();
      const config = { headers: { auth: user.token } };
      // console.log(config);
      const {data}=await axios.get(API_URL + `user/${id}`,config)
      dispatch({type:GET_ONE_USER_FORADMIN_SUCCESS,payload:data})
      
  } catch (error) {
    console.error("Error getting one user:", error);

  dispatch({type:GET_ONE_USER_FORADMIN_FAIL})
  }
}