import React from 'react'
import './PlaceOrder.css'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export const PlaceOrder = () => {
      const { t } = useTranslation();
      const handlePlaceOrderClick = () => {
        localStorage.setItem("serviceURL", "placeOrder");
    
      };
    return (
      <div className="offerOrder">
        <p>{t("Place-order.text")}</p>
        <Link to={"/prices"}>
        {/* <Link to={"/placeOrder"}> */}
        <button onClick={handlePlaceOrderClick}>{t("Place-order.btn")}</button>
        </Link>
      </div>
    );
}
