import React from 'react'
import SelectCountry from '../components/SelectCountry'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

const ChangeCountryPage = ({setCurrency}) => {
  return (
    <div>
           <SelectCountry  setCurrency={setCurrency} isLandingPage ={false} />
<Footer/>
    </div>
  )
}

export default ChangeCountryPage