import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ResetPasswordComponent.css";
import axios from 'axios';
import { SnackbarProvider, useSnackbar } from "notistack";
import { environment } from "../../../envirement";
const ResetPasswordComponent = () => {
  const Language = localStorage.getItem('i18nextLng');


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  
  const [textInput, setTextInput] = useState('');
  

  const handleSendEmail = async () => {
    try {
      const response = await axios.post(`${environment.apiUrl}/usersAPI/forgotPassword`, { Email: textInput, language: Language,
    });
      enqueueSnackbar(response.data.message);
    } catch (error) {
      enqueueSnackbar(error.response.data.message);
    }
  };
  
  return (
    
    <div className="forgetten-pwd-container">
       <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <h3>{t("logIn.Forgot_password_title")}</h3>
      <input
        type="text"
        placeholder="does.miller@gmail.com"
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
      />
      <p>{t("logIn.forgotten_password_info")}</p>
      <button onClick={handleSendEmail}>{t("logIn.send-btn")}</button>
      </div>
  
      


    
  );
};

export default ResetPasswordComponent;

// import { SnackbarProvider, enqueueSnackbar } from 'notistack'

// const ResetPasswordComponent = () => {
//   return (
//     <SnackbarProvider>
//       <div className="forgetten-pwd-container">
        
//         <button style={{color:"black", backgroundColor:"red"}} onClick={() => enqueueSnackbar('That was easy!')}>Show snackbar</button>
//       </div>
//      </SnackbarProvider>
//   )
// }

// export default ResetPasswordComponent;