import  { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginPage from '../pages/Authentification/Login/LoginPage';
const ProtectedRoutes = ({ storedCred, children }) => {
  const isLoggedIn = storedCred && storedCred.user && storedCred.user.id !== null;
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is not logged in and navigate to the login page
    if (!isLoggedIn) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
 
  // Render children only if the user is logged in
  return isLoggedIn ? children : <LoginPage/>
};

export default ProtectedRoutes;
