import { ImageDown, Images, ImageUp } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

const steps = [
  {
    id: 1,
    titleKey: "steps.upload.title",
    descriptionKey: "steps.upload.description",
    icon: <ImageUp size={100} strokeWidth={1} />
  },
  {
    id: 2,
    titleKey: "steps.design.title",
    descriptionKey: "steps.design.description",
    icon: <Images size={100} strokeWidth={1} />
  },
  {
    id: 3,
    titleKey: "steps.done.title",
    descriptionKey: "steps.done.description",
    icon: <ImageDown size={100} strokeWidth={1} />
  }
];

const Steps = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50 flex flex-col justify-center items-center">
                <h1 className="title mt-10">{t("steps.title")}</h1>
                <div className="flex flex-col md:flex-row justify-between gap-6 p-8 bg-gray-50">

{steps.map((step) => (
  <div key={step.id} className="flex flex-col items-center text-center p-6 bg-white shadow-md rounded-lg w-full md:w-1/4">
    {step.icon}
    <h3 className="text-[#ecb365] font-semibold text-lg uppercase mb-2">
      {t("steps.step")} {step.id}
    </h3>
    <h2 className="text-lg font-bold text-gray-800 mb-2">
      {t(step.titleKey)}
    </h2>
    <p className="text-gray-600 text-sm">
      {t(step.descriptionKey)}
    </p>
  </div>
))}
</div>
    </div>
  
  );
};

export default Steps;
