import React, { useEffect, useState } from "react";
import deleteIcon from "../../assets/img/icons/Close.svg";
import { environment } from "../../envirement";
import axios from "axios";

//--------
// router.put('/UpdateAdminChangesResponse/:id',uploadProp.array("Images"),UpdateAdminChangesResponse,)

//--------

const UpdateAdminFileUpload = ({ getFiles, response,type }) => {
  let prevImages = response.Images;
  const [files, setFiles] = useState([]);
  const [imagesFinal, setImagesFinal] = useState(prevImages || []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    const dynamicInput = document.createElement("input");
    dynamicInput.type = "file";
    dynamicInput.multiple = true;
    dynamicInput.onchange = handleFileInputChange;
    dynamicInput.click();
  };

  const handleFileInputChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const deleteImageFromServer = async (imagePath) => {
    try {
     if(type==="Res"){
      await axios.delete(`${environment.apiUrl}/changesAPI/DeleteImageAdminResponse/${response.id}`, {
        data: { imagePath }
      });
      console.log("Image deleted successfully");
     }else{
      await axios.delete(`${environment.apiUrl}/changesAPI/DeleteImageAdminChangesResponse/${response.id}`, {
        data: { imagePath }
      });
      console.log("Image deleted successfully");
     }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleRemoveFile = async (index) => {
    const updatedImages = [...imagesFinal];
    const removedImage = updatedImages[index];
    const filteredImages = updatedImages.filter((image, i) => i !== index);
    setImagesFinal(filteredImages);

    if (typeof removedImage === "string") {
      await deleteImageFromServer(removedImage);
    } else {
      const removedFileIndex = index - prevImages.length;
      const updatedFiles = [...files];
      updatedFiles.splice(removedFileIndex, 1);
      setFiles(updatedFiles);
    }

    getFiles(filteredImages);
  };

  useEffect(() => {
    const remainingImages = imagesFinal.filter((image) => !files.includes(image));
    const newFiles = files.filter((file) => !prevImages.includes(file));
    const updatedImagesFinal = [...remainingImages, ...newFiles];
    setImagesFinal(updatedImagesFinal);
    getFiles(updatedImagesFinal);
  }, [files]);

  const submitChanges = async () => {
    try {
      var formData = new FormData();

      files.forEach((file) => {
        if (!prevImages.includes(file)) {
          formData.append(`Images`, file);
        }
      });

      const { data } = type==="Res" ? await axios.put(
        `${environment.apiUrl}/changesAPI/pushNewResponseImg/${response.id}`,
        formData
      ) : await axios.put(
        `${environment.apiUrl}/changesAPI/pushNewChangesResponseImg/${response.id}`,
        formData
      );
      console.log(data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {imagesFinal && (
        <>
          <p>uploaded images : {imagesFinal.length}</p>
          <button
          style={{height:"340px"}}
            className="upload-input-admin"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <ul className="file-list" id="file-list-container" style={{width:"100%",alignItems:'flex-start'}}>
              {imagesFinal.map((image, i) => (
                <li key={i}>
                  <div>
                    <img
                      style={{ objectFit: "cover", width: "100px", height: "100px" }}
                      className="file-icon"
                      src={typeof image === "string" ? environment.apiUrl + "/" + image : URL.createObjectURL(image)}
                      alt="File Icon"
                    />
                    <div onClick={() => handleRemoveFile(i)}>
                      <img
                        src={deleteIcon}
                        alt="Delete Icon"
                        width={"20px"}
                        style={{ position: "relative", bottom: "109px", left: "48px", cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </button>
          <div style={{display:'flex',justifyContent:'center'}}>
          <button className="btn-download"  onClick={submitChanges}>submit update</button>
          <button className="btn-download" onClick={handleButtonClick}>add images</button>
          </div>
         
        </>
      )}
    </div>
  );
};

export default UpdateAdminFileUpload;
