import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk'; 
import { composeWithDevTools } from "redux-devtools-extension";
import { addNewUserReducer, loginReducer, resetPWDReducer , getOneUserForAdminReducer} from "./reducers/UsersReducer";
import {ordersReducer, getOrderReducer, getOrderForAdminReducer,modifOrderStatusReducer, validateOrderReducer, getOneOrderReducer,getOrdersFiltredForAdminReducer,getOneOrderForAdminReducer, getOrdersFiltredReducer} from './reducers/OrdersReducer';
import {addResponseReducer, addChangesReducer, addChangeResponseReducer} from './reducers/ResponsesReduces';

const reducer=combineReducers({
    addNewUser:addNewUserReducer,
    loginDetails:loginReducer,
    getOneUserForAdmin:getOneUserForAdminReducer,
    // resetPWD:resetPWDReducer   

    //----order----
    orders: ordersReducer,
    getOrders:getOrderReducer,
    getOrdersForAdmin : getOrderForAdminReducer,
    modifOrderStatus:modifOrderStatusReducer,
    getOrdersFiltred:getOrdersFiltredReducer,
    getOrdersFiltredForAdmin:getOrdersFiltredForAdminReducer,
    validateOrder : validateOrderReducer,

    //responses 

    addResponse : addResponseReducer,
    addChanges : addChangesReducer,
    getOneOrder : getOneOrderReducer,
    getOneOrderForAdmin:getOneOrderForAdminReducer,
    addChangeResponse:addChangeResponseReducer,
})

const fromLocalStorage=localStorage.getItem('cred')?JSON.parse(localStorage.getItem('cred')):null

const initState={
loginDetails:{user:fromLocalStorage}
}

const store=createStore(reducer,initState,composeWithDevTools(applyMiddleware(thunk)))
export default store









