import React from "react";
import "./Partners.css";
import { useTranslation } from "react-i18next";
import WindowWidthComponent from "../../WindowWidthComponent";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import partners from "../../../data/partners.json";

const Partners = () => {
  const logoURL = (imgName) => {
    return require(`../../../assets/img/logo/${imgName}`);
  };

  const { t } = useTranslation();
  const windowWidth = WindowWidthComponent();
  const options = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 1000,
    nav: true,
    loop:true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      1280: {
        items: 1,
        nav: true,
      },
    },
  };

  return (
    <div>
      <div className="partners-container">
        <h3 className="partners-title">{t("partners.title")}</h3>

        {windowWidth <= 1280 ? (
  <OwlCarousel className="owl-theme" {...options}>
    {partners.map((partner, id) => (
      <div key={id} className="partner-container">
        <img
          className="oneImg"
          src={logoURL(partner.src)}
          alt={partner.alt}
          loading="lazy" // Native lazy loading
        />
      </div>
    ))}
  </OwlCarousel>
) : (
  <div className="partners-logos">
    {partners.map((partner, id) => (
      <img
        key={id}
        src={logoURL(partner.src)}
        alt={partner.alt}
        loading="lazy" // Native lazy loading
      />
    ))}
  </div>
)}

      </div>
    </div>
  );
};

export default Partners;
