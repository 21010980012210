import React from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import ProfileComponent from "../../../components/Authentification/Profile/ProfileComponent";
import { Helmet } from "react-helmet-async";

const ProfilePage = () => {
  return (
    <div>
      <Helmet>
      <title>Profile | Meryas Home</title>
        <meta name="keywords" content="profile, user profile, account informationMeryashome, orders, virtual staging, virtual renovation, 3D rendering interior, 3D rendering exterior, 2d textured plan, item removal, image enhancement, photo modification, day to dusk" />
        <meta name="description" content="View and manage your orders and account information on our platform." />
        <meta property="og:title" content="Profile | Meryas Home" />
        <meta property="og:description" content="Increase your selling time by 85% with our virtual real estate services." />
        <meta property="og:image" content="https://www.meryashome.com/static/media/RV%204_Meryas.1614038ec92569e32c1d.png" />
        <meta property="og:url" content="https://www.meryashome.com/profile" />
        <link rel='canonical' href='https://www.meryashome.com/profile' />

      </Helmet>
      {/* <Header /> */}
      <ProfileComponent />
      <Footer />
    </div>
  );
};

export default ProfilePage;
