// RandomBlogCardList.jsx
import React, { useMemo } from 'react';
import { blogData } from "../../data/Blogs";
import { blogDataFr } from "../../data/BlogsFr";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from 'lucide-react';
import vs1 from "../../assets/img/vs/1 - VS_Meryas.png"

import vs6 from "../../assets/img/vs/6 - VS_Meryas.png"

import vs11 from "../../assets/img/vs/12 - VS_Meryas.png"

import vs16 from "../../assets/img/vs/17 - VS_Meryas.png"

import vs21 from "../../assets/img/vs/22 - VS_Meryas.png"
import vs22 from "../../assets/img/vs/23 - VS_Meryas.png"
import vs23 from "../../assets/img/vs/24 - VS_Meryas.png"
import vs24 from "../../assets/img/vs/25 - VS_Meryas.png"
import vs25 from "../../assets/img/vs/26 - VS_Meryas.png"
function RandomBlogCardList() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // Determine which blog data to use based on the current language
  const blogs = i18n.language === 'fr' ? blogDataFr : blogData;

  // Memoize a randomly selected list of 4 blogs
  const randomBlogs = useMemo(() => {
    return blogs
      .sort(() => 0.5 - Math.random()) // Shuffle the blogs
      .slice(0, 3); // Select the first 4 blogs
  }, [blogs]);

  const handleBlogClick = (blog) => {
    navigate(`/blogs/${blog.id}/${blog.title.replace(/\s+/g, '-').toLowerCase()}`);
  };

  const handleSeeAllClick = () => {
    navigate('/blogs');
  };
  const blogImages = [
    // Blog 1 images
    [vs1],
    // Blog 2 images
    [vs6],
    // Blog 3 images
    [vs11],
    // Blog 4 images
    [vs16],
    [vs21],
    [vs22],
    [vs23],
    [vs24],
    [vs25]
  ];
  const img = (id) => {
    return blogImages[id - 1] || null; // Subtract 1 to match array index
  };

  return (
    <section className="py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="font-manrope text-4xl font-bold text-gray-900 text-center mb-14">
          {i18n.language === 'fr' ? 'Blogs' : 'Blogs'}
        </h2>

        {/* Responsive grid layout for random blogs */}
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
          {randomBlogs?.map((blog) => (
            <div
              key={blog.id}
              onClick={() => handleBlogClick(blog)}
              className="group cursor-pointer border border-gray-300 rounded-2xl p-5 transition-all duration-300 hover:border-indigo-600 h-full flex flex-col justify-between"
            >
              <div>
                <div className="flex items-center mb-6">
                  <img src={img(blog.id)} alt={`${blog.imageAlt} image`} className="rounded-lg w-full object-cover" />
                </div>
                <h4 className="text-gray-900 font-medium leading-8 mb-6">{blog.title}</h4>
              </div>

              {/* Footer with author and publication date at the bottom */}
              <div className="flex items-center justify-between mt-auto font-medium pt-4 border-t border-gray-200">
                <h6 className="text-sm text-gray-500 flex items-center gap-1 hover:text-[#ecb365] transition-all ">
                  {i18n.language === 'fr' ? 'Lire la suite' : 'Read more'}
                  <ArrowRight size={20} />
                </h6>
              </div>
            </div>
          ))}
        </div>

        {/* See All button */}
        <div className="flex justify-center mt-10">
          <button
            onClick={handleSeeAllClick}
            className="px-6 py-2 font-medium text-white bg-[#ecb365] rounded-lg hover:bg-[#ecb365bd] transition-all duration-300"
          >
            {i18n.language === 'fr' ? 'Voir tous' : 'See All'}
          </button>
        </div>
      </div>
    </section>
  );
}

export default RandomBlogCardList;
