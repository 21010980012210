import React from "react";

import USD from '../assets/img/flags/us.png'
import EUR from '../assets/img/flags/eur.png'
import AUD from '../assets/img/flags/aus.png'
import CAD from '../assets/img/flags/cad.png'
import GBP from '../assets/img/flags/ls.png'
import NZD from '../assets/img/flags/nzd.png'
import Other from '../assets/img/flags/world.png'

const CurrencyFlagComponent = ({ currency }) => {
    let flagSrc = CAD; 
    switch (currency) {
        case "USD":
            flagSrc = USD;
            break;
        case "EUR":
            flagSrc = EUR;
            break;
        case "AUD":
            flagSrc = AUD;
            break;
        case "CAD":
            flagSrc = CAD;
            break;
        case "GBP":
            flagSrc = GBP;
            break;
        case "NZD":
            flagSrc = NZD;
            break;
        case "OTHER":
            flagSrc = Other;
            break;
        default:
            flagSrc = CAD;
    }
 

    const flagStyle = currency === 'OTHER' ? { width: '40px', height: '20px' } : { width: 35, height: 35};

    return <img src={flagSrc} alt={`${currency} Flag`} style={flagStyle} />;
};


export default CurrencyFlagComponent