export const ADD_NEW_ORDER="ADD_NEW_ORDER"
export const ADD_NEW_ORDER_SUCCESS="ADD_NEW_ORDER_SUCCESS"
export const ADD_NEW_ORDER_FAIL="ADD_NEW_ORDER_FAIL"

export const GET_ORDER_REQUEST="GET_ORDER_REQUEST"
export const GET_ORDER_SUCCESS="GET_ORDER_SUCCESS"
export const GET_ORDER_FAIL="GET_ORDER_FAIL"


export const GET_ALL_ORDER_REQUEST="GET_ALL_ORDER_REQUEST"
export const GET_ALL_ORDER_SUCCESS="GET_ALL_ORDER_SUCCESS"
export const GET_ALL_ORDER_FAIL="GET_ALL_ORDER_FAIL"



export const MODIF_ORDER_STATUS_REQUEST="MODIF_ORDER_STATUS_REQUEST"
export const MODIF_ORDER_STATUS_SUCCESS="MODIF_ORDER_STATUS_SUCCESS"
export const MODIF_ORDER_STATUS_FAIL="MODIF_ORDER_STATUS_FAIL"


export const GET_ONE_ORDER_REQUEST="GET_ONE_ORDER_REQUEST"
export const GET_ONE_ORDER_SUCCESS="GET_ONE_ORDER_SUCCESS"
export const GET_ONE_ORDER_FAIL="GET_ONE_ORDER_FAIL"



export const GET_ONE_ORDER_FORADMIN_REQUEST="GET_ONE_ORDER_FORADMIN_REQUEST"
export const GET_ONE_ORDER_FORADMIN_SUCCESS="GET_ONE_ORDER_FORADMIN_SUCCESS"
export const GET_ONE_ORDER_FORADMIN_FAIL="GET_ONE_ORDER_FORADMIN_FAIL"



export const GET_ORDERS_FILTRED_REQUEST="GET_ORDERS_FILTRED_REQUEST"
export const GET_ORDERS_FILTERS_SUCCESS="GET_ORDERS_FILTERS_SUCCESS"
export const GET_ORDERS_FILTRED_FAIL="GET_ORDERS_FILTRED_FAIL"

export const GET_ORDERS_FILTRED_FOR_ADMIN_REQUEST="GET_ORDERS_FILTRED_FOR_ADMIN_REQUEST"
export const GET_ORDERS_FILTERS_FOR_ADMIN_SUCCESS="GET_ORDERS_FILTERS_FOR_ADMIN_SUCCESS"
export const GET_ORDERS_FILTRED_FOR_ADMIN_FAIL="GET_ORDERS_FILTRED_FOR_ADMIN_FAIL"


export const VALIDATE_ORDER_REQUEST="VALIDATE_ORDER_REQUEST"
export const VALIDATE_ORDER_SUCCESS="VALIDATE_ORDER_SUCCESS"
export const VALIDATE_ORDER_FAIL="VALIDATE_ORDER_FAIL"
