import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component"; // Import LazyLoadImage component
import "react-lazy-load-image-component/src/effects/blur.css"; // Import lazy load effect (optional)
import Header from "../../components/Header/Header";
import Partners from "../../components/HomeComponents/Partners/Partners";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/Footer/Footer";
import vs from "../../assets/img/banner/4.png";
import renovation from "../../assets/img/banner/9.png";
import inter3D from "../../assets/img/services/Web19-15x6.png";
import exter3D from "../../assets/img/3dRendering/Meryas-Façade 2.jpg";
import plan2D from "../../assets/img/services/WebB-A17-15x6.png";
import D2D from "../../assets/img/services/WebB-A1-15x6.png";
import enhancementimg from "../../assets/img/services/WebB-A18-15x6.png";
import photomodif from "../../assets/img/services/WebB-A14-15x6.png";
import itemremoval from "../../assets/img/banner/10.png";

const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const location = useLocation();

  const stagingRef = useRef(null);
  const renovationRef = useRef(null);
  const modelingRef = useRef(null);
  const enhancementRef = useRef(null);
  const removalRef = useRef(null);
  const twoDRef = useRef(null);
  const modelingExtRef = useRef(null);
  const ddRef = useRef(null);
  const lccRef = useRef(null);

  useEffect(() => {
    const scrollElementId = location.hash.substring(1);

    const element =
      scrollElementId === "STAGING"
        ? stagingRef.current
        : scrollElementId === "RENOVATION"
        ? renovationRef.current
        : scrollElementId === "MODELINGINT"
        ? modelingRef.current
        : scrollElementId === "MODELINGEXT"
        ? modelingExtRef.current
        : scrollElementId === "ENHANCEMENT"
        ? enhancementRef.current
        : scrollElementId === "REMOVAL"
        ? removalRef.current
        : scrollElementId === "PM"
        ? lccRef.current
        : scrollElementId === "DD"
        ? ddRef.current
        : scrollElementId === "2D"
        ? twoDRef.current
        : null;

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem("serviceURL", `services`);
  }, []);

  return (
    <>
      <div className="servicesContainer">
        <div className="servicesContent">
          <h1>{t("services-page.title")}</h1>

          <div className="partOneServices" id="STAGING" ref={stagingRef}>
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc1.title")}</h2>
              <p>{t("services-page.bloc1.text")}</p>
              <Link to={"/virtual-staging"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={vs}
              alt="virtual staging service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={renovationRef} />
          </div>

          <div id="RENOVATION" className="partTwoServices">
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc2.title")}</h2>
              <p>{t("services-page.bloc2.text")}</p>
              <Link to={"/virtual-renovation"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={renovation}
              alt="virtual renovation service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={modelingRef} />
          </div>

          <div id="MODELINGINT" className="partOneServices">
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc3.title")}</h2>
              <p>{t("services-page.bloc3.text")}</p>
              <Link to={"/3D-rendering(interior)"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={inter3D}
              alt="3D rendering interior service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={modelingExtRef} />
          </div>

          <div id="MODELINGEXT" className="partTwoServices">
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc4.title")}</h2>
              <p>{t("services-page.bloc4.text")}</p>
              <Link to={"/3D-rendering(exterior)"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={exter3D}
              alt="3D rendering exterior service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={enhancementRef}></div>
          </div>

          <div id="ENHANCEMENT" className="partOneServices">
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc5.title")}</h2>
              <p>{t("services-page.bloc5.text")}</p>
              <Link to={"/image-Enhancement"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={enhancementimg}
              alt="image enhancement service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={removalRef}></div>
          </div>

          <div id="REMOVAL" className="partTwoServices">
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc6.title")}</h2>
              <p>{t("services-page.bloc6.text")}</p>
              <Link to={"/item-removal"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={itemremoval}
              alt="item removal service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={twoDRef}></div>
          </div>

          <div id="2D" className="partOneServices">
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc8.title")}</h2>
              <p>{t("services-page.bloc8.text")}</p>
              <Link to={"/2d-textured-plan"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={plan2D}
              alt="2d textured plan service"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={ddRef}></div>
          </div>

          <div id="DD" className="partTwoServices">
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc7.title")}</h2>
              <p>{t("services-page.bloc7.text")}</p>
              <Link to={"/day-to-dusk"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={D2D}
              alt="day to dusk"
              effect="blur"
              width="100%"
              height="auto"
            />
            <div ref={lccRef}></div>
          </div>

          <div id="PM" className="partOneServices">
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc9.title")}</h2>
              <p>{t("services-page.bloc9.text")}</p>
              <Link to={"/photo-modification"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <LazyLoadImage
              src={photomodif}
              alt="photo modification service"
              effect="blur"
              width="100%"
              height="auto"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
