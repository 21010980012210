import React from 'react';

const LineComponent = () => {
  const lineStyle = {
    border: '1px solid #06466366',
    maxWidth:"900px",
    width: '100%',  
    margin: '0 auto',  
    boxSizing: 'border-box',
  };

  return (
    <div style={lineStyle}></div>
  );
};

export default LineComponent;
