import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Content from "./content/Content";
import Partners from "../../components/HomeComponents/Partners/Partners";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../components/Testimonials/Testimonials";
import WhyUs from "../../components/HomeComponents/WhyUs/WhyUs";
import Steps from "../Steps";
import StepSignup from "../../components/SignUpSteps";

const Gallery = ({ navigate }) => {
  const location = useLocation();

  // Define meta data for each service route
  const metaData = {
    "virtual-staging": {
      title: "Virtual Staging | Meryas Home",
      description: "Transform empty spaces into beautifully staged homes with Meryas Home's virtual staging services, perfect for boosting real estate sales.",
      keywords: "virtual staging, home staging, real estate virtual staging, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/virtual-staging/1"
    },
    "3D-rendering": {
      title: "3D Rendering | Meryas Home",
      description: "Explore our 3D rendering services for real estate, creating visually stunning interiors and exteriors from 2D plans.",
      keywords: "3D rendering, real estate 3D modeling, interior rendering, exterior rendering, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/3D-rendering/2"
    },
    "virtual-renovation": {
      title: "Virtual Renovation | Meryas Home",
      description: "Virtually renovate properties to showcase potential transformations. Perfect for real estate listings.",
      keywords: "virtual renovation, real estate remodeling, digital renovation, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/virtual-renovation/3"
    },
    "item-removal": {
      title: "Item Removal | Meryas Home",
      description: "Remove unwanted items from photos to present clean, clutter-free listings.",
      keywords: "photo item removal, real estate photo editing, clutter removal, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/item-removal/4"
    },
    "virtual-tour": {
      title: "Virtual Tour | Meryas Home",
      description: "Bring properties to life with immersive virtual tours, allowing potential buyers to explore online.",
      keywords: "virtual tour, real estate tours, online property tours, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/virtual-tour/5"
    },
    "day-to-dusk": {
      title: "Day to Dusk | Meryas Home",
      description: "Transform daytime photos into captivating evening scenes with day-to-dusk photo editing.",
      keywords: "day to dusk, real estate photo editing, evening transformation, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/day-to-dusk/6"
    },
    "2D-Plan": {
      title: "2D Floor Plans | Meryas Home",
      description: "Visualize properties with 2d textured plans, providing a clear layout for potential buyers.",
      keywords: "2D floor plans, real estate layout, color floor plans, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/2D-Plan/7"
    },
    "Photo-modification": {
      title: "Photo Modification | Meryas Home",
      description: "Edit photos by adding features like grass, concrete, or changing material colors.",
      keywords: "photo modification, real estate photo editing, Meryas Home",
      canonical: "https://www.meryashome.com/gallery/Photo-modification/8"
    }
  };

  // Extract service type from URL path
  const serviceType = location.pathname.split("/")[2];

  // Get the meta data based on the service type or fallback to a default meta
  const meta = metaData[serviceType] || {
    title: "Gallery - Meryas Home",
    description: "Explore Meryas Home's portfolio of virtual staging, 3D modeling, and photo enhancement projects.",
    keywords: "real estate photo gallery, virtual staging, 3D modeling, image enhancement, Meryas Home",
    canonical: "https://www.meryashome.com/gallery/"
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === "/gallery") {
      navigate("/gallery/virtual-staging/1");
      localStorage.setItem("serviceURL", `gallery/virtual-staging/1`);
    }
  }, [location.pathname, navigate]);

  return (
    <div className="bg">
      {/* Dynamic Meta Tags */}
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
        <link rel="canonical" href={meta.canonical} />
        <meta property="og:url" content="https://www.meryashome.com/gallery/virtual-staging/1" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Meryas Home" />

      </Helmet>

      {/* Page Content */}
      <Content navigate={navigate} />
      <WhyUs />
      <Steps />
      <StepSignup />
      <Partners />
      <PlaceOrder />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Gallery;