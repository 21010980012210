import React from 'react';
import './NotFoundComponent.css'; // Import CSS file

const NotFoundComponent = () => {
  return (
    <div className="not-found-container">
      <div className="text-center">
        <div className="circle-background">
          <div className="circle-inner">
          <svg width="100" height="100" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 10V16M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#ca8a04" stroke-width="1.5" stroke-linecap="round"/>
<path d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" fill="#ca8a04"/>
</svg>

          </div>
        </div>
        <h1 className="not-found-heading">404 - Page not found</h1>
        <p className="not-found-text">The page you are looking for doesn't exist or <br />has been removed.</p>
      </div>
    </div>
  );
}

export default NotFoundComponent;
