import { useEffect, useRef, useState } from 'react'
import "./HeaderTwo.css"
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { useDispatch } from 'react-redux'
import { Link,useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { logout } from '../../redux/actions/UsersActions'
import USD from "../../assets/img/flags/us.png";
import WindowWidthComponent from "../WindowWidthComponent";
import {
    VscMenu,
    VscMail,
    VscChromeClose,
    VscChevronDown,
  } from "react-icons/vsc";
  import svgImage from "../../assets/img/icons/iphone.svg";
  import CurrencyFlagComponent from "../FlagComponent";
import { environment } from '../../envirement'
import { t } from 'i18next'

const cities = [
    { name: t("locations.montreal.name"), to: `${environment.FrontUrl}/cities/montreal` },
    { name: t("locations.ottawa.name"), to: `${environment.FrontUrl}/cities/ottawa` },
    { name: t("locations.toronto.name"), to: `${environment.FrontUrl}/cities/toronto` },
    { name: t("locations.vancouver.name"), to: `${environment.FrontUrl}/cities/vancouver` },
    { name: t("locations.new york.name"), to: `${environment.FrontUrl}/cities/new york` },
    { name: t("locations.las vegas.name"), to: `${environment.FrontUrl}/cities/las vegas` },
    { name: t("locations.chicago.name"), to: `${environment.FrontUrl}/cities/chicago` },
    { name: t("locations.atlanta.name"), to: `${environment.FrontUrl}/cities/atlanta` },
    { name: t("locations.florida.name"), to: `${environment.FrontUrl}/cities/florida` },
    { name: t("locations.miami.name"), to: `${environment.FrontUrl}/cities/miami` },
    { name: t("locations.washington.name"), to: `${environment.FrontUrl}/cities/washington` },
    { name: t("locations.san diego.name"), to: `${environment.FrontUrl}/cities/san diego` },
    { name: t("locations.philadelphia.name"), to: `${environment.FrontUrl}/cities/philadelphia` },
    { name: t("locations.california.name"), to: `${environment.FrontUrl}/cities/california` },
    { name: t("locations.boston.name"), to: `${environment.FrontUrl}/cities/boston` },
    { name: t("locations.los angeles.name"), to: `${environment.FrontUrl}/cities/los angeles` },
    { name: t("locations.monaco.name"), to: `${environment.FrontUrl}/cities/monaco` },
    { name: t("locations.paris.name"), to: `${environment.FrontUrl}/cities/paris` },
    { name: t("locations.berlin.name"), to: `${environment.FrontUrl}/cities/berlin` },
    { name: t("locations.sydney.name"), to: `${environment.FrontUrl}/cities/sydney` }
  ];
  const services = [
    { 
      name: t("header.nav-bar-services.s1"), 
      to: `/virtual-staging` 
    },
    { 
      name: t("header.nav-bar-services.s2"), 
      to: `/virtual-renovation` 
    },
    { 
      name: t("header.nav-bar-services.s3"), 
      to: `/3D-rendering(interior)` 
    },
    { 
      name: t("header.nav-bar-services.s4"), 
      to: `/3D-rendering(exterior)` 
    },
    { 
      name: t("header.nav-bar-services.s5"), 
      to: `/image-Enhancement` 
    },
    { 
      name: t("header.nav-bar-services.s6"), 
      to: `/item-removal` 
    },
    { 
      name: t("header.nav-bar-services.s7"), 
      to: `/day-to-dusk` 
    },
    { 
      name: t("header.nav-bar-services.s8"), 
      to: `/2d-textured-plan` 
    },
    { 
      name: t("header.nav-bar-services.s9"), 
      to: `/photo-modification` 
    },
  ];
const products = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

export default function HeaderTwo({ getCurrency }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const windowWidth = WindowWidthComponent();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const credString = localStorage.getItem("cred");
    const cred = JSON.parse(credString);
    setIsAdmin(cred && cred.user && cred.user.Role === "Superadmin");
  });

  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem("serviceURL", "");
    setIsAdmin(false);
  };

  const storedCred = JSON.parse(localStorage.getItem("cred"));
  const isLoggedIn =
    storedCred && storedCred.user && storedCred.user.id !== null;

  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem("lng") || "en"
  );
  const [activeCurrency, setActiveCurrency] = useState(
    localStorage.getItem("currency")
  );

  const [flagCurrency, setFlagCurrency] = useState(null);

  useEffect(() => {
    // Vérifie si la devise est déjà stockée dans le localStorage
    const storedCurrency = localStorage.getItem("currency");

    // Si la devise n'est pas déjà stockée, définissez-la sur CAD par défaut
    if (!storedCurrency) {
      localStorage.setItem("currency", "CAD");
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
    localStorage.setItem("lng", lng);
    // console.log(`Language changed to: ${lng}`);
    window.location.reload()
  };
  const changeCurrency = (curr) => {
    if (curr === "OTHER") {
      setActiveCurrency("OTHER"); // Modifier ici pour afficher "Other countries" dans le menu déroulant
      localStorage.setItem("currency", "USD");
      getCurrency("USD");
      setFlagCurrency(USD);
    } else {
      setActiveCurrency(curr);
      localStorage.setItem("currency", curr);
      getCurrency(curr);
      setFlagCurrency(curr);
    }
    toggleAccordion();
  };

  const toggleSubMenu = () => {
    // Implementation for toggling the sub-menu goes here
  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const buttonRef = useRef();
  const citybuttonRef = useRef();

  return (
    <div className='sticky top-0 z-40 w-full ' >
<div className="flex  items-center justify-between w-full h-[41px] bg-black text-[#f5deb3] font-nunito  text-[17px] leading-[16px] tracking-[0.02em] px-[20px] ">
  <div className=" gap-[20px] items-center hidden md:flex">
    <div className="phoneNumber flex items-center gap-[5px]">
      <img src={svgImage} alt="Phone Icon" className="w-[17px] h-[20px]" />
      <a href="tel:+18886498993" className="text-[#f5deb3] text-[12px] fonttype">+1 (888) 649-8993</a>
    </div>
    <div className="phoneNumber flex items-center gap-[5px]">
      <VscMail style={{ fontSize: "20px", color: "wheat" }} />
      <p className="text-[#f5deb3] text-[12px] m-0">info@meryashome.com</p>
    </div>
  </div>
  
  <p className="text-[#f5deb3] text-[12px] m-0 hidden xl:inline-block "> {t(`header.topPart.text`)} </p>
  
  <div className="flex gap-[10px] items-center topNav">
    <div className="currency-flag-container">
      <Link to="/change-location">
        <CurrencyFlagComponent currency={activeCurrency} />
      </Link>
    </div>
    <p className="flex items-center gap-[5px] text-[12px] text-[#f5deb3]">
      <span
        className={`language ${activeLanguage === "en" ? "active" : ""} cursor-pointer hover:opacity-90 transition-all`}
        onClick={() => changeLanguage("en")}
      >
        ENGLISH
      </span>
      -
      <span
        className={`language ${activeLanguage === "fr" ? "active" : ""} cursor-pointer hover:opacity-90 transition-all`}
        onClick={() => changeLanguage("fr")}
      >
        FRANÇAIS
      </span>
    </p>
  </div>
</div>


          <header className="bg-[#041C32]">
      <nav aria-label="Global" className="flex  items-center justify-between ">
        <div className="">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <Link to={"/"} className="a">
                <label className="text-[35px] w-[165px] h-[72px] cursor-pointer ">
                  <img
                    src={require("../../assets/img/lionLogo.png")}
                    alt="meryas icon"
                  />
                </label>
              </Link>
          </a>
        </div>
        <div className="flex menu">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className=" inline-flex items-center justify-center rounded-md  text-white bg-transparent"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        {/* <PopoverGroup className="hidden lg:flex lg:gap-x-12 lg:items-center">
          <Popover className="relative">
            <PopoverButton className=" gap-x-1 dh-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
              Product
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {products.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                    </div>
                    <div className="flex-auto">
                      <a href={item.href} className="block  text-gray-900">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="fgap-x-2.5 p-3 dh-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between hover:bg-gray-100"
                  >
                    <item.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                    {item.name}
                  </a>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
          <Link to={"/"} className="elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                      {t("header.menu.m2")}
                    </Link>
          <a href="#" className="h-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Features
          </a>
          <a href="#" className="h-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Marketplace
          </a>
          <a href="#" className="h-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Company
          </a>
        </PopoverGroup> */}
             <PopoverGroup className=" hidden w-full justify-evenly lg:flex lg:gap-[12px] lg:items-center mobileEl">
             {
                    isAdmin? (
                        <Link
                        to={"/usersManagement"}
                        className="elementbtn"
                        onClick={()=>setMobileMenuOpen(false)}
                      >
                        Users Management
                      </Link>
                    ):
                    <Link to={"/"} className=" elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m2")}
                  </Link>
                }
                        {/* Residentiel real estate */}
 {
    isAdmin? (
        <Link
        to={"/testimonialManagement"}
        className="elementbtn"
        onClick={()=>setMobileMenuOpen(false)}
      >
        Testimonial Management
      </Link>
    ):
<Popover className="relative ">
          <PopoverButton ref={buttonRef} className="gap-x-1 h-[41px] w-max m-0 text-white bg-transparent text-[17px] leading-[16px] tracking-[0.02em] p-0 flex items-center justify-between focus:outline-none focus:ring-0  ">
  <span className='hover:text-[#ecb365] transition-all'>
  {t("header.menu.m6")}
  </span>
  <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
</PopoverButton>


            <PopoverPanel
              transition
              className=" absolute  top-full z-10 m0 w-max  overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="">
              {services.map((service) => (
      <div
      
        key={service.name}
        className="  group relative flex items-center gap-x-6  py-3 px-2 text-sm hover:bg-[#ecb365] hover:transition-all "
      >
        <div className="flex-auto  w-full">
          <Link  onClick={() => buttonRef.current?.click()}  to={service.to} className="block text-black group-hover:!text-white">
            {service.name}
            <span className="absolute inset-0" />
          </Link>
        </div>
      </div>
    ))}
              </div>
             
            </PopoverPanel>
          </Popover>
 }
          
                      {/* Commercial Real estate */}
{isAdmin?(
     <Link
     to={"/recentlyStagedManagement"}
     className="elementbtn"
     onClick={()=>setMobileMenuOpen(false)}
   >
     Staged listing Management
   </Link>
):
( 
/* <Popover className="relative">
    <PopoverButton className="m-0 gap-x-1 h-[41px] w-max text-white bg-transparent text-[17px] leading-[16px] tracking-[0.02em] p-0 flex items-center justify-between focus:outline-none focus:ring-0">
<span className='hover:text-[#ecb365] transition-all' >
{t("header.menu.m10")}
</span>

<ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
</PopoverButton>


      <PopoverPanel
        transition
        className="absolute  top-full z-10 mt-3 w-fit  overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="">
        {services.map((service) => (
<div
  key={service.name}
  className="  group relative flex items-center gap-x-6  py-3 px-2 text-sm hover:bg-[#ecb365] hover:transition-all "
>
  <div className="flex-auto  w-full">
    <Link to={service.to} className="block text-black group-hover:!text-white">
      {service.name}
      <span className="absolute inset-0" />
    </Link>
  </div>
</div>
))}
        </div>
       
      </PopoverPanel>
    </Popover> */
    null
  )
}
        
          <Link to={"/prices"} className="elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m13")}
                  </Link>
          <Link to={"/gallery"} className="elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m7")}
                  </Link>
                  {/* cities */}
                  <Popover className="relative">
          <PopoverButton ref={citybuttonRef} className=" p-0 gap-x-1 h-[41px] w-max text-white bg-transparent text-[17px] leading-[16px] tracking-[0.02em]  flex items-center justify-between focus:outline-none focus:ring-0">
  
  <span className='hover:text-[#ecb365] transition-all'>
  {t("header.menu.m11")}
  </span>
  <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
</PopoverButton>


            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-fit  overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="overflow-auto h-96">
              {cities.map((city) => (
      <div
        key={city.name}
        className="  group relative flex items-center gap-x-6  py-3 px-2 text-sm hover:bg-[#ecb365] hover:transition-all "
      >
        <div className="flex-auto  w-full">
          <Link onClick={() => citybuttonRef.current?.click()} to={city.to} className="block text-black group-hover:!text-white">
            {city.name}
            <span className="absolute inset-0" />
          </Link>
        </div>
      </div>
    ))}
              </div>
             
            </PopoverPanel>
          </Popover>
        {!isAdmin &&   <Link to={"/about"} className="elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m12")}
                  </Link>}
                  {!isAdmin && <Link to={"/blogs"} className="elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                      Blog
                    </Link>}
                    <Link to={"/contact"} className="elementbtn" onClick={()=>setMobileMenuOpen(false)}>
                    Contact
                  </Link>
          {/* <a href="#" className="h-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Features
          </a>
          <a href="#" className="h-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Marketplace
          </a>
          <a href="#" className="h-[41px] w-full  text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Company
          </a> */}
        </PopoverGroup>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className=" h-[41px]   text-white   text-[17px] leading-[16px] tracking-[0.02em] px-[20px] flex items-center justify-between">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div> */}
          <div className="gap-2 flex mobileEl mr-6">
                  {isLoggedIn ? (
                    <>
                      {" "}
                      
                        <Link
                          onClick={handleLogout}
                          to={"/"}
                          className=" text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                          id="login"
                        >
                          {t("header.topPart.logout")}
                        </Link>
                      
                      
                        <Link
                          to={"/profile"}
                          id="order"
                          onClick={()=>setMobileMenuOpen(false)}
                          className="a text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                        >
                          {t("header.topPart.orders")}
                        </Link>
                      
                    </>
                  ) : (
                    <>
                      
                        <Link
                          to={"/login"}
                          className=" whitespace-nowrap text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                          id="login"
                          onClick={()=>setMobileMenuOpen(false)}
                        >
                          {t("header.topPart.Login")}
                        </Link>
                      
                      
                        <Link
                          to={"/prices"}
                          // to={"/placeOrder"}
                          onClick={()=>setMobileMenuOpen(false)}
                          id="order"
                          className="whitespace-nowrap text-[#041C32] hover:!text-black w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope] "
                        >
                          {t("header.menu.m9")}
                        </Link>
                      
                    </>
                  )}
                </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className=" ">
        <div className="fixed inset-0 z-50" />
        <DialogPanel transition className={` fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10  origin-righ  duration-300 ease-out data-[closed]:scale-180 data-[closed]:opacity-0`}>
          <div className="flex items-center justify-between">
            <a className="-m-1.5 p-1.5 ">
              {/* <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              /> */}
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {
                    isAdmin? (
                        <Link
                        to={"/usersManagement"}
                        className=" block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50"
                        onClick={()=>setMobileMenuOpen(false)}
                      >
                        Users Management
                      </Link>
                    ):
                    <Link to={"/"} className=" block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m2")}
                  </Link>
                }
             
                    {/* Residentiel */}
                    {isAdmin?(
 <Link
 to={"/testimonialManagement"}
 className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50"
 onClick={()=>setMobileMenuOpen(false)}
>
 Testimonial Management
</Link>
                    ):
                    (
                        <Disclosure as="div" className="-mx-3">
                        <DisclosureButton className=" transition-all group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7  text-gray-900 hover:bg-gray-50 hover:text-[#ecb365] ">
                        {t("header.menu.m6")}
                          <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel transition className="mt-2 space-y-2 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                          {[...services].map((item) => (
                              <Link to={item.to} onClick={()=> setMobileMenuOpen(false)} >
                            <DisclosureButton
                              key={item.name}
                              as="a"
                              // href={item.to}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm/7  text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </DisclosureButton>
                            </Link>
                          ))}
                        </DisclosurePanel>
                      </Disclosure>
                    )}
               
                {/* commercial */}
                {isAdmin?(
                    <Link
                    to={"/recentlyStagedManagement"}
                    className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50"
                    onClick={()=>setMobileMenuOpen(false)}
                  >
                    Staged listing Management
                  </Link>
                ):(
/* <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="hover:text-[#ecb365] transition-all group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7  text-gray-900 hover:bg-gray-50">
                  {t("header.menu.m10")}
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel transition className="mt-2 space-y-2 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                    {[...services].map((item) => (
                        <Link to={item.to} onClick={()=> setMobileMenuOpen(false)} >
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        // href={item.to}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm/7  text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                      </Link>
                    ))}
                  </DisclosurePanel>
                </Disclosure> */
                null
                )}
                
                <Link to={"/prices"} className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m13")}
                  </Link>
          <Link to={"/gallery"} className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m7")}
                  </Link>
                  {/* cities */}
                  <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="hover:text-[#ecb365] transition-all group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7  text-gray-900 hover:bg-gray-50">
                  {t("header.menu.m11")}
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel transition className="mt-2 space-y-2 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
                    {[...cities].map((item) => (
                        <Link to={item.to} onClick={()=> setMobileMenuOpen(false)} >
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        // href={item.to}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm/7  text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                      </Link>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                {!isAdmin && <Link to={"/about"} className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50" onClick={()=>setMobileMenuOpen(false)}>
                    {t("header.menu.m12")}
                  </Link>}
                 {!isAdmin&& ( <Link to={"/blogs"} className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50" onClick={()=>setMobileMenuOpen(false)}>
                     Blog
                    </Link>
                  )}
 <Link to={"/contact"} className="block rounded-lg  py-2 text-base/7  text-gray-900 hover:bg-gray-50" onClick={()=>setMobileMenuOpen(false)}>
                    Contact
                  </Link>
                {/* <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7  text-gray-900 hover:bg-gray-50">
                    Product
                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...products, ...callsToAction].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm/7  text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure> */}
             
              </div>
              <div className="py-6">
                 <div className="gap-2 flex ">
                  {isLoggedIn ? (
                    <>
                      {" "}
                      
                        <Link
                          onClick={handleLogout}
                          to={"/"}
                          className=" text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                          id="login"
                        >
                          {t("header.topPart.logout")}
                        </Link>
                      
                      
                        <Link
                          to={"/profile"}
                          id="order"
                          onClick={()=>setMobileMenuOpen(false)}
                          className="a text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                        >
                          {t("header.topPart.orders")}
                        </Link>
                      
                    </>
                  ) : (
                    <>
                      
                        <Link
                          to={"/login"}
                          className=" text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                          id="login"
                          onClick={()=>setMobileMenuOpen(false)}
                        >
                          {t("header.topPart.Login")}
                        </Link>
                      
                      
                        <Link
                          to={"/prices"}
                          // to={"/placeOrder"}
                          onClick={()=>setMobileMenuOpen(false)}
                          id="order"
                          className="text-[#041C32] w-fit text-[17px] p-[2px] h-7 flex justify-center items-center font-semibold text-base rounded-sm font-[Manrope]"
                        >
                          {t("header.menu.m9")}
                        </Link>
                      
                    </>
                  )}
                </div>
                {/* <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7  text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a> */}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
    </div>
   
  )
}