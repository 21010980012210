import React, { useRef, useState } from "react";
import "./OrderCard.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { environment } from "../../../envirement";
import OrderCheckbox from "../../OrderCheckbox";
import axios from "axios"; // Import Axios for API requests

const OrderCard = ({ displayMode, orders, isAdmin }) => {
  const [hoveredArticle, setHoveredArticle] = useState(null);
  const [orderStatuses, setOrderStatuses] = useState(
    orders.orders.reduce((acc, order) => {
      acc[order.id] = order.Admin_Order_Status;
      return acc;
    }, {})
  );

  const dropdownRef = useRef(null);

  const handleMouseOver = (articleId) => {
    setHoveredArticle(articleId);
  };

  const handleMouseOut = () => {
    setHoveredArticle(null);
  };

  const { t } = useTranslation();
  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };

  const addressDisplay = (address) => {
    if (address && address.length > 38) {
      const truncatedAddress = address.substring(0, 38) + "...";
      return <>{truncatedAddress}</>;
    } else if (address) {
      return <>{address}</>;
    } else {
      return "N/A";
    }
  };

  let Orders = orders.orders;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  // Function to handle status change and update via API
  const handleStatusChange = async (orderId, newStatus) => {
    try {
      await axios.put(`${environment.apiUrl}/ordersAPI/updateOrderStatusForAdmin`, {
        orderId,
        status: newStatus,
      });
      setOrderStatuses((prevStatuses) => ({
        ...prevStatuses,
        [orderId]: newStatus,
      }));
      // alert("Order status updated successfully");
    } catch (error) {
      console.error("Failed to update order status", error);
    }
  };
  const handleArticleClick = (e) => {
    if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      return; // Ignore click if it’s from the dropdown
    }
    // Otherwise, handle the article click
    console.log("Article clicked");
  };
  const getStatusBgColor = (status) => {
    switch (status) {
      case "Closed":
        return "bg-red-500"; // Tailwind red
      case "InProgress":
        return "bg-orange-500"; // Tailwind orange
      case "Todo":
        return "bg-gray-200"; // Default gray
      default:
        return "bg-gray-200"; // Default gray if status is undefined
    }
  };
  return (
    <div className={`articles-container ${displayMode}`}>
      <section className="articles">
        {Orders.map((order) => (

          <div className="">
           {isAdmin && (
                    <div style={{ marginTop: "10px",width:"100%" }}>
                      {/* <label htmlFor="order-status">Order Status: </label> */}
                      <select
                      className={`w-full ${orderStatuses[order.id]=="Closed"?"bg-red-600":orderStatuses[order.id]=="InProgress"?"bg-orange-500":"bg-slate-50"} `}
                                            ref={dropdownRef}

                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                        id="order-status"
                        value={orderStatuses[order.id] || order.Admin_Order_Status}

                        onChange={(e) => handleStatusChange(order.id, e.target.value)}
                      >
                        <option value="Todo">Todo</option>
                        <option value="InProgress">InProgress</option>
                        <option value="Closed">Closed</option>
                      </select>
                    </div>
                  )}
                    <article
            key={order.id}
            className={order.id === hoveredArticle ? "bigger-article" : ""}
            onMouseOver={() => handleMouseOver(order.id)}
            onMouseOut={handleMouseOut}
          >
           
            {displayMode === "tableau" ? (
              <div className="article-wrapper">
                <figure>
                
                  {order && order.Images[0] && order.Images[0].Image && (
                    <img src={imgPath(order.Images[0].Image)} alt="order now" />
                  )}
                </figure>
                <div className="article-body">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2>{order.Ref}</h2>
                    <div>{formatDate(order.createdAt)}</div>
                  </div>
                  <p>
                    {t("account.address-label")}:{" "}
                    {order.id === hoveredArticle ? order.Address || "N/A" : addressDisplay(order.Address)}
                  </p>
                  
                </div>
                {isAdmin ? (
                  <div
                    style={{
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Nunito",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      {`${order.User_First_Name} ${order.User_Last_Name}`}
                    </span>
                    <Link
                      to={`/profile/${order.id}`}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "800",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "#006FB7",
                        textDecoration: "underline",
                        fontSize: "14px",
                      }}
                    >
                      {t("account.btn")}
                    </Link>
                  </div>
                ) : (
                  <Link to={`/profile/${order.id}`} className="read-more">
                    {t("account.btn")}
                  </Link>
                )}
              </div>
            ) : (
              <div className="article-wrapper-list">
                <div className="article-body-list">
                  <h2>{order.Ref} </h2>
                  <p>
                    {t("account.address-label")}:{" "}
                    {order.id === hoveredArticle ? order.Address || "N/A" : addressDisplay(order.Address)}
                  </p>
                </div>
                {isAdmin && (
                  <>
                    <p
                      style={{
                        fontFamily: "Nunito",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      {`${order.User_First_Name} ${order.User_Last_Name}`}
                    </p>
                    <Link to={`/profile/${order.id}`} className="read-more">
                      {t("account.btn")}
                    </Link>
                  </>
                )}
              </div>
            )}
          </article>
          </div>
        
        ))}
      </section>
    </div>
  );
};

export default OrderCard;
