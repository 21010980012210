import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DescpRetourComponent.css";
import { useDispatch, useSelector } from "react-redux";
import AdminFileUpload from "../../../FileUpload/AdminFileUpload";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { environment } from "../../../../envirement";
import {
  addNewResponse,
  addNewChangeResponse,
} from "../../../../redux/actions/ResponsesActions";
import fileImg from "../../../../assets/img/fileimage_13.png";

import { FallingLines } from "react-loader-spinner";
import axios from "axios";
import UpdateAdminFileUpload from "../../../FileUpload/UpdateAdminFileUpload";

const DescpRetourComponent = ({ order }) => {
  const [uploadedImgs, setUploadedImgs] = useState(null);
  const [shouldReload, setShouldReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageFullscreen, setImageFullscreen] = useState(null);
  const [showInputImgs, setShowInputImgs] = useState(false);
  const [showInputImgsChanges, setShowInputImgsChanges] = useState([]);
  const dispatch = useDispatch();
  const [selectedStyle, setSelectedStyle] = useState("");

  useEffect(() => {
    // Initialize showInputImgsChanges array with false values
    if (order && order.order && order.order.Response && order.order.Response.changes) {
      setShowInputImgsChanges(order.order.Response.changes.map(() => false));
    }
  }, [order]);

  const handleUpdateImgsBtn = () => {
    setShowInputImgs(true);
  };

  const handleUpdateImgsCBtn = (index) => {
    setShowInputImgsChanges((prev) => {
      const newArray = [...prev];
      newArray[index] = true;
      return newArray;
    });
  };

  const handleSubmitUpdateResponse = async (idResponse) => {
    await axios
      .put(`http://localhost:3000/changesAPI/UpdateAdminResponse/${idResponse}`)
      .then((response) => {
        console.log(response);
        setShowInputImgs(false);
      })
      .catch((error) => {
        console.error("Error updating admin response imgs:", error);
      });
  };

  const handleImageClick = (image) => {
    setImageFullscreen(image);
  };

  const getFiles = (files) => {
    setUploadedImgs(files);
  };

  const handleStyleChange = (e) => {
    setSelectedStyle(e.target.value);
  };

  const { userAdmin } = useSelector((state) => state.getOneUserForAdmin);

  useEffect(() => {}, [selectedStyle]);
  const { t } = useTranslation();

  const submit = async () => {
    try {
      setLoading(true);

      const retourData = {
        OrderId: order.order.id,
        Images: uploadedImgs,
      };
      const retourChangeData = {
        OrderId: order.order.id,
        order: order,
        userId: order.order.User_Id,
        Images: uploadedImgs,
      };

      if (!order.order.Response) {
        await dispatch(addNewResponse(retourData));
      } else if (
        order.order.Response.changes.length > 0 &&
        order.order.Response.changes[order.order.Response.changes.length - 1]
          .ChangesResponse !== undefined
      ) {
        await dispatch(
          addNewChangeResponse(
            retourChangeData,
            order.order.Response.changes[
              order.order.Response.changes.length - 1
            ].id
          )
        );
      }

      setShouldReload(true);
    } catch (error) {
      console.error("Error submitting:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  const imagesArray =
    order &&
    order.order &&
    order.order.Response &&
    order.order.Response.changes &&
    order.order.Response.changes.length > 0 &&
    order.order.Response.changes.map((c) => {
      return c.ChangesResponse && c.ChangesResponse.Images;
    });

  const handleCloseFullscreen = () => {
    setImageFullscreen(null);
  };

  const getFileType = (filePath) => {
    const extension = filePath.split(".").pop().toLowerCase();
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "jfif",
      "bmp",
      "tiff",
      "svg",
      "raw",
      "webp",
      "heic",
      "heif",
      "psd",
      "eps",
      "ico",
    ];
    const videoExtensions = [
      "mp4",
      "avi",
      "mkv",
      "mov",
      "wmv",
      "flv",
      "webm",
      "m4v",
      "mpeg",
      "3gp",
      "ogg",
      "ogv",
      "qt",
      "rm",
      "swf",
      "ts",
      "vob",
      "wmv",
    ];
    const documentExtensions = [
      "doc",
      "docx",
      "pdf",
      "pde",
      "txt",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "odt",
      "ods",
      "odp",
      "rtf",
      "csv",
      "html",
      "xml",
      "json",
      "yaml",
      "md",
      "tex",
      "log",
      "ini",
      "cfg",
      "msg",
      "odf",
      "pages",
      "numbers",
      "key",
      "wps",
      "sxw",
      "sxc",
      "sxi",
      "wpd",
      "tex",
      "odg",
    ];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else if (documentExtensions.includes(extension)) {
      return "document";
    } else {
      return "unknown";
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-wrapper">
          <FallingLines
            color="#041C32"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
          />
        </div>
      )}
      <div className="descp-retour">
        <div className="order-desc">
          <h4>
            Style :{" "}
            {order.order.Style ? <span>{order.order.Style} style</span> : null}{" "}
          </h4>
          <h4>
            Description :{" "}
            {order.order.Note ? <span>{order.order.Note} </span> : null}{" "}
          </h4>
        </div>
      </div>
      <div> credits : {userAdmin?.user?.Credit && userAdmin.user.Credit} </div>
      {order && order.order && order.order.Response && (
        <>
          <p>Delivery 1</p>
          <button style={{ backgroundColor: "orange" }} onClick={handleUpdateImgsBtn}>
            update response
          </button>
          {!showInputImgs ? (
            <div className="retour-message" style={{ maxWidth: "1041px" }}>
              <div className="grid-container">
                {order.order.Response.Images.map((image, id) => (
                  <div key={id} className="grid-item">
                    {getFileType(image) === "image" && (
                      <img
                        src={`${environment.apiUrl}/${image}`}
                        alt={""}
                        onClick={() => handleImageClick(image)}
                        className="grid-image"
                      />
                    )}

                    {getFileType(image) === "video" && (
                      <video controls className="grid-video">
                        <source
                          src={`${environment.apiUrl}/${image}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {getFileType(image) === "document" && (
                      <div className="document-container">
                        <a
                          href={`${environment.apiUrl}/${image}`}
                          target="_blank"
                          download
                        >
                          <img
                            src={fileImg}
                            alt="Document"
                            className="grid-image"
                            style={{ width: "100px" }}
                          />
                        </a>
                        <p className="filename">{image.split("\\")[1]} </p>
                      </div>
                    )}
                    {getFileType(image) === "unknown" && (
                      <p>Type de fichier inconnu</p>
                    )}
                  </div>
                ))}
              </div>
              {imageFullscreen && (
                <div className="fullscreen-overlay">
                  <span className="close-btn" onClick={handleCloseFullscreen}>
                    <IoIosCloseCircleOutline />
                  </span>
                  <img
                    src={`${environment.apiUrl}/${imageFullscreen}`}
                    alt="order"
                    className="fullscreen-image"
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <UpdateAdminFileUpload
                getFiles={getFiles}
                response={order.order.Response}
                type="Res"
              />
            </div>
          )}
        </>
      )}

      {order && order.order && !order.order.Response ? (
        <>
          <AdminFileUpload getFiles={getFiles} />
          <button
            style={{
              backgroundColor:
                uploadedImgs && uploadedImgs.length > 0 ? "black" : "black",
              opacity: uploadedImgs && uploadedImgs.length > 0 ? 1 : 0.6,
              cursor:
                uploadedImgs && uploadedImgs.length > 0
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={submit}
            disabled={!uploadedImgs || uploadedImgs.length === 0}
          >
            Validate
          </button>
        </>
      ) : null}

      {imagesArray ? (
        <>
          {imagesArray.map((images, index) => (
            <div key={index} style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            
              {order &&
                order.order &&
                order.order.Response &&
                order.order.Response.changes && (
                  <p >
                    {index + 1} -{" "}
                    {order.order.Response.changes[index].Description}
                  </p>
                )}
              {order &&
                order.order &&
                order.order.Response &&
                order.order.Response.changes &&
                order.order.Response.changes.length > 0 &&
                order.order.Response.changes[index].ChangesResponse !==
                  null && (
                  <>
                    <p>Delivery {index + 2}</p>
                    <button
                      style={{ backgroundColor: "orange" }}
                      onClick={() => handleUpdateImgsCBtn(index)}
                    >
                      update response
                    </button>
                    {!showInputImgsChanges[index] ? (
                      <div
                        className="retour-message"
                        style={{ maxWidth: "1041px" }}
                      >
                        <div className="grid-container">
                          {order.order.Response.changes[index].ChangesResponse.Images.map(
                            (image, id) => (
                              <div key={id} className="grid-item">
                                {getFileType(image) === "image" && (
                                  <img
                                    src={`${environment.apiUrl}/${image}`}
                                    alt={""}
                                    onClick={() => handleImageClick(image)}
                                    className="grid-image"
                                  />
                                )}

                                {getFileType(image) === "video" && (
                                  <video controls className="grid-video">
                                    <source
                                      src={`${environment.apiUrl}/${image}`}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                )}
                                {getFileType(image) === "document" && (
                                  <div className="document-container">
                                    <a
                                      href={`${environment.apiUrl}/${image}`}
                                      target="_blank"
                                      download
                                    >
                                      <img
                                        src={fileImg}
                                        alt="Document"
                                        className="grid-image"
                                        style={{ width: "100px" }}
                                      />
                                    </a>
                                    <p className="filename">
                                      {image.split("\\")[1]}{" "}
                                    </p>
                                  </div>
                                )}
                                {getFileType(image) === "unknown" && (
                                  <p>Type de fichier inconnu</p>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        {imageFullscreen && (
                          <div className="fullscreen-overlay">
                            <span
                              className="close-btn"
                              onClick={handleCloseFullscreen}
                            >
                              <IoIosCloseCircleOutline />
                            </span>
                            <img
                              src={`${environment.apiUrl}/${imageFullscreen}`}
                              alt="order"
                              className="fullscreen-image"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <UpdateAdminFileUpload
                          getFiles={getFiles}
                          response={
                            order.order.Response.changes[index].ChangesResponse
                          }
                          type="ChangesRes"
                        />
                      </div>
                    )}
                  </>
                )}
            </div>
          ))}
        </>
      ) : null}
      {order &&
      order.order &&
      order.order.Response &&
      order.order.Response.changes &&
      order.order.Response.changes.length > 0 &&
      order.order.Response.changes[order.order.Response.changes.length - 1]
        .ChangesResponse == null ? (
        <>
          <AdminFileUpload getFiles={getFiles} />
          <button
            style={{
              backgroundColor:
                uploadedImgs && uploadedImgs.length > 0 ? "black" : "black",
              opacity: uploadedImgs && uploadedImgs.length > 0 ? 1 : 0.6,
              cursor:
                uploadedImgs && uploadedImgs.length > 0
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={submit}
            disabled={!uploadedImgs || uploadedImgs.length === 0}
          >
            Validate
          </button>
        </>
      ) : null}
    </>
  );
};

export default DescpRetourComponent;
