export const environment = {
  production: true,
  apiUrl: 'https://meryas-server.meryashome.com',
  stripePk:"pk_live_51KO0TkJuv7tYj0qfoBEUWN2JoyS23sntW5njAyCCP8FPGZLp8NdsxDqHHbIZDdfmj1m6pSflVPU5MXVoKCPvsVBs00RW4lEWlN",
  captchaKey: '6LeSE4QjAAAAAJx6NGs4Ge3qMMpd6_Q-a0oQYx5R', //prod
  FrontUrl:"https://www.meryashome.com",

  // apiUrl: 'http://localhost:3001',
  // stripePk:"pk_test_51Ok8drLJrt4v80UFisMEseYeiCgfDXx1Xc49YFekmInb3nCnTQzdPolP6PUSsWgd1aQuDzrbulf7rgfYtV63zbr700QUtJ9eYM",
  // captchaKey: '6Levi9khAAAAAEX3Gr7rba6DbUsA1_agbUKqS8sX', //local
  // FrontUrl:"http://192.168.1.17:3000",
  

}
