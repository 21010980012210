import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer/Footer";
import ProgressBarComponent from "../../../../components/Authentification/Profile/OrderStatusComponents/ProgressBarComponent";
import OrderRenderComponent from "../../../../components/Authentification/Profile/OrderStatusComponents/OrderRenderComponent";
import ButtonsComponents from "../../../../components/Authentification/Profile/OrderStatusComponents/ButtonsComponents";
import {
  getOrders,
  getOrdersForAdmin,
  getOneOrder,
  getOneOrderForAdmin,
} from "../../../../redux/actions/OrdersActions";
import { FallingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import NotFoundComponent from "../../../../components/notFound/NotFoundComponent";
import { Helmet } from "react-helmet-async";

const OrderStatus = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orderId } = useParams();

  let credString = localStorage.getItem("cred");
  let cred = JSON.parse(credString);
  let isAdmin = cred.user.Role === "Superadmin" ? true : false;

  useEffect(() => {
    if (isAdmin) {
      dispatch(getOrdersForAdmin());
      dispatch(getOneOrderForAdmin(orderId));
    } else {
      dispatch(getOrders());
      dispatch(getOneOrder(orderId));
    }
  }, []);
  const { order } = useSelector((state) => state.getOneOrder);
  const { orderAdmin } = useSelector((state) => state.getOneOrderForAdmin);
  let getLoading = isAdmin ? "getOneOrderForAdmin" : "getOneOrder";
  const { loading } = useSelector((state) =>
    isAdmin ? state[getLoading] : state[getLoading]
  );

  // console.log("loading")
  // console.log(loading)
  let thisOrder = isAdmin ? orderAdmin : order;
  // console.log("---------------------thisOrder?.order---------------------");
  // console.log(thisOrder?.order);

  return (
    <>
    <Helmet>
        <meta name="keywords" content="order status, user profile, order details, order progress, Meryashome, virtual staging, virtual renovation, 3D rendering interior, 3D rendering exterior, 2d textured plan, item removal, image enhancement, photo modification, day to dusk" />
        <meta name="description" content="Check the status and details of your order. View the progress and changes made to your request." />
      </Helmet>
      {loading && (
        <div className="loader-wrapper">
          <FallingLines
            color="#041C32"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
          />
        </div>
      )}
      {/* <Header /> */}
      {loading && (
        <div className="white-container" style={{ height: "600px" }}></div>
      )}

      <div>
        {thisOrder && thisOrder.order ? (
       
          <>
            <ProgressBarComponent
              title={thisOrder.order.Ref}
              status={thisOrder.order.Status}
              address={(thisOrder.order.Address ?thisOrder.order.Address :"N/A")}
              userName = {isAdmin && `${thisOrder?.order?.User?.First_Name} ${thisOrder?.order?.User?.Last_Name}`}
              isAdmin={isAdmin}
            /> 
            <OrderRenderComponent order={thisOrder} isAdmin={isAdmin} />

            {!isAdmin &&
              thisOrder.order.Response &&
              thisOrder.order.Response.Images.length !== 0 &&
              (() => {
                const response = thisOrder.order.Response;
                const changes = response && response.changes;

                if (
                  changes &&
                  changes.length > 0 &&
                  changes[changes.length - 1].ChangesResponse == null
                ) {
                  return null; // Ne rien afficher si ChangesResponse est null
                }

                return !thisOrder.order.Validated ? (
                  <ButtonsComponents
                    response={thisOrder.order.Response}
                    order={thisOrder.order}
                  />
                ) : (
                  <div className="validated-order-message">
                    <p>{t("order-validated")}</p>
                  </div>
                );
              })()}
          </>
        ) : (
          !loading && !thisOrder && <NotFoundComponent />
        )}
      </div>
      <Footer />
    </>
  );
};

export default OrderStatus;
