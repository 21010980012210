import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { environment } from '../envirement';

export default function TestimonialListComponent() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchTestimonialData();
  }, [page, rowsPerPage]);
  
  const fetchTestimonialData = () => {
    axios.get(`${environment.apiUrl}/testimonialAPI/GetTestimonialList?page=${page + 1}&rowsPerPage=${rowsPerPage}`)
      .then(response => {
        setRows(response.data.testimonialList);
        setTotalPages(response.data.totalPages);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // console.log(rows)

  return (
    <div style={{padding:"4%"}} >
      <Paper sx={{ width: '100%', overflow: 'hidden' ,border:"solid 1px", fontFamily: 'Arial, sans-serif'}}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" >
            {rows.length > 0 && (
              <TableHead>
                <TableRow>
                  {Object.keys(rows[0]).map((key) => (
                    <TableCell key={key}>
                      {key}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {rows.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {Object.keys(row).map((key) => (
                    <TableCell key={key}>
                      {row[key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalPages * rowsPerPage} // Use totalPages here
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage} // Ensure this function is correctly bound
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
